import { fork } from 'redux-saga/effects'

import * as watchers from './watchers'
import { companyMembersSaga } from '../modules/CompanyMembers/companyMembersSaga'
import { ticketSaga } from '../modules/Ticket/ticketSaga'

export default function* rootSaga() {
  yield fork(watchers.app)
  yield fork(watchers.fetches)
  yield fork(watchers.editTrip)
  yield fork(watchers.reduxForm)
  yield fork(watchers.selections)
  yield fork(watchers.reservation)
  yield fork(watchers.profile)
  yield fork(companyMembersSaga)
  yield fork(ticketSaga)
}
