import { clearFields, initialize } from 'redux-form'
import { put, takeEvery, select, takeLatest } from 'redux-saga/effects'
import { pathOr, map } from 'ramda'

import { updateModalState, goToPage, showModal, updateEditableFields } from '../../actions'
import { selections, reservation } from '../../services'
import { localTravellerPriceCategory } from '../../services/pricing/reducers/selectors'

const { getCurrentReservation } = reservation.selectors
const { endEditTrip, startEditTrip } = selections

const getSailRefId = pathOr(0, ['sailRefs', 0, 'sailRefId'])

export function* changeTicketsFormValues({ payload: editableTrip }) {
  const { localIDs } = editableTrip || {}

  if (localIDs) {
    const transformLocalId = (localID) => ({ localID, status: 'exist' })
    const tripLocalIDs = map(transformLocalId, localIDs)

    if (tripLocalIDs.length) {
      yield put(
        initialize('ticketsForm', 'LOCAL_TRAVELLER', {
          ...localTravellerPriceCategory,
          count: tripLocalIDs.length,
        })
      )
      yield put(initialize('localTravellerIDForm', { localIDs: tripLocalIDs }))
    } else {
      yield put(clearFields('ticketsForm', false, false, 'LOCAL_TRAVELLER'))
      yield put(clearFields('localTravellerIDForm', false, false, 'localIDs'))
    }
  }

  const fields = yield select(pathOr({}, ['userSelections', 'editableFields']))
  yield put(initialize('ticketsForm', fields))

  const current = yield select(getCurrentReservation)

  if (current && current.reservationOwner) {
    const { firstName, lastName, email, phones } = current.reservationOwner
    const phone = pathOr({ intlCode: '', phoneNumber: '' }, [0], phones)
    const { intlCode, phoneNumber } = phone

    const initialOwnerFields = {
      firstName,
      lastName,
      email,
      phoneCode: intlCode,
      phoneNumber,
    }

    yield put(initialize('contact', initialOwnerFields))
  }

  const sailRefIdForEdit = getSailRefId(editableTrip)
  yield put(goToPage(`/main-inside/select-tickets${sailRefIdForEdit ? `?sail=${sailRefIdForEdit}` : ''}`))
}

function* showModalDialog(contextType) {
  yield put(updateModalState(contextType))
}

function* goToConfirmDispatcher() {
  const fields = yield select(pathOr({}, ['form', 'ticketsForm', 'values']))
  yield put(updateEditableFields(fields))
  yield put(goToPage('/main-inside/confirm'))
}

export function* watchEditModalCalling() {
  yield takeEvery(showModal, showModalDialog)
}

export function* watchEndEditTrip() {
  yield takeLatest(endEditTrip, goToConfirmDispatcher)
}

export function* watchStartEditTrip() {
  yield takeEvery(startEditTrip, changeTicketsFormValues)
}

export default function* editTrip() {
  yield [watchStartEditTrip(), watchEndEditTrip(), watchEditModalCalling()]
}
