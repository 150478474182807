import { createAction } from 'redux-actions'
import * as actions from '../consts'

export const fetchUserDetails = createAction(actions.FETCH_USER_DETAILS)
export const setUserDetails = createAction(actions.SET_USER_DETAILS)
export const updateUserDetails = createAction(actions.UPDATE_USER_DETAILS)

export const fetchAllUserReservations = createAction(actions.FETCH_ALL_USER_RESERVATIONS)
export const fetchAllUserReservationsSuccess = createAction(actions.FETCH_ALL_USER_RESERVATIONS_SUCCESS) // eslint-disable-line

export const upsertUserCompany = createAction(actions.UPSERT_USER_COMPANY)
export const upsertUserCompanySuccess = createAction(actions.UPSERT_USER_COMPANY_SUCCESS)
export const deleteUserCompany = createAction(actions.DELETE_USER_COMPANY, (companyId, userId) => ({
  companyId,
  userId,
}))

export const changeUserPassword = createAction(actions.CHANGE_USER_PASSWORD)
export const resetCredentialsMessages = createAction(actions.RESET_CREDENTIALS_MESSAGES)

export const fetchCountries = createAction(actions.FETCH_COUNTRIES)
export const fetchCountriesSuccess = createAction(actions.FETCH_COUNTRIES_SUCCESS)

export const fetchCitizenships = createAction(actions.FETCH_CITIZENSHIPS)
export const fetchCitizenshipsSuccess = createAction(actions.FETCH_CITIZENSHIPS_SUCCESS)

export const changeUserFilters = createAction(actions.CHANGE_USER_FILTERS)
export const selectUsersCompany = createAction(actions.SELECT_USERS_COMPANY)

export const resetUser = createAction(actions.RESET_USER)

export const inviteUserToCompany = createAction(actions.INVITE_USER_TO_COMPANY)
export const inviteUserToCompanySuccess = createAction(actions.INVITE_USER_TO_COMPANY_SUCCESS)

export const manageInvitationToCompany = createAction(actions.MANAGE_INVITATION_TO_COMPANY)
export const manageInvitationToCompanySuccess = createAction(actions.MANAGE_INVITATION_TO_COMPANY_SUCCESS)

export const updateCompanyMembers = createAction(actions.UPDATE_COMPANY_MEMBERS)
export const removeUserFromCompanySuccess = createAction(actions.REMOVE_USER_FROM_COMPANY_SUCCESS)

export const getLocalTravellerInfo = createAction(actions.GET_LOCAL_TRAVELLER_INFO)
export const promoteResidentDiscount = createAction(actions.PROMOTE_RESIDENT_DISCOUNT)
