import * as reducers from './reducers/payment'
import * as actions from './actions'
import * as sagas from './sagas'
import * as constants from './consts/payment'
import * as selectors from './selectors'

export default {
  reducers,
  actions,
  constants,
  sagas,
  selectors,
}
