import React from 'react'
import { split, prop, compose, match, insert, join, map, pathOr, contains, update } from 'ramda'
import ProtectedRoute from '../modules/Common/ProtectedRoute'

// Default locale is the first of supported languages in theme json
const themeLanguages = pathOr([], ['brandProps', 'languages'])(window)
export const defaultLocale = pathOr('en', [0])(themeLanguages)

const getPathname = () => window.location.hash
const getOrigin = () => window.location.origin

const hashLocaleRegExp = /^#\/([\D]{2,3})\//
export const getUrlLocale = compose(prop(1), match(hashLocaleRegExp))

export const getLocaleFromUrlOrRedirect = () => {
  const localeStorage = JSON.parse(window.localStorage.getItem('persist:root'))
  const hashPath = getPathname()

  if (!hashPath) {
    if (localeStorage) {
      const localeFromStorage = localeStorage.locale || ''
      return localeFromStorage.replace(/"/g, '')
    }

    return null
  }

  const urlLocale = getUrlLocale(hashPath)
  if (contains(urlLocale, themeLanguages)) {
    return urlLocale
  }

  const getPathWithDefaultLocale = compose(
    join('/'),
    urlLocale ? update(1, defaultLocale) : insert(1, defaultLocale),
    split('/')
  )
  window.location.replace([getOrigin(), getPathWithDefaultLocale(hashPath)].join('/'))
  return defaultLocale
}

export const generateRoutesWithLocale = map((props) => (
  <ProtectedRoute {...props} exact key={props.path} path={`/:locale${props.path}`} />
))
