import * as AppActions from '../consts/actions'

const initialState = []

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case AppActions.SHOW_NOTIFICATION: {
      return [...state, action.notification]
    }
    case AppActions.REMOVE_NOTIFICATION: {
      return [...state.slice(0, action.notifyIndex), ...state.slice(action.notifyIndex + 1, state.length)]
    }
    default:
      return state
  }
}

export default notifications
