import * as Actions from '../consts'

const initialTravellers = {}

const localTraveller = (state = initialTravellers, action) => {
  switch (action.type) {
    case Actions.GET_LOCAL_TRAVELLER_INFO_SUCCESS: {
      const loyaltyProgram = action.payload
      return {
        ...state,
        [loyaltyProgram.personalIdentificationNumber]: loyaltyProgram,
      }
    }

    default:
      return state
  }
}

export default localTraveller
