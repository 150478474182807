export const INVENTORY_FETCH_SUCCESS = 'inventory:INVENTORY_FETCH_SUCCESS'
export const INVENTORY_FETCH_FAIL = 'inventory:INVENTORY_FETCH_FAIL'
export const ALL_INVENTORY_FETCH_SUCCESS = 'inventory:ALL_INVENTORY_FETCH_SUCCESS'
export const CHANGE_INVENTORY_LEG = 'inventory:CHANGE_LEG'
export const CHANGE_INVENTORY_DATE = 'inventory:CHANGE_DATE'
export const FETCH_ROUTE_LEG_INVENTORY = 'inventory:FETCH_ROUTE_LEG_INVENTORY'
export const FETCH_INVENTORY_FOR_EDIT_RESERVATION = 'inventory:FETCH_INVENTORY_FOR_EDIT_RESERVATION'

export const SET_ALL_INVENTORY_FETCH_SUCCESS = 'inventory:SET_ALL_INVENTORY_FETCH_SUCCESS'

export const SW_INVENTORY_FETCH = '[SW]/FETCH_INVENTORY'
export const SW_INVENTORY_FETCH_SUCCESS = '[SW]/FETCH_INVENTORY_SUCCESS'
