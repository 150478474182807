import axios from 'axios'

export const fetchUserDetails = () =>
  axios({
    method: 'get',
    url: '/api/user/details',
  })

export const fetchAllReservationsByUserId = ({ customerId, from, to }) =>
  axios({
    method: 'post',
    url: '/api/user/reservations/all',
    data: {
      customerId,
      limit: 300,
      ...(from && { from }),
      ...(to && { to }),
    },
  })

export const updateUserDetails = (data) =>
  axios({
    method: 'put',
    url: '/api/user/details',
    data,
  })

export const upsertUserCompany = (data) =>
  axios({
    method: 'put',
    url: '/api/user/company',
    data,
  })

export const deleteUserCompany = (data) =>
  axios({
    method: 'delete',
    url: '/api/user/company',
    data,
  })

export const changeUserPassword = (data) =>
  axios({
    method: 'put',
    url: '/api/user/change-password',
    data,
  })

export const fetchLocalTravellerInfo = (accountNumber) =>
  axios({
    method: 'get',
    url: `/api/user/local/${accountNumber}`,
  })
