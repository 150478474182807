import { handleActions } from 'redux-actions'

import { FETCH_PORTS_SUCCESS } from '../consts'

const ports = handleActions(
  {
    [FETCH_PORTS_SUCCESS]: (state, { payload }) => payload.entities.ports,
  },
  {}
)

export default ports
