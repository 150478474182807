import * as Actions from '../consts'

export const reservationFilters = {
  number: '',
  dates: [null, null],
  direction: '',
  payer: {},
  paymentMethod: '',
  ticketsSort: '',
}

const initialFilters = {
  tickets: {
    old: false,
    cancelled: false,
    ...reservationFilters,
  },
  usersTickets: {
    user: {},
    old: false,
    cancelled: false,
    ...reservationFilters,
  },
  usersCompany: {
    companyId: '',
  },
}

const filters = (state = initialFilters, action) => {
  switch (action.type) {
    case Actions.CHANGE_USER_FILTERS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          [action.payload.filter]: action.payload.value,
        },
      }

    case Actions.RESET_USER: {
      return initialFilters
    }

    default:
      return state
  }
}

export default filters
