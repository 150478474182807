import * as Actions from '../consts'

const emptyList = []

const history = (state = emptyList, action) => {
  const { payload } = action
  switch (action.type) {
    case Actions.FETCH_ALL_USER_RESERVATIONS_SUCCESS:
      return payload.content || []
    case Actions.RESET_USER:
      return emptyList
    case Actions.FETCH_ALL_USER_RESERVATIONS_FAIL:
    default:
      return state
  }
}

export default history
