import { fetchUserCustomerInfo } from '../../services/customer/actions'
import { isLoggedInSelector } from '../../services/auth/reducers/selectors'
import { RESET_SELECTED_SAIL_PACKAGE_INDEX } from '../../consts/actions'
import { CLEAR_PASSENGER_SEQ_NUM } from '../../services/user-selections/consts'

export function interceptor(store) {
  const { dispatch, getState } = store
  return (next) => (action) => {
    if (
      (action.type === 'navigate/GO_TO_PAGE' && action.payload.endsWith('/main-inside/select-tickets')) ||
      action.type === 'navigate/BACK_TO_PREVIOUS_PAGE'
    ) {
      const isLoggedIn = isLoggedInSelector(getState())
      if (isLoggedIn) {
        dispatch(fetchUserCustomerInfo())
      }
      dispatch({
        type: RESET_SELECTED_SAIL_PACKAGE_INDEX,
      })
      dispatch({
        type: CLEAR_PASSENGER_SEQ_NUM,
      })
    }

    next(action)
  }
}
