import actions from './actions'
import reducer, * as selectors from './reducers'
import * as constants from './consts/schedule'
import { scheduleSaga } from './sagas'

export default {
  actions,
  reducer,
  constants,
  selectors,
  sagas: {
    scheduleSaga,
  },
}
