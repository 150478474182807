import { normalize } from 'normalizr'

import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchRoutes, fetchRoutesSuccess, fetchRoutesError } from './actions'
import * as api from './api'
import fakeApi from './fake-api'
import { routesSchema } from './schemas'

function* fetchRoutesSaga() {
  try {
    const fake = location.href.indexOf('fake') >= 0
    const data = yield call(fake ? fakeApi.fetchRoutes() : api.fetchRoutes)
    yield put(fetchRoutesSuccess(normalize(data.content, routesSchema)))
  } catch (e) {
    yield put(fetchRoutesError())
  }
}

export function* routesSaga() {
  yield takeLatest(fetchRoutes, fetchRoutesSaga)
}
