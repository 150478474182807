import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, createStore, compose } from 'redux'
import { handleAction } from 'redux-actions'
import { identity } from 'ramda'
import { persistStore, persistCombineReducers, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import formActionSaga from 'redux-form-saga'

import LogRocket from 'logrocket'

import { reducer as formReducer } from 'redux-form'
import { UIReducers, MediaReducers } from 'bora-material-ui'

import { axiosConfigure } from './middlewares/axios-config'
import { interceptor } from './middlewares/navigation-interceptor'
import { interceptor as tokenCheckInterceptor } from './middlewares/token-check-interceptor'
import { serviceWorkerMiddleware } from './middlewares/service-worker-middleware'
import { checkReservationExpiration } from './middlewares/expiration-check'

import {
  inventory,
  payment,
  pricing,
  reservation,
  schedule,
  routes as routesServices,
  salesStatic,
  auth,
  user,
  customer,
  selections,
} from '../services'
import notifications from '../reducers/notifications'
import modal from '../reducers/modal'
import { modalData } from '../reducers/modalData'
import { passengerToEdit } from '../reducers/passengerToEdit'
import viewMode from '../reducers/viewMode'
import cancelled from '../reducers/cancelled'
import fetching from '../reducers/fetching'
import network from '../reducers/network'
import confirming from '../reducers/confirming'
import message from '../reducers/message'
import selectedSailPackageIndexToEdit from '../reducers/selectedSailPackageIndexToEdit'
import userNeedToPayAfterEdit from '../reducers/userNeedToPayAfterEdit'
import rootSaga from '../sagas/appSaga'
import { reducer as userSelections, changeLocale } from '../services/user-selections'
import { getLocaleFromUrlOrRedirect, defaultLocale } from '../utils/locale'
import { companyMembersReducer as companyMembers } from '../modules/CompanyMembers/CompanyMembers'
import { editTicketReducer as editTicket } from '../modules/Ticket/EditTicket'

const { routes, sailPackages } = routesServices

const { sagas: authSagas } = auth
const { sagas: scheduleSagas } = schedule
const { sagas: reservationSaga } = reservation
const { sagas: inventorySagas } = inventory
const { sagas: pricingSagas } = pricing
const {
  sagas: { request, ...paymentSagas },
} = payment
const { sagas: routesSagas } = routes
const { sagas: sailPackagesSagas } = sailPackages
const { sagas: salesStaticSaga } = salesStatic
const { sagas: userSaga } = user
const { sagas: customerSaga } = customer
const { sagas: selectionsSagas } = selections
const sagaMiddleware = createSagaMiddleware()

export const initScheduleSagas = (middleware) => {
  Object.values(scheduleSagas).forEach(middleware.run.bind(middleware))
}

export const initSagas = (sagas) => (middleware) => {
  Object.values(sagas).forEach(middleware.run.bind(middleware))
}

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose
/* eslint-enable */

const middlewares = [
  serviceWorkerMiddleware,
  axiosConfigure,
  interceptor,
  tokenCheckInterceptor,
  checkReservationExpiration,
  sagaMiddleware,
  LogRocket.reduxMiddleware(),
]

const enhancers = [applyMiddleware(...middlewares)]

const create = () => {
  const formPersistConfig = {
    key: 'form',
    storage,
    blacklist: ['subInfo', 'bookingForm'],
  }

  const reducers = {
    // eslint-disable-line
    auth: auth.reducers.default,
    customer: customer.reducer,
    companyMembers,
    editTicket,
    routes: routes.reducer,
    sailPackages: sailPackages.reducer,
    prices: pricing.reducer,
    schedule: schedule.reducer,
    reservation: reservation.reducers.default,
    salesStatic: salesStatic.reducers.default,
    payment: payment.reducers.default,
    notifications,
    modal,
    selectedSailPackageIndexToEdit,
    modalData,
    passengerToEdit,
    form: persistReducer(formPersistConfig, formReducer),
    userSelections,
    user: user.reducer,
    locale: handleAction(
      changeLocale,
      (state, { payload: locale }) => locale,
      getLocaleFromUrlOrRedirect() || defaultLocale
    ),
    userNeedToPayAfterEdit,
    viewMode,
    cancelled,
    message,
    confirming,
    network,
    ...(process.env.NODE_ENV !== 'production' && { fetching }),
    ...UIReducers,
    ...MediaReducers,
  }

  const ScheduleTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    identity,
    // transform state being rehydrated
    (outboundState) => {
      const { sails, inventories, availableDates } = outboundState
      return { sails, inventories, availableDates }
    },

    { whitelist: ['schedule'] }
  )

  const AuthStateTransform = createTransform(
    identity,
    ({ token, joinToken }) => {
      return { token, joinToken }
    },

    { whitelist: ['auth'] }
  )

  const config = {
    key: 'root',
    storage,
    whitelist: [
      'auth',
      'schedule',
      'user',
      'customer',
      'routes',
      'prices',
      'salesStatic',
      'sailPackages',
      'userSelections',
      'reservation',
    ],
    transforms: [AuthStateTransform, ScheduleTransform],
  }

  const reducer = persistCombineReducers(config, reducers)

  const store = createStore(reducer, composeEnhancers(...enhancers))

  const persistor = persistStore(store)
  if (window.brandProps.persistState) {
    persistor.persist()
  } else {
    persistor.purge()
    persistor.pause()
  }

  initSagas({
    salesStaticSaga,
    reservationSaga,
    ...authSagas,
    ...paymentSagas,
    ...pricingSagas,
    ...scheduleSagas,
    ...inventorySagas,
    ...routesSagas,
    ...sailPackagesSagas,
    ...selectionsSagas,
    userSaga,
    customerSaga,
    rootSaga,
    formActionSaga,
  })(sagaMiddleware)

  return { store, persistor }
}

const { store, persistor } = create()

export { store, persistor }
