import { combineReducers } from 'redux'
import menuItems from './menuItems'
import vccf from './vccf'
import links from './links'

export default combineReducers({
  menuItems,
  vccf,
  links,
})
