import * as Actions from '../consts'

const initialCitizenships = []

const citizenships = (state = initialCitizenships, action) => {
  switch (action.type) {
    case Actions.FETCH_CITIZENSHIPS_SUCCESS: {
      return action.payload || []
    }

    default:
      return state
  }
}

export default citizenships
