import faker from 'faker'

export const isReallyProduction = () => window.brandProps && window.brandProps.env === 'production'

export const fillContactDataForm = (change) => {
  if (isReallyProduction()) return

  const firstName = faker.name.firstName()
  const lastName = faker.name.lastName()
  const phoneCode = '372'
  const phoneNumber = faker.phone.phoneNumber('55######')
  const email = faker.internet.email()

  change('phoneCode', phoneCode)
  change('firstName', firstName)
  change('lastName', lastName)
  change('phoneNumber', phoneNumber)
  change('email', email)
}

export const fillCompanyDataForm = (change) => {
  if (isReallyProduction()) return

  change('city', faker.address.city())
  change('legalName', faker.company.companyName())
  change('zip', faker.address.zipCode())
  change('registrationNumber', faker.finance.creditCardNumber())
  change('addressLine', faker.address.streetAddress())
}
