import moment from 'moment'
import { change, reset } from 'redux-form'
import { fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { compose, head, isEmpty, path, reverse } from 'ramda'

import { DATE_FORMAT } from '../../consts/stuff'
import { goToPage, toggleDirection } from '../../actions'
import { inventory, payment, reservation, schedule, selections as selectionsService } from '../../services'

import { clearReservationIfNeeded } from './reservation'
import { fetchAllInventory, fetchSailsAndInventories } from './fetches'

import { getNearestOrFirstSail, selectedSails } from '../../services/schedule/reducers'
import { clearSailSelection, setSelectedSail, setSelectedSailForEdit } from '../../services/schedule/actions/schedule'
import { extendSailPackage } from '../../services/utils'
import { fetchInventoryForEditReservationAction } from '../../services/inventory/actions'
import { getRoutes } from '../../services/routes/routes/reducers'
import { fillSelectionsFromUrl } from '../../services/user-selections'
import { getAllSailPackages } from '../../services/routes/sailPackages/reducers'

const { changeLeg } = inventory.actions
const { clearPayment } = payment.actions
const { clearReservation } = reservation.actions.reservation

const { clearSchedule, changeRouteLeg, fetchAvailableDates, omitPackageFromScheduleState } = schedule.actions

const {
  getSailDate,
  getSailDates,
  setSailDates,
  getSailBackDate,
  setFirstSailDate,
  setSecondSailDate,
  getSelectedSailPackages,
  getReversePackage,
  getFirstSelectedSailPackage,
  getSecondSelectedSailPackage,
  selectSailPackage,
  setSelectedPackages,
  selectReverseSailPackage,
} = selectionsService

function* changeFirstSelectedDate() {
  const sailPackage = yield select(getFirstSelectedSailPackage)
  const [firstDate, secondDate] = yield select(getSailDates)

  if (secondDate && moment(secondDate).isBefore(firstDate)) {
    const secondSailPackage = yield select(getSecondSelectedSailPackage)
    const secondSailPackageCode = secondSailPackage.code

    yield put(setSailDates([firstDate]))
    yield put(change('departure', 'sailDateBack', 0))
    yield put(change('searchForm', 'CalendarBack', 0))

    yield put(omitPackageFromScheduleState({ sailPackageCode: secondSailPackageCode }))
  }

  yield fork(fetchSailsAndInventories, { sailPackage, dateSelector: getSailDate })
}

function* setInventoriesForEditReservationDate({ payload }) {
  const { reservation, sailPackageIndex } = payload

  const getPackageParams = compose(
    ({ departureDate: date, routeLeg: leg }) => ({ date, leg }),
    path(['sailPackages', sailPackageIndex, 'sailRefs', 0])
  )

  const getPackageCode = path(['sailPackages', sailPackageIndex, 'code'])
  const fetchParams = {
    ...getPackageParams(reservation),
    code: getPackageCode(reservation),
  }

  if (sailPackageIndex === 0) {
    yield put(setFirstSailDate(fetchParams.date))
  } else {
    yield put(setSecondSailDate(fetchParams.date))
  }

  // yield fork(fetchInventory, fetchParams)
  // yield fetchInventory(fetchParams)
}

function* changeSecondSelectedDate() {
  const sailPackage = yield select(getSecondSelectedSailPackage)
  yield fork(fetchSailsAndInventories, { sailPackage, dateSelector: getSailBackDate })
}

function* handleSelectReverseSailPackage() {
  const sailDates = yield select(getSailDates)
  yield put(setSecondSailDate(head(sailDates)))
}

function* handleSelectRouteAndDate({ payload }) {
  if (URLSearchParams) {
    const params = new URLSearchParams(payload)
    // const routeParam = params.get('route')

    const routes = yield select(getRoutes)
    const packages = yield select(getAllSailPackages)

    if (isEmpty(routes) || isEmpty(packages)) {
      yield put(getAllSailPackages())
    }

    // const { sailPackages = [] } = yield select(getRouteByCode(routeParam))
    // const datesParams = params.getAll('date')
    const packagesParams = params.getAll('package')

    if (packagesParams.length) {
      // const packages = sailPackages.filter(({ code }) => packagesParams.includes(code))
      // console.log({
      //   datesParams,
      //   packages,
      //   sailPackages,
      // })
      // yield put(setSelectedPackages(packages))
      // yield put(setSailDates(datesParams))
    }
  }
}

const getSailStatus = path(['sail', 'status'])
const getSailRefId = path(['sail', 'sailRefId'])
const getSailPackage = path(['sail', 'sailPackage'])

export function* selectSailAfterAllInventoriesFetch({ payload: { sailPackageCode } = {} }) {
  if (!sailPackageCode) {
    yield put(clearSailSelection())
    return
  }

  const selectedPackages = yield select(getSelectedSailPackages)
  const getEditReservation = path(['editTicket', 'reservation'])
  const reservationOnEdit = yield select(getEditReservation)

  const editingReservation = Boolean(reservationOnEdit)

  const sailToSelect = yield select(getNearestOrFirstSail(sailPackageCode, selectedPackages))
  const scheduleSelectedSailsState = yield select(selectedSails)

  if (path(['count'], sailToSelect) > 0 && getSailStatus(sailToSelect) === 'OPEN') {
    // the case with only one sail available, select it automatically

    yield put(change('departure', 'sailTime', 0))

    if (editingReservation) {
      yield put(
        setSelectedSailForEdit({
          sailPackageCode: getSailPackage(sailToSelect),
          selectedSailId: getSailRefId(sailToSelect),
        })
      )
    } else {
      yield put(
        setSelectedSail({
          sailPackageCode: getSailPackage(sailToSelect),
          selectedSailId: getSailRefId(sailToSelect),
        })
      )
    }
  } else if (!editingReservation) {
    if (!sailToSelect) {
      console.log(`Sail for selecting is undefined for ${sailPackageCode}`, {
        scheduleSelectedSailsState,
      })
    }

    if (Object.keys(scheduleSelectedSailsState).length < 1) {
      yield put(clearSailSelection())
    }
  }
}

function* changeRouteLegProcess(action) {
  if (action.payload === null) {
    yield put(reset('departure'))
  }
  yield put(changeRouteLeg(action.payload))
  yield put(changeLeg(action.payload))
}

function* changeSailPackage({ payload }) {
  const { sailPackage, redirect = false, isClear = true } = payload

  if (isClear) {
    yield put(clearPayment())
    yield put(clearSchedule())
    yield put(clearReservation())
  }

  const extended = extendSailPackage(sailPackage)

  const [leg] = extended.legSequence

  const startMoment = moment().startOf('date')
  const endMoment = moment(startMoment).add(12, 'M').endOf('month')

  yield put(
    fetchAvailableDates({
      legCode: leg.code,
      startDate: startMoment.format(DATE_FORMAT),
      endDate: endMoment.format(DATE_FORMAT),
      // endDate: '2019-06-03' // <- freaking hardcode as kihnu wanted
    })
  )

  yield fork(fetchSailsAndInventories, { sailPackage: extended, dateSelector: getSailDate })

  if (redirect) {
    yield put(goToPage(redirect))
  }
}

function* changeDirection() {
  const selectedPackages = yield select(getSelectedSailPackages)
  if (selectedPackages.length === 1) {
    const reversePackage = yield select(getReversePackage)
    // because for some sailpackages there are no return routes
    if (reversePackage) {
      yield put(selectSailPackage({ sailPackage: reversePackage }))
    }
  }

  if (selectedPackages.length === 2) {
    const selectedSailDates = yield select(getSailDates)

    yield put(setSailDates(reverse(selectedSailDates)))
    yield put(setSelectedPackages(reverse(selectedPackages)))
  }
}

export function* watchToggleDirection() {
  yield takeEvery(toggleDirection, changeDirection)
}

export function* watchSelectSail() {
  yield takeEvery(setSelectedSail, clearReservationIfNeeded)
}

export function* watchUserSelectionDate() {
  yield takeLatest(setFirstSailDate, changeFirstSelectedDate)
}

export function* watchUserSelectionBackDate() {
  yield takeLatest(setSecondSailDate, changeSecondSelectedDate)
}

export function* watchScheduleSelectDate() {
  yield takeEvery(schedule.constants.CHANGE_SCHEDULE_DATE, fetchAllInventory)
}

export function* watchSelectReverseSailPackage() {
  yield takeEvery(selectReverseSailPackage, handleSelectReverseSailPackage)
}

export function* watchSelectRouteDataFromUrl() {
  yield takeEvery(fillSelectionsFromUrl, handleSelectRouteAndDate)
}

export function* watchSelectRoute() {
  yield takeEvery('routes/Routes/SELECT_ROUTE', changeRouteLegProcess)
}

export function* watchSelectSailPackage() {
  yield takeEvery(selectSailPackage, changeSailPackage)
}

export function* watchResetInventoriesForEditReservation() {
  yield takeEvery(fetchInventoryForEditReservationAction, setInventoriesForEditReservationDate)
}

export default function* selections() {
  yield [
    watchSelectRoute(),
    watchScheduleSelectDate(),
    watchSelectReverseSailPackage(),

    watchSelectSailPackage(),
    watchUserSelectionDate(),
    watchUserSelectionBackDate(),
    watchToggleDirection(),

    watchSelectSail(),
    watchResetInventoriesForEditReservation(),
    watchSelectRouteDataFromUrl(),
  ]
}
