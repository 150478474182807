import { createSelector } from 'reselect'
import {
  prop,
  find,
  pathEq,
  pathOr,
  identity,
  compose,
  filter,
  pathSatisfies,
  assoc,
  reduce,
  ifElse,
  isEmpty,
  F,
} from 'ramda'

import routesReducer from './routes'
import { THEME } from 'bora-material-ui/themes'
import { isGavdos } from '../../../../utils/gavdosStyleUtils'

export default routesReducer

const getRoutesState = prop('routes')
const getRouteLegs = (route) => route.legs
const getImageUrl = (leg) => `/images/${leg.portFrom}.png`

const getLocaleProp = prop('locale')

export const getAttributeStructureValue = pathOr('', ['structure', 'value', 'value'])
export const attributeCodePath = ['attribute', 'code']

export const getTerms =
  (code, hasCustomTerms = false) =>
  (routes, locale) => {
    if (window.brandProps.theme === THEME.LIINILAEVAD) {
      return compose(
        getAttributeStructureValue,
        find(pathEq(attributeCodePath, `TERMS_${locale.toUpperCase()}`)),
        pathOr([], [code, 'attributes'])
      )(routes)
    }

    let termsProp = locale === 'et' ? 'TERMS_ET' : 'TERMS'
    const customTermsProp =
      locale === 'et' ? `TERMS_${locale.toUpperCase()}_AFTER_01.09.2021` : 'TERMS_AFTER_01.09.2021'

    if (isGavdos) {
      termsProp = `TERMS_${locale.toUpperCase()}`
    }

    return compose(
      getAttributeStructureValue,
      find(pathEq(attributeCodePath, hasCustomTerms ? customTermsProp : termsProp)),
      pathOr([], [code, 'attributes'])
    )(routes)
  }

const getByCodeAndAttrName = (code, attrName) =>
  compose(
    ifElse(isEmpty, F, identity),
    getAttributeStructureValue,
    find(pathEq(attributeCodePath, attrName)),
    pathOr([], [code, 'attributes'])
  )

export const getNoRefundIfCancelledAttribute = (code) => getByCodeAndAttrName(code, 'NO_REFUND_IF_SAIL_CANCELLED')
export const getResidentsAttribute = (code) => getByCodeAndAttrName(code, 'RESIDENTS')
export const getHideZeroPriceAttribute = (code) => getByCodeAndAttrName(code, 'HIDE_ZERO_PRICES_IN_ONLINE')
export const getEnableCompanyLoyaltiesAttribute = (code) => getByCodeAndAttrName(code, 'ENABLE_COMPANY_LOYALTIES')
export const getHandicappedVehiclesAttribute = (code) => getByCodeAndAttrName(code, 'ENABLE_HANDICAPPED_VEHICLES')
export const getRouteNameSuffixAttribute = (code) => getByCodeAndAttrName(code, 'ROUTE_NAME_SUFFIX')
export const getAlternativeResidentsPriceCatAttribute = (code, hasCustomTerms = false) =>
  hasCustomTerms
    ? getByCodeAndAttrName(code, 'ALTERNATIVE_PRICE_CATEGORY_FOR_RESIDENT_AFTER_01.09.2021')
    : getByCodeAndAttrName(code, 'ALTERNATIVE_PRICE_CATEGORY_FOR_RESIDENT')
export const getRoutes = createSelector(getRoutesState, identity)
export const getRouteByCode = (code) => createSelector(getRoutes, prop(code))
export const getRouteTerms = (code, hasCustomTerms = false) =>
  createSelector([getRoutes, getLocaleProp], getTerms(code, hasCustomTerms))
export const getRouteNoRefundIfCancelled = (code) => createSelector([getRoutes], getNoRefundIfCancelledAttribute(code))
export const getRouteAllowsResidentsPurchase = (code) => createSelector([getRoutes], getResidentsAttribute(code))
export const getRouteHideZeroPrice = (code) => createSelector([getRoutes], getHideZeroPriceAttribute(code))
export const getRouteNameSuffix = (code) => createSelector([getRoutes], getRouteNameSuffixAttribute(code))
export const getAlternativeResidentsPriceCategory = (code, hasCustomTerms = false) =>
  createSelector([getRoutes], getAlternativeResidentsPriceCatAttribute(code, hasCustomTerms))

export const getEnableCompanyLoyaltiesForRoute = (code) =>
  createSelector([getRoutes], getEnableCompanyLoyaltiesAttribute(code))

export const getEnableHandicappedVehiclesForRoute = (code) =>
  createSelector([getRoutes], getHandicappedVehiclesAttribute(code))

const getCoefficientsMap = (code) => (routes, locale) => {
  const attributes = pathOr([], [code, 'attributes'], routes)
  const coefHeaderCode = `CARDECK_${code}-${locale === 'et' ? 'COEF_HEADER_ET' : 'COEF_HEADER_EN'}`
  const header = compose(getAttributeStructureValue, find(pathEq(['attribute', 'code'], coefHeaderCode)))(attributes)

  const coefCodeSuffix = locale === 'et' ? 'COEF_ET' : 'COEF_EN'
  const assocAttributeValueToCode = (acc, el) =>
    assoc(pathOr('', attributeCodePath, el), getAttributeStructureValue(el), acc)
  const coefCodesMap = compose(
    reduce(assocAttributeValueToCode, {}),
    filter(pathSatisfies((str) => str.includes(coefCodeSuffix), ['attribute', 'code']))
  )(attributes)

  return header ? { header, ...coefCodesMap } : coefCodesMap
}

export const getRouteCoefficientsMap = (code) => createSelector([getRoutes, getLocaleProp], getCoefficientsMap(code))

const prices = [10, 20, 30]

const getRoutesLegs = (routes) =>
  [].concat(...Object.values(routes).map(getRouteLegs)).map((leg, index) => ({
    ...leg,
    imgSource: getImageUrl(leg),
    price: prices[index % 3],
  }))

export const promotions = createSelector(getRoutes, getRoutesLegs)

export const getNextRoutes = createSelector(
  [(state) => state.schedule.selectedSail, getRoutes],
  (selectedSchedule, routes) => {
    const selectedDestinationPort = selectedSchedule.departure.port
    const keysRoutesWithSelectedDestinationPort = Object.keys(routes).filter((key) =>
      key.includes(selectedDestinationPort)
    )
    const nextRoutes = keysRoutesWithSelectedDestinationPort.map((routeKey) => routes[routeKey])
    return nextRoutes
  }
)
