import { handleActions } from 'redux-actions'
import { fetchLinksFail, fetchLinksSuccess } from '../actions/links'

const initialState = {
  items: [],
  pageCount: 0,
  selectedPage: '',
  error: '',
}

export default handleActions(
  {
    [fetchLinksSuccess](state, { payload }) {
      return {
        ...payload,
        error: '',
      }
    },

    [fetchLinksFail](state, { payload }) {
      return {
        ...initialState,
        error: payload,
      }
    },
  },
  initialState
)
