import {
  PAYMENT_PAY,
  PAYMENT_PAYING,
  PAYMENT_PAY_FAILED,
  PAYMENT_RESERVATION_ID,
  CLEAR_PAYMENT,
  STRIPE_PAYMENT_FAILED,
} from '../consts/payment'

const initialState = {
  status: '',
  error: null,
  reservationId: null,
}

const payment = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_PAYING:
      return { ...state, status: 'paying' }
    case PAYMENT_PAY:
      return { ...state, status: 'payed' }
    case PAYMENT_PAY_FAILED:
      return { ...state, status: 'error', error: action.payload }
    case STRIPE_PAYMENT_FAILED:
      return { ...state, status: 'error', error: action.payload }
    case PAYMENT_RESERVATION_ID:
      return { ...state, reservationId: action.payload, status: `${action.payload !== '' ? 'allow' : ''}`, error: null }
    case CLEAR_PAYMENT:
      return initialState
    default:
      return state
  }
}

export default payment
