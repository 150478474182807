import { FETCH_MENU_ITEMS_FAILED, FETCH_MENU_ITEMS_SUCCESS, START_FETCHING } from '../consts/menuItems'
import messages from '../../../consts/messages'

const initialState = {
  menuItems: [],
  fetching: false,
}

const defaultMenuItems = [
  {
    translationObj: messages.navBarBookTickets,
    link: '/',
  },
  {
    translationObj: messages.navBarSignIn,
    link: '/auth/login',
  },
  {
    translationObj: messages.navBarSignUp,
    link: '/auth/signup',
  },
]

const menuItems = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENU_ITEMS_SUCCESS: {
      return {
        ...state,
        menuItems: action.payload || initialState.menuItems,
        fetching: false,
      }
    }
    case FETCH_MENU_ITEMS_FAILED:
      return {
        ...state,
        menuItems: defaultMenuItems,
        fetching: false,
      }
    case START_FETCHING:
      return { ...state, fetching: true }
    case 'persist/PURGE':
    default:
      return state
  }
}

export default menuItems
