import { createAction } from 'redux-actions'
import * as actions from '../consts'

const pricesFake = () => ({
  fake: {
    name: 'prices',
    success: actions.SET_PRICES,
  },
})

export const clearPrices = createAction(actions.CLEAR_PRICES)
export const clearSailRefIdPrices = createAction(actions.CLEAR_SAIL_REF_ID_PRICES)
export const fetchPrices = createAction(actions.FETCH_PRICES)
export const fetchPricesForSelectedSails = createAction(actions.FETCH_PRICES_FOR_SELECTED_SAILS, null, pricesFake)
export const setPricesState = createAction(actions.SET_PRICES)

export const calculatePrice = createAction(actions.CALCULATE_VEHICLE_PRICE)
