import axios from 'axios'
import { curry } from 'ramda'
import { projectTotalPagesAndData } from '../utils'

const getItemsWithTotalPages = curry(projectTotalPagesAndData)([
  'body',
  'subject',
  'published_at',
  'id',
  'severity',
  'image',
])

export function getNewsItems(locale, page) {
  return axios
    .get('api/sales-static/news', {
      params: { page, locale },
    })
    .then(getItemsWithTotalPages)
}
