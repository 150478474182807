import { normalize } from 'normalizr'

import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchPorts, fetchPortsSuccess, fetchPortsError } from './actions'
import * as api from './api'
import fakeApi from './fake-api'
import { portsSchema } from './schemas'

function* fetchPortsSaga() {
  try {
    const fake = location.href.indexOf('fake') >= 0
    const data = yield call(fake ? fakeApi.fetchPorts() : api.fetchPorts)
    yield put(fetchPortsSuccess(normalize(data.content, portsSchema)))
  } catch (e) {
    yield put(fetchPortsError())
  }
}

export function* portsSaga() {
  yield takeLatest(fetchPorts, fetchPortsSaga)
}
