import * as Actions from '../consts'
import { normalizeData } from '../../utils'
import { reservationsSchema } from '../schemas'

const initialReservations = {}

const reservations = (state = initialReservations, action) => {
  const { payload } = action
  switch (action.type) {
    case Actions.FETCH_ALL_USER_RESERVATIONS_SUCCESS:
      return normalizeData('reservations', reservationsSchema)(payload)
    case Actions.RESET_USER:
      return initialReservations
    case Actions.FETCH_ALL_USER_RESERVATIONS_FAIL:
    default:
      return state
  }
}

export default reservations
