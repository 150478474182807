import React, { Fragment, useState } from 'react'
import 'regenerator-runtime/runtime'
import { Provider, connect } from 'react-redux'
import 'react-dates/initialize'
import { HashRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom'

import { PersistGate } from 'redux-persist/lib/integration/react'
import { whyDidYouUpdate } from 'why-did-you-update'

import { UIChangeAction, UIProvider, UILayout, UIText, UIFonts, MaterialUI } from 'bora-material-ui'

import { AccessToHistory, LocaleProvider } from './modules/Common'

import PromotionsView from './modules/Promotions'
// import { Language } from './modules/Language'
// import Notifications from './modules/Notifications'
import Modal from './modules/Modal'
import { SentryCatcher } from './SentryCatcher'
import { Reservation, MyReservations } from './modules/MyReservations'
import { ReservationScreen, CheckIn } from './modules/Checkin'
import { MainInsideView, PaymentResults, RefundScreen, CancelledReservation, RefundResults } from './modules/Booking'
import { SalesStaticInsideView } from './modules/SalesStatic'
import { Join, Login, Recovery, Signup, CompanySignup } from './modules/Auth'
import { Profile, CreateCorporateAccount } from './modules/Profile'
import { MyTicketEditMode, EditedTicketPay } from './modules/MyTicket/index.js'
import RefundEdit from './modules/Booking/containers/Refund/RefundEdit'

import messages from './consts/messages'
import UIElements from './consts/UIElements'
import {
  SeaJetsFooter,
  TallinkFooter,
  PosidoniaFooter,
  GavdosFooter,
  LiinilaevadFooter,
} from './modules/Themes/components'
import './styles/main.css'
import { store, persistor } from './store/createStore'
import { getLocale } from './services/user-selections'
import { generateRoutesWithLocale, defaultLocale } from './utils/locale'

import AppBar, { isMobile } from './modules/Common/components/AppBar.js'
import { StandaloneEditTicket } from './modules/Ticket/EditTicket/StandaloneEditTicket'
import AppLayout from './containers/AppLayout'
import { THEME } from 'bora-material-ui/themes'
import { translate } from './modules/Common/Translator'
import { capitalize } from './services/utils'
import { getGavdosStyle } from './utils/gavdosStyleUtils'
import NotFound from './modules/NotFound/NotFound'

if (process.env.NODE_ENV !== 'production' && process.env.PERFORMANCE_ANALYZE) {
  whyDidYouUpdate(React, {
    groupByComponent: false,
    collapseComponentGroups: true,
    include: [/^Connect/],
  })
}

UIChangeAction('MaterialUI', MaterialUI)(store.dispatch)

const styles = {
  headerWrapperXs: { height: 'auto' },
  headerStyle: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '20%',
    backgroundSize: 'cover',
    backgroundColor: UIElements.BACKGROUND_COLOR,
  },
  headerXs: {
    height: '77px',
    center: true,
    'flex-end': false,
    'j-flex-center': true,
    'j-flex-start': false,
  },
  selectRouteXs: { id: 'i18n.selectRoute.mobile' },
  contentXs: { width: '100%' },
  switcherWrapperXs: { center: false, padding: '0', bgColor: UIElements.BACKGROUND ? 'transparent' : '#1f282c' },
  copyrightWrapperXs: {
    center: true,
    column: true,
    opacity: 1,
  },
  footerTextMobile: {
    color: 'black',
    fontWeight: '600',
  },
  cookiesBanner: {
    'background-color': 'white',
    padding: '10px 5px',
    color: '#79909b',
    'font-family': 'Roboto, sans-serif',
    'font-weight': '600',
  },
  cookiesBannerXs: {
    column: true,
  },
  cookiesBannerLink: {
    'text-decoration': 'none',
    color: '#79909b',
    'margin-left': '5px',
  },
  cookiesBannerButton: {
    width: getGavdosStyle('auto', '87px'),
    height: '30px',
    padding: '7px 5px 7px 6px',
    'border-radius': '4px',
    'background-color': '#79909b',
    margin: getGavdosStyle('8px 20px', '0 20px'),
    cursor: 'pointer',
  },
}
const CookieBanner = ({ locale }) => {
  const initialValue = localStorage.getItem('cookieEnabled')
  const [isAccepted, setAccepted] = useState(initialValue === 'true')
  const acceptCookiesTerms = () => {
    if (!initialValue && !isAccepted) {
      setAccepted(true)
      localStorage.setItem('cookieEnabled', 'true')
    }
  }
  const { host: url, hostname } = window.location
  let privacyPolicyLink = hostname
  if (window.brandProps.muiTheme.privacyLink) {
    privacyPolicyLink = window.brandProps.muiTheme.privacyLink[locale]
      ? window.brandProps.muiTheme.privacyLink[locale]
      : window.brandProps.muiTheme.privacyLink.et
  }
  return (
    <UILayout
      display-if={!isAccepted}
      justify={'center'}
      align={'center'}
      wrap={'wrap'}
      style={{
        ...styles.cookiesBanner,
      }}
      xs={styles.cookiesBannerXs}
    >
      <UIText
        color={styles.cookiesBanner.color}
        font={styles.cookiesBanner['font-family']}
        align="center"
        text={translate(messages.cookieBannerDescription, { url: capitalize(url) })}
      />
      <a style={{ ...styles.cookiesBannerLink }} href={privacyPolicyLink} target="_blank">
        <UIText
          color={styles.cookiesBanner.color}
          font={styles.cookiesBanner['font-family']}
          textDecoration="underline"
          align="center"
          translate={messages.privacyPolicy}
        />
      </a>
      <UILayout
        style={{ ...styles.cookiesBannerButton }}
        justify={'center'}
        align={'center'}
        onClick={acceptCookiesTerms}
      >
        <UIText
          color={'white'}
          font={styles.cookiesBanner['font-family']}
          align="center"
          translate={messages.agreePrivacyPolicy}
        />
      </UILayout>
    </UILayout>
  )
}
const Header = withRouter((props) => (
  <UILayout
    display-if={UIElements.HEADER}
    bg={UIElements.HEADER}
    height="402px"
    width="100%"
    xs={styles.headerWrapperXs}
    style={styles.headerStyle}
  >
    <UILayout
      xs={styles.headerXs}
      height="112px"
      flex-end
      j-flex-start
      display-if={!props.location.pathname.includes('auth') && !props.location.pathname.includes('my')}
    >
      <UIText
        id="i18n.selectRoute"
        xs={styles.selectRouteXs}
        translate={props.location.pathname.includes('reservation') ? messages.yourTickets : messages.routeSelect}
      />
    </UILayout>
  </UILayout>
))

const accessToHistory = new AccessToHistory()

const KeepHistory = withRouter((props) => {
  accessToHistory.updateHistoryInstance(props.history)
  return null
})

const Footer = (props) => {
  const { theme } = props
  switch (theme) {
    case 'tallink':
      return <TallinkFooter />
    case 'seajets':
      return <SeaJetsFooter />
    case 'posidonia':
      return <PosidoniaFooter />
    case 'gavdos':
      return <GavdosFooter />
    case 'liinilaevad':
      return <LiinilaevadFooter />
    default:
      return null
  }
}

const routes = [
  { path: '/auth/login', component: Login },
  { path: '/auth/join', component: Join },
  { path: '/auth/join/:joinToken', component: Join },
  { path: '/auth/recovery', component: Recovery },
  { path: '/auth/signup', component: Signup },
  { path: '/corporate/new', component: CreateCorporateAccount },
  { path: '/checkin', component: CheckIn },
  { path: '/main-inside/*', component: MainInsideView },
  { path: '/content/*', component: SalesStaticInsideView },
  { path: '/payment/results', component: PaymentResults },
  { path: '/ticket/:reservationId', component: PaymentResults },
  { path: '/refund', component: RefundScreen },
  { path: '/refund/results', component: RefundResults },
  { path: '/myticket-edit/refund', component: RefundEdit },
  { path: '/my/reservations', component: MyReservations }, // out-of-date component, is used by customer?
  { path: '/my/reservation/:reservationId', component: Reservation }, // out-of-date component, is used by customer?
  { path: '/reservation/:reservationId', component: ReservationScreen },
  { path: '/profile/:tabName', component: Profile, protected: true },
  { path: '/profile/:tabName/*', component: Profile, protected: true },
  { path: '/profile/', component: Profile, protected: true },
  { path: '/edit-pay', component: EditedTicketPay },
  { path: '/add-company', component: CompanySignup, protected: true },
  { path: '/sail-cancelled/:reservationId', component: CancelledReservation }, // out-of-date component, is used by customer?
  { path: '/myticket-edit', component: MyTicketEditMode }, // out-of-date component, is used by customer?
  { path: '/not-found', component: NotFound },
  // TODO: Remove reservations ids (and route below) before release
  { path: '/ticket/:reservationId/edit', component: StandaloneEditTicket },
  { path: '/*', component: PromotionsView },
  { path: '*', component: NotFound },
]

const App = connect((state) => ({
  locale: state.locale,
  notifications: state.notifications,
}))((props) => (
  <PersistGate loading={null} persistor={persistor}>
    <LocaleProvider locale={props.locale} defaultLocale={defaultLocale} supported={window.brandProps.languages}>
      <Router>
        <Fragment>
          <KeepHistory />
          {/* <Route path="/language" component={Language} /> */}
          <AppLayout>
            <CookieBanner display-if={!UIElements.DISABLE_COOKIE_BANNER && !isMobile} locale={props.locale} />
            <UILayout
              height="15px"
              bg={UIElements.TOP_STRIP}
              style={{ backgroundRepeatY: styles.headerStyle.backgroundRepeat }}
              display-if={UIElements.TOP_STRIP}
            />
            <UIFonts />
            <AppBar />
            {/* <Notifications /> */}
            <Modal />
            <UILayout
              className="main-app-layout"
              position="relative"
              column
              width="100%"
              maxWidth={UIElements.APP_WIDTH}
              xs={styles.contentXs}
              j-flex-center
              margin={{
                ...(window.brandProps.muiTheme.appBar &&
                  window.brandProps.muiTheme.appBar.margin && { ...window.brandProps.muiTheme.appBar.margin }),
              }}
            >
              <Header />
              <Fragment>
                <UILayout
                  column
                  center
                  width="100%"
                  j-flex-start
                  padding={UIElements.CONTENT_PADDING || '0 0 200px 0'}
                  bgColor={UIElements.BACKGROUND ? UIElements.BACKGROUND : '#FFF'}
                  xs={styles.switcherWrapperXs}
                >
                  <Switch>{generateRoutesWithLocale(routes)}</Switch>
                </UILayout>
              </Fragment>
              <UILayout display-if={UIElements.SHOW_FOOTER}>
                <Footer theme={UIElements.LOGO} />
              </UILayout>
            </UILayout>
            <Footer display-if={UIElements.SHOW_FOOTER_OUTSIDE_LAYOUT} theme={UIElements.LOGO} />
            <UILayout
              data-test="copyright"
              margin="20px"
              width={UIElements.APP_WIDTH}
              opacity="0.9"
              display-if={window.brandProps.theme === THEME.KIHNU}
              xs={styles.copyrightWrapperXs}
              pre-ms={styles.copyrightWrapperXs}
            >
              <UIText align="left" color="rgb(44, 70, 132)" font="GinesoNorm" xs={styles.footerTextMobile}>
                &copy; AS Kihnu Veeteed | Powered by &nbsp;
                <a href="https://bora.systems/" target="_blank">
                  Bora Systems
                </a>
              </UIText>
            </UILayout>
          </AppLayout>
        </Fragment>
      </Router>
    </LocaleProvider>
  </PersistGate>
))

export default () => (
  <SentryCatcher>
    <Provider store={store}>
      <UIProvider>
        <Router>
          <Switch>
            <Route path="/:locale" component={App} />
            <Redirect exact from="/" to={`/${getLocale()(store.getState())}/`} />
            <Redirect from="//" to={`/${getLocale()(store.getState())}/`} />
          </Switch>
        </Router>
      </UIProvider>
    </Provider>
  </SentryCatcher>
)
