import * as AppActions from '../consts/actions'

const initialState = {}

export const passengerToEdit = (state = initialState, { type, payload }) => {
  switch (type) {
    case AppActions.SET_PASSENGER_TO_EDIT: {
      return payload
    }
    default:
      return state
  }
}
