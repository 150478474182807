import {
  always,
  anyPass,
  ascend,
  assoc,
  compose,
  cond,
  curry,
  defaultTo,
  descend,
  equals,
  filter,
  isEmpty,
  isNil,
  keys,
  map,
  not,
  pick,
  prop,
  propEq,
  reduce,
  reject,
  sortWith,
  T,
} from 'ramda'

export const notEmpty = compose(not, isEmpty)
export const notNil = compose(not, isNil)
export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
)

export function debouncePromise(inner, ms = 0) {
  let timer = null
  let resolveFn = null

  return function debounced(...args) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      const result = inner(...args)
      if (resolveFn) resolveFn(result)
      resolveFn = null
    }, ms)

    return new Promise((r) => {
      resolveFn = r
    })
  }
}

export const sortVehicles = sortWith([descend(prop('priceCategorySubType'))])

export const getSortedVehiclesOnly = compose(
  sortVehicles,
  reject(propEq('type', 'BICYCLE')),
  filter(propEq('type', 'VEHICLE')),
  defaultTo([])
)

const getOrderIndex = cond([
  [equals('DECK'), always(0)],
  [equals('CABIN'), always(1)],
  [equals('BICYCLE'), always(10)],
  [equals('VEHICLE'), always(10)],
  [equals('TRAILER'), always(15)],
  [equals('DEFAULT'), always(800)],
  [T, always(900)],
])

export const getTicketTypeByPriceCategory = cond([
  [equals('DECK'), always('PASSENGER')],
  [equals('CABIN'), always('PASSENGER')],
  [equals('BICYCLE'), always('BICYCLE')],
  [equals('VEHICLE'), always('CAR')],
  [equals('TRAILER'), always('TRAILER')],
  [equals('DEFAULT'), always('PASSENGER')],
  [T, always('UNKNOWN')],
])

export const sortAllItems = compose(
  sortWith([ascend(prop('orderIndex'))]),
  map((item) => ({
    ...item,
    orderIndex: getOrderIndex(item.priceCategorySubType),
  }))
)

const vehicleFormHelper = (acc, { registryCategory = '', licencePlate, ...rest }) => ({
  ...acc,
  [licencePlate]: { simple: Boolean(registryCategory), ...rest },
})

export const onlyTrailerOrCarVehicles = compose(
  reduce(vehicleFormHelper, {}),
  map(pick(['type', 'registryCategory', 'seqN'])),
  filter(anyPass([propEq('type', 'CAR'), propEq('type', 'TRAILER')]))
)
