import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'

import {
  addToSelectedSailPackage,
  dropReverseSailPackage,
  saveTempPassengerData,
  selectPassengerToEditPersonalData,
  selectReverseSailPackage,
} from './actions'
import { getReversePackage } from './selectors'
import { removeSailRefByCode } from '../schedule/actions/schedule'
import { getFormValues } from 'redux-form'
import { pathOr } from 'ramda'

function* setReverseSailPackageSaga({ payload: isRoundTrip }) {
  if (isRoundTrip) {
    const reversePackage = yield select(getReversePackage)
    yield put(addToSelectedSailPackage({ ...reversePackage, source: 'saga' }))
  } else {
    yield put(dropReverseSailPackage())
  }
}

function* removeSelectedSailRefId({ payload: code }) {
  yield put(removeSailRefByCode(code))
}

function* onSelectingNextManifestPassenger() {
  const formValues = yield select(getFormValues('bookingForm'))
  const passenger = pathOr({}, ['passengers', 0])(formValues)
  const { seqN, firstName = '', lastName = '' } = passenger
  if (firstName && lastName) yield put(saveTempPassengerData({ seqNum: seqN, passenger }))
}

function* watchSetReversePackage() {
  yield takeLatest(selectReverseSailPackage, setReverseSailPackageSaga)
}

function* watchSetDroppingSailPackage() {
  yield takeEvery(dropReverseSailPackage, removeSelectedSailRefId)
}

function* watchSetNextManifestPassengerToEdit() {
  yield takeEvery(selectPassengerToEditPersonalData, onSelectingNextManifestPassenger)
}

export function* selectionsSaga() {
  yield [watchSetReversePackage(), watchSetDroppingSailPackage(), watchSetNextManifestPassengerToEdit()]
}
