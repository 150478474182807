import { normalize } from 'normalizr'
import { pathOr } from 'ramda'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  fetchAllSailPackages,
  fetchSailPackagesSuccess,
  fetchSailPackagesError,
  manageSailPackagesFetchResponse,
  setSailPackagesState,
} from './actions'
import { fetchSailPackages as fetchRequest } from './api'
import { sailPackagesSchema } from './schemas'
import { routesSchema } from '../routes/schemas'
import { getRoutesFromPackages } from '../../utils'
import { fetchRoutesSuccess, setRoutesState } from '../routes/actions'

const normilizeData = (data) => {
  const normalized = normalize(data.content, sailPackagesSchema)
  const routes = getRoutesFromPackages(data.content)
  const normalizedRoutes = normalize(routes, routesSchema)

  return {
    sailPackages: normalized,
    routes: normalizedRoutes,
  }
}

function* setState({ payload }) {
  const data = payload
  const { sailPackages, routes } = normilizeData(data)

  yield put(setRoutesState(pathOr({}, ['entities', 'routes'], routes)))
  yield put(setSailPackagesState(pathOr({}, ['entities', 'sailPackages'], sailPackages)))
}

function* fetchSailPackagesSaga() {
  try {
    const data = yield call(fetchRequest)
    const { sailPackages, routes } = normilizeData(data)
    yield put(fetchSailPackagesSuccess(sailPackages))
    yield put(fetchRoutesSuccess(routes))
  } catch (e) {
    yield put(fetchSailPackagesError())
  }
}

function* watchFetchAllSailPackages() {
  yield takeLatest(fetchAllSailPackages, fetchSailPackagesSaga)
}

function* watchFetchSailPackageResponse() {
  yield takeLatest(manageSailPackagesFetchResponse, setState)
}

export function* sailPackagesSaga() {
  yield [watchFetchAllSailPackages(), watchFetchSailPackageResponse()]
}
