import moment from 'moment'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { changeLocale, getLocale, toggleMobileMenu } from '../user-selections'
import { getMenuItems } from './api'
import { FETCH_MENU_ITEMS_FAILED, FETCH_MENU_ITEMS_SUCCESS } from './consts/menuItems'

function* fetchMenuItems() {
  yield put(toggleMobileMenu(false))
  const locale = yield select(getLocale())
  try {
    const data = yield call(getMenuItems, locale)
    moment.locale(locale)
    yield put({
      type: FETCH_MENU_ITEMS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    yield put({
      type: FETCH_MENU_ITEMS_FAILED,
      payload: error,
    })
  }
}

function* watchLocaleChange() {
  yield takeLatest(changeLocale, fetchMenuItems)
}

export function* salesStaticSaga() {
  yield [fetchMenuItems(), watchLocaleChange()]
}
