export const PAYMENT_PAY = 'payment/PAY'
export const PAYMENT_PAYING = 'payment/PAYING'
export const PAYMENT_PAYED = 'payment/PAYED'
export const PAYMENT_PAY_FAILED = 'payment/PAY_FAILED'
export const PAYMENT_RESERVATION_ID = 'payment/RESERVATION_ID'
export const PREPARE_RESERVATION_PAYMENT = 'payment/PREPARE_RESERVATION_PAYMENT'
export const SEND_TRANSACTION_STATUS = 'payment/SEND_TRANSACTION_STATUS'
export const IMMEDIATELY_PAYMENT_PAYED = 'payment/IMMEDIATELY_PAYMENT_PAYED'
export const SEND_TRANSACTION_STATUS_FAILED = 'payment/SEND_TRANSACTION_STATUS_FAILED'
export const SEND_TRANSACTION_STATUS_SUCCESS = 'payment/SEND_TRANSACTION_STATUS_SUCCESS'
export const PAYMENT_REPLACE_LOCATION = 'payment/PAYMENT_REPLACE_LOCATION'
export const CLEAR_PAYMENT = 'payment/CLEAR_PAYMENT'
export const SEND_CREDIT_CARD_PAYMENT = 'payment/SEND_CREDIT_CARD_PAYMENT'
export const CREDIT_CARD_PAYMENT_WITHOUT_3D_COMPLETED = 'payment/CREDIT_CARD_PAYMENT_WITHOUT_3D_COMPLETED'
export const STRIPE_PAYMENT_FAILED = 'payment/STRIPE_PAYMENT_FAILED'
