import { compose, reduce, map, defaultTo, any, equals, keys, isEmpty, pathOr } from 'ramda'

const getReservesAmount = pathOr(0, [0, 'amount'])

export const sailsInventoriesMutator = (inventoryData = {}) =>
  Object.entries(inventoryData.availabilities || {}).map((sailInventories) => ({
    inventories: sailInventories[1],
    sailRefId: sailInventories[0],
    routeLeg: inventoryData.routeLeg,
  }))

const rdc = (acc, { inventoryClass, sailPackageSeqN, quantity, resident }) => {
  const key = `${sailPackageSeqN}:${inventoryClass}${resident && '-R'}`
  return {
    ...acc,
    [key]: acc[key] ? acc[key] + quantity : quantity,
  }
}
export const getInventoryClassItemAmounts = compose(
  reduce(rdc, {}),
  map(({ inventoryClass, sailPackageSeqN, quantity, resident }) => ({
    inventoryClass,
    sailPackageSeqN: sailPackageSeqN - 1,
    quantity,
    resident,
  })),
  defaultTo([])
)

export const inventoryCannotFitAmounts = (inventory, amounts, index) => {
  const errors = inventory.map(({ inventoryClass, amount, reserves = [] }) => {
    const residentsAmount = getReservesAmount(reserves)
    if (residentsAmount) {
      const required = amounts[`${index}:${inventoryClass === 'CAR_DECK' ? 'HIGH METERS' : inventoryClass}-R`] || 0
      return required > residentsAmount + amount
    }
    const required = amounts[`${index}:${inventoryClass === 'CAR_DECK' ? 'HIGH METERS' : inventoryClass}`] || 0
    return required > amount
  })

  return any(equals(true))(errors)
}

export const convertCurrentInventoryForVoyages = (amounts) => {
  if (isEmpty(amounts))
    return {
      Passenger: 0,
      Bike: 0,
      Car: 0,
    }

  return keys(amounts).reduce((acc, key) => {
    if (key.endsWith(':DECK')) {
      return { ...acc, Passenger: amounts[key] }
    } else if (key.endsWith(':BICYCLE')) {
      return { ...acc, Bike: amounts[key] }
    } else if (key.endsWith(':HIGH METERS')) {
      return { ...acc, Car: amounts[key] }
    }
    return { ...acc }
  }, {})
}

export const checkIfBookingOutOfRange = (bookingAttribute = '', selectedDate = '') => {
  let bookingBoundary = ''
  bookingBoundary = bookingAttribute.value.replace('-, ', '')
  return new Date(selectedDate) > new Date(bookingBoundary)
}
