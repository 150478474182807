import { defaultTo, pathOr, compose, not } from 'ramda'

const defaultReservation = {
  allowance: { cancel: false, edit: false },
}

const getProp = (prop) => compose(pathOr(false, ['allowance', prop]), defaultTo(defaultReservation))
const getPropReversed = (prop) => compose(not, getProp(prop))

export const canEdit = getProp('edit')
export const canCancel = getProp('cancel')
export const cannotEdit = getPropReversed('edit')
export const cannotCancel = getPropReversed('cancel')
