import * as consts from './consts'
import * as actions from './actions'
import * as sagas from './sagas'
import reducers, * as selectors from './reducers/inventory'

export default {
  actions,
  consts,
  selectors,
  reducers,
  sagas,
}
