import { reduce, pathOr, omit } from 'ramda'

import * as userActions from '../consts'
import * as customerActions from '../../customer/consts'
import { normalizeData } from '../../utils'
import { companiesSchema } from '../schemas'

const initialCompanies = {}

const byCompanyId = (acc, { company, ...member }) => {
  acc[company.companyId] = { ...company, member }
  return acc
}

const companies = (state = initialCompanies, action) => {
  const { payload } = action
  switch (action.type) {
    case customerActions.FETCH_USER_CUSTOMER_INFO_SUCCESS: {
      const memberOf = pathOr([], ['payload', 'memberOf'], action)
      return reduce(byCompanyId, {}, memberOf)
    }
    case userActions.UPSERT_USER_COMPANY_SUCCESS:
      return normalizeData('companies', companiesSchema)(payload)
    case userActions.DELETE_USER_COMPANY_SUCCESS:
      return omit([payload], state)
    case userActions.RESET_USER:
      return initialCompanies
    case customerActions.FETCH_USER_CUSTOMER_INFO_FAIL:
    case userActions.UPSERT_USER_COMPANY_FAIL:
    case userActions.DELETE_USER_COMPANY_FAIL:
    default:
      return state
  }
}

export default companies
