import * as AppActions from '../consts/actions'

const initialState = {}

export const modalData = (state = initialState, { type, payload }) => {
  switch (type) {
    case AppActions.SET_MODAL_DATA: {
      return payload
    }
    case AppActions.CLEAR_MODAL_DATA: {
      return initialState
    }
    default:
      return state
  }
}
