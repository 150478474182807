import { createAction } from 'redux-actions'
import {
  STRIPE_PAYMENT_FAILED,
  PAYMENT_PAYING,
  PREPARE_RESERVATION_PAYMENT,
  SEND_TRANSACTION_STATUS,
  IMMEDIATELY_PAYMENT_PAYED,
  PAYMENT_REPLACE_LOCATION,
  CLEAR_PAYMENT,
  SEND_CREDIT_CARD_PAYMENT,
  CREDIT_CARD_PAYMENT_WITHOUT_3D_COMPLETED,
} from '../consts/payment'

const preparePaymentFake = () => ({
  fake: {
    name: 'payment.prepare',
    success: IMMEDIATELY_PAYMENT_PAYED,
  },
})

export const prepareForPayment = createAction(PREPARE_RESERVATION_PAYMENT, null, preparePaymentFake)
export const paymentReplaceLocation = createAction(PAYMENT_REPLACE_LOCATION)
export const pay = createAction(PAYMENT_PAYING)
export const sendTransactionStatus = createAction(SEND_TRANSACTION_STATUS)
export const clearPayment = createAction(CLEAR_PAYMENT)
export const sendCreditCardPayment = createAction(SEND_CREDIT_CARD_PAYMENT)
export const creditCardPaymentComplete = createAction(CREDIT_CARD_PAYMENT_WITHOUT_3D_COMPLETED)
export const stripePaymentFailed = createAction(STRIPE_PAYMENT_FAILED)
