import axios from 'axios'
import * as R from 'ramda'

export const getPrices = (sailRefId, roundTrip = false, oneDayRoundTrip = false) =>
  axios({
    method: 'get',
    url: `/api/prices/${sailRefId}?roundTrip=${roundTrip}&oneDayRoundTrip=${oneDayRoundTrip}`,
    validateStatus: (status) => (status >= 200 && status < 300) || status === 307,
  })

export const fetchPricesBySailRefsIds = (rt = false, oneDayRoundTrip = false) =>
  R.compose(
    (fetches) => Promise.all(fetches),
    R.map((sailRefId) => getPrices(sailRefId, rt, oneDayRoundTrip))
  )

export const calculateVehiclePrices = ({
  plateNumber,
  sailRefId,
  priceCategory,
  heightInCm,
  lengthInCm,
  weightInKg,
  reservationId,
  widthInCm,
  companyRegistrationNumber,
}) => {
  const queryParams = {
    ...(reservationId && { reservationId }),
    ...(companyRegistrationNumber && { companyRegistrationNumber }),
  }
  const queryParamsString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&')
  return axios({
    method: 'put',
    url: `/api/vehicle/${plateNumber}/sail/${sailRefId}/priceCategory/${priceCategory}${
      queryParamsString.length ? '?' : ''
    }${queryParamsString}`,
    data: {
      heightInCm,
      lengthInCm,
      weightInKg,
      widthInCm,
    },
  })
}
