import { pathOr } from 'ramda'
import * as AppActions from '../consts/actions'

const initialState = ''

const viewMode = (state = initialState, action) => {
  switch (action.type) {
    case AppActions.SET_VIEW_MODE: {
      return pathOr(state, ['payload'], action)
    }
    default:
      return state
  }
}

export default viewMode
