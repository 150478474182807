import { put, select, takeEvery } from 'redux-saga/effects'

import { pathOr, reduce } from 'ramda'

import { selections, customer as customerService } from '../../services'

const { fetchUserCustomerInfoSuccess } = customerService.actions
const { getCustomerRole, selectCustomerRole } = selections

function* setActualCompaniesInSelect({ payload }) {
  const byCompanyId = (acc, { company, ...member }) => {
    acc[company.companyId] = { ...company, member }
    return acc
  }
  const memberOf = pathOr([], ['memberOf'], payload)
  const companies = reduce(byCompanyId, {}, memberOf)
  const selectedRole = yield select(getCustomerRole)

  if (selectedRole.registrationNumber && companies[selectedRole.code]) {
    const { companyId, legalName, ...company } = companies[selectedRole.code]
    yield put(selectCustomerRole({ ...company, code: companyId, name: legalName }))
  }
}

function* watchFetchUserCustomerSuccess() {
  yield takeEvery(fetchUserCustomerInfoSuccess, setActualCompaniesInSelect)
}

export default function* app() {
  yield [watchFetchUserCustomerSuccess()]
}
