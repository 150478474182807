import * as actions from './actions'
import reducer from './reducers'
import * as selectors from './selectors'
import * as constants from './consts'
import sagas from './sagas'

export default {
  actions,
  reducer,
  constants,
  selectors,
  sagas,
}
