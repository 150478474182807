import { pathOr } from 'ramda'
import * as AppActions from '../consts/actions'
import { RESERVATION_ABOUT_TO_EXPIRE, RESERVATION_EXPIRED } from '../services/reservation/consts/reservation'

const initialState = ''

const modal = (state = initialState, action) => {
  switch (action.type) {
    case AppActions.SHOW_MODAL: {
      return pathOr(state, ['payload'], action)
    }
    case RESERVATION_EXPIRED: {
      return 'reservationExpired'
    }
    case RESERVATION_ABOUT_TO_EXPIRE: {
      return 'reservationWannaExpired'
    }
    default:
      return state
  }
}

export default modal
