export const addHotelsToTransferAddons = (items, reservation) =>
  items.map((item) => {
    if (item.inventoryClass !== 'TRANSFER') return item
    const [transferItemId] = item.ownerSeqNs
    const addon = reservation.addons.find((addon) => addon.seqN === transferItemId)
    const hotel = addon.attributes[0].structure.value.value
    const titleWithHotel = `${item.priceCategoryTranslation}: ${hotel}`
    const itemWithHotel = { ...item, priceCategoryTranslation: titleWithHotel }
    return itemWithHotel
  })
