export const RESERVATION_CREATE = 'reservation/RESERVATION_CREATE'
export const RESERVATION_CREATED = 'reservation/RESERVATION_CREATED'
export const RESERVATION_CREATE_FAILED = 'reservation/RESERVATION_CREATE_FAILED'
export const RESERVATION_MODIFY = 'reservation/RESERVATION_MODIFY'
export const RESERVATION_MODIFIED = 'reservation/RESERVATION_MODIFIED'
export const RESERVATION_MODIFY_FAILED = 'reservation/RESERVATION_MODIFY_FAILED'
export const RESERVATION_CONFIRM = 'reservation/RESERVATION_CONFIRM'
export const RESERVATION_CONFIRM_SUCCESS = 'reservation/RESERVATION_CONFIRM_SUCCESS'
export const RESERVATION_CONFIRM_FAILED = 'reservation/RESERVATION_CONFIRM_FAILED'
export const GET_RESERVATION_PARTS = 'reservation/GET_RESERVATION_PARTS'
export const GET_RESERVATION_PARTS_SUCCESS = 'reservation/GET_RESERVATION_PARTS_SUCCESS'
export const GET_RESERVATION_PARTS_FAILED = 'reservation/GET_RESERVATION_PARTS_FAILED'
export const GET_RESERVATION = 'reservation/GET_RESERVATION'
export const GET_RESERVATION_SUCCESS = 'reservation/GET_RESERVATION_SUCCESS'
export const GET_RESERVATION_ON_LOCALE = 'reservation/GET_RESERVATION_ON_LOCALE'
export const GET_RESERVATION_FAILED = 'reservation/GET_RESERVATION_FAILED'
export const UPDATE_RESERVATION_INFO = 'reservation/UPDATE_RESERVATION_INFO'
export const UPDATE_LOCAL_RESERVATION = 'reservation/UPDATE_LOCAL_RESERVATION'
export const MANAGE_URL_RESERVATION = 'reservation/MANAGE_URL_RESERVATION'
export const REMOVE_CURRENT_RESERVATION = 'reservation/REMOVE_CURRENT_RESERVATION'
export const RESERVATION_ABOUT_TO_EXPIRE = 'reservation/RESERVATION_ABOUT_TO_EXPIRE'
export const RESERVATION_EXPIRED = 'reservation/RESERVATION_EXPIRED'
export const START_FETCHING = 'reservation/START_FETCHING'
export const EXTEND_RESERVATION = 'reservation/EXTEND_RESERVATION'
export const EXTEND_RESERVATION_FAILED = 'reservation/EXTEND_RESERVATION_FAILED'
export const CLEAR_RESERVATION = 'reservation/CLEAR_RESERVATION'
export const SET_RESERVATION = 'reservation/SET_RESERVATION'
export const SET_RESERVATION_FAILED = 'reservation/SET_RESERVATION_FAILED'
export const SET_REFUND_RESERVATION = 'reservation/SET_REFUND_RESERVATION'
export const SET_REFUND_RESERVATION_FAILED = 'reservation/SET_REFUND_RESERVATION_FAILED'

export const START_PRICE_CALCULATION = 'reservation/START_PRICE_CALCULATION'

export const FETCH_PAYMENT_METHODS = 'payment/FETCH_PAYMENT_METHODS'
export const FETCH_PAYMENT_METHODS_SUCCESS = 'payment/FETCH_PAYMENT_METHODS_SUCCESS'
export const FETCH_PAYMENT_METHODS_FAILED = 'payment/FETCH_PAYMENT_METHODS_FAILED'

export const REFUND_RESERVATION = 'reservation/REFUND_RESERVATION'
export const REFUND_RESERVATION_FAILED = 'reservation/REFUND_RESERVATION_FAILED'
export const REFUND_RESERVATION_SUCCESS = 'reservation/REFUND_RESERVATION_SUCCESS'

export const SET_EDITED_RESERVATION_DATA = 'reservation/SET_EDITED_RESERVATION_DATA'

export const START_EDIT_RESERVATION = 'reservation/START_EDIT_RESERVATION'
export const START_EDIT_RESERVATION_SUCCESS = 'reservation/START_EDIT_RESERVATION_SUCCESS'

export const CANCEL_EDIT_RESERVATION = 'reservation/CANCEL_EDIT_RESERVATION'
export const CANCEL_EDIT_RESERVATION_SUCCESS = 'reservation/CANCEL_EDIT_RESERVATION_SUCCESS'

export const FINISH_EDIT_RESERVATION = 'reservation/FINISH_EDIT_RESERVATION'
export const FINISH_EDIT_RESERVATION_SUCCESS = 'reservation/FINISH_EDIT_RESERVATION_SUCCESS'

export const EDIT_DATA_IN_RESERVATION = 'reservation/EDIT_DATA_IN_RESERVATION'
export const EDIT_DATA_IN_RESERVATION_SUCCESS = 'reservation/EDIT_DATA_IN_RESERVATION_SUCCESS'

export const WATCH_CANCELLED_RESERVATION = 'reservation/WATCH_CANCELLED_RESERVATION'

export const SET_CUSTOM_VEHICLE_WEIGHT = 'reservation/SET_CUSTOM_VEHICLE_WEIGHT'
export const RESET_CUSTOM_VEHICLE_WEIGHT = 'reservation/RESET_CUSTOM_VEHICLE_WEIGHT'
export const RESERVATION_WITHOUT_PASSENGERS_WARNING = 'reservation/RESERVATION_WITHOUT_PASSENGERS_WARNING'
export const CLEAR_RESERVATION_ERROR = 'reservation/CLEAR_RESERVATION_ERROR'
export const EXTEND_RESERVATION_EXPIRE = 'reservation/EXTEND_RESERVATION_EXPIRE'
export const CLEAR_EXPIRE_TIMERS = 'reservation/CLEAR_EXPIRE_TIMERS'
export const APPLY_PROMO_CODE = 'reservation/APPLY_PROMO_CODE'
export const REMOVE_PROMO_CODE = 'reservation/REMOVE_PROMO_CODE'
export const APPLY_PROMO_CODE_SUCCESS = 'reservation/APPLY_PROMO_CODE_SUCCESS'
export const APPLY_PROMO_CODE_FAILED = 'reservation/APPLY_PROMO_CODE_FAILED'
export const REMOVE_PROMO_CODE_SUCCESS = 'reservation/REMOVE_PROMO_CODE_SUCCESS'
export const REMOVE_PROMO_CODE_FAILED = 'reservation/REMOVE_PROMO_CODE_FAILED'
export const RESERVATION_FROM_RESERVE = 'reservation/RESERVATION_FROM_RESERVE'
