import { createSelector } from 'reselect'
import {
  has,
  prop,
  head,
  compose,
  last,
  and,
  pathOr,
  ifElse,
  propEq,
  not,
  find,
  curry,
  identity,
  values,
  map,
  sum,
  pick,
  keys,
  isEmpty,
  flip,
  gt,
  length,
  propOr,
} from 'ramda'

import { getRoutes } from '../routes/routes/reducers' // it should be independent

const getUserSelections = prop('userSelections')
const notWithThisCode = compose(not, propEq('code'))
const rnd = (num) => Math.round(num * 100) / 100
const getReverseForCode = (code) => find(curry(notWithThisCode)(code))

export const getEditableTrip = createSelector(
  getUserSelections,
  compose(
    ifElse(and(has('sailRefs'), has('code')), identity, () => undefined),
    prop('editableTrip')
  )
)

export const getSailDates = createSelector(getUserSelections, prop('sailDates'))
export const getSelectedPassengerSeqNum = createSelector(getUserSelections, propOr(0, 'selectedPassengerSeqNum'))
export const getCustomerRole = createSelector(getUserSelections, prop('customerRole'))
export const getSailDate = createSelector(getUserSelections, compose(head, prop('sailDates')))
export const getSailBackDate = createSelector(getUserSelections, compose(last, prop('sailDates')))
export const getSelectedSailPackages = createSelector(getUserSelections, prop('sailPackages'))
export const getSelectedSailPackageFirstRoute = createSelector(
  getUserSelections,
  pathOr('', ['sailPackages', [0], 'route'])
)
export const isLaaksaareRoute = createSelector(getSelectedSailPackageFirstRoute, (route) => route.startsWith('LAA-'))
export const getCommonUser = createSelector(getUserSelections, prop('commonUser'))
export const getSeats = createSelector(getUserSelections, prop('seats'))
export const getVehicleInputType = createSelector(getUserSelections, prop('vehicleInputType'))
export const getTrailerInputType = createSelector(getUserSelections, prop('trailerInputType'))

export const getMobileMenuStatus = createSelector(getUserSelections, prop('mobileMenuOpened'))
export const getSelectedRoute = createSelector(getUserSelections, pathOr('', ['sailPackages', [0], 'route']))

export const getLocale = createSelector(propOr('et', 'locale'), identity)

const priceCalc = compose(
  rnd,
  sum,
  map(({ price, count }) => price * count),
  map(pick(['price', 'count'])),
  values
)

export const isIntermediatePriceEmpty = createSelector(getSeats, isEmpty)
export const selectIntermediatePrice = createSelector(getSeats, priceCalc)
export const selectIntermediateCategories = createSelector(getSeats, keys)

const sailPackageCodeFromProps = (state, props) => pathOr('a', ['sailPackage', 'code'], props)
export const getSelectedSailPackageCodeFromProps = () => createSelector(sailPackageCodeFromProps, identity)

// REWRITE AT BASKET LOGIC
export const getFirstSelectedSailPackage = createSelector(getSelectedSailPackages, head)
export const getSecondSelectedSailPackage = createSelector(getSelectedSailPackages, last)
export const getTempPassengerData = createSelector(getUserSelections, propOr({}, 'tempPassengerFormData'))

export const getReversePackage = createSelector(
  [getRoutes, getFirstSelectedSailPackage],
  (allRoutes, { code, route }) => {
    const takeAnother = getReverseForCode(code)
    const reversePackage = takeAnother(allRoutes[route].sailPackages)

    return reversePackage
  }
)

export const isRoundTrip = createSelector(getUserSelections, compose(flip(gt)(1), length, prop('sailPackages')))
