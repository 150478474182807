import { handleActions } from 'redux-actions'
import * as actions from '../actions/vccf'

const defaultState = {
  fetching: false,
  types: [],
  addresses: [],
}

export default handleActions(
  {
    [actions.VCCFItemsFetchStart](state) {
      return {
        ...state,
        fetching: true,
      }
    },

    [actions.VCCFITEMSFetchFail](state) {
      return {
        ...state,
        fetching: false,
      }
    },

    [actions.VCCFItemsFetchSuccess](state, { payload }) {
      return {
        ...state,
        ...payload,
        fetching: false,
      }
    },
  },
  defaultState
)
