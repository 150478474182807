import * as consts from './consts'
import * as actions from './actions'
import * as sagas from './sagas'
import { prices as reducer, selectors } from './reducers'

export default {
  actions,
  reducer,
  consts,
  selectors,
  sagas,
}
