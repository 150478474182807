export const FETCH_PRICES_SUCCESS = 'PRICES:FETCH_SUCCESS'
export const FETCH_PRICES_FAIL = 'PRICES:FETCH_FAIL'
export const FETCH_PRICES = 'PRICES:FETCH_PRICES'
export const FETCH_PRICES_FOR_SELECTED_SAILS = 'PRICES:FETCH_PRICES_FOR_SELECTED_SAILS'
export const FETCH_PRICES_FOR_SELECTED_SAILS_FAIL = 'PRICES:FETCH_PRICES_FOR_SELECTED_SAILS_FAIL'
export const SET_PRICES = 'PRICES:SET_PRICES'
export const CLEAR_SAIL_REF_ID_PRICES = 'PRICES:CLEAR_SAIL_REF_ID_PRICES'
export const CLEAR_PRICES = 'PRICES:CLEAR_PRICES'
export const CALCULATE_VEHICLE_PRICE = 'PRICES:CALCULATE_VEHICLE_PRICE'
export const CALCULATE_VEHICLE_PRICE_SUCCESS = 'PRICES:CALCULATE_VEHICLE_PRICE_SUCCESS'
export const CALCULATE_VEHICLE_REQUESTING = 'PRICES:CALCULATE_VEHICLE_REQUESTING'
export const CALCULATE_VEHICLE_PRICE_FAIL = 'PRICES:CALCULATE_VEHICLE_PRICE_FAIL'
