import * as reducers from './reducers'
import * as actions from './actions'
import * as constants from './consts/menuItems'
import * as api from './api'
import * as selectors from './selectors'
import { salesStaticSaga } from './sagas'

export default {
  api,
  reducers,
  actions,
  constants,
  selectors,
  sagas: salesStaticSaga,
}
