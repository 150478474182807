export const FETCH_SCHEDULE_SUCCESS = 'SCHEDULE:FETCH_SUCCESS'
export const FETCH_SCHEDULE_FAIL = 'SCHEDULE:FETCH_FAIL'
export const FETCH_AVAILABLE_REPLACEMENT_LEGS = 'SCHEDULE:FETCH_AVAILABLE_REPLACEMENT_LEGS'
export const FETCH_AVAILABLE_REPLACEMENT_LEGS_SUCCESS = 'SCHEDULE:FETCH_AVAILABLE_REPLACEMENT_LEGS_SUCCESS'
export const FETCH_AVAILABLE_REPLACEMENT_LEGS_FAIL = 'SCHEDULE:FETCH_AVAILABLE_REPLACEMENT_LEGS_FAIL'
export const FETCH_SAILS_SCHEDULE = 'SCHEDULE:FETCH_SAILS_SCHEDULE'
export const FETCH_SAILS_FOR_LEG_AND_DATE = 'SCHEDULE:FETCH_SAILS_FOR_LEG_AND_DATE'
export const FETCH_SCHEDULE_AVAILABLE_DATES_SUCCESS = 'SCHEDULE:FETCH_SCHEDULE_AVAILABLE_DATES_SUCCESS'
export const FETCH_SCHEDULE_AVAILABLE_DATES_FAIL = 'SCHEDULE:FETCH_SCHEDULE_AVAILABLE_DATES_FAIL'
export const CHANGE_SCHEDULE_DATE = 'SCHEDULE:CHANGE_DATE'
export const SELECT_SCHEDULE_SAIL = 'SCHEDULE:SELECT_SAIL'
export const SELECT_EDIT_SCHEDULE_SAIL = 'SCHEDULE:SELECT_EDIT_SAIL'
export const CLEAR_EDIT_SCHEDULE_SAIL_LIST = 'SCHEDULE:CLEAR_EDIT_SCHEDULE_SAIL_LIST'
export const CLEAR_SCHEDULE_SELECTED_SAILS = 'SCHEDULE:CLEAR_SCHEDULE_SELECTED_SAILS'
export const CLEAR_SCHEDULE = 'SCHEDULE:CLEAR_SCHEDULE'
export const MANAGE_SCHEDULE_PARTS_FETCH_RESPONSE = 'SCHEDULE:MANAGE_SCHEDULE_PARTS_FETCH_RESPONSE'
export const SET_SCHEDULE_STATE = 'SCHEDULE:SET_SCHEDULE_STATE'
export const OMIT_PACKAGE_FROM_SCHEDULE_STATE = 'SCHEDULE:OMIT_PACKAGE_FROM_SCHEDULE_STATE'
export const REMOVE_SAIL_REF_BY_CODE = 'SCHEDULE:REMOVE_SAIL_REF_BY_CODE'

export const SW_SAILS_FETCH = '[SW]/FETCH_SAILS'
export const SW_SAILS_FETCH_SUCCESS = '[SW]/FETCH_SAILS_SUCCESS'
export const SW_CLEAR_TIMEOUTS = '[SW]/CLEAR_TIMEOUTS'
