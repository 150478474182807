import jwtDecode from 'jwt-decode'
import moment from 'moment'

import { signout } from '../../services/auth/actions'

export function interceptor(store) {
  const { dispatch } = store

  return (next) => (action) => {
    if (
      action.type === 'navigate/GO_TO_PAGE' &&
      (action.payload.endsWith('/select-tickets') || action.payload.endsWith('/confirm'))
    ) {
      const { auth: { token = '' } = {} } = store.getState()

      if (!token) return next(action)

      try {
        const { expiration } = jwtDecode(token)

        if (expiration) {
          const tokenExpiration = Number(moment.unix(expiration).utc().toDate())
          const now = Number(new Date())
          const shouldLogout = now > tokenExpiration

          if (process.env.NODE_ENV !== 'production') {
            // console.log('Checking token expiration', {
            //   tokenExpiration,
            //   now,
            //   expireInTheFuture: now < tokenExpiration,
            //   shouldLogout,
            // })
          }

          if (shouldLogout) dispatch(signout())
        }
      } catch (e) {
        console.error('Cannot decode token')
      }
    }

    return next(action)
  }
}
