import { createSelector } from 'reselect'
import { prop, allPass, complement as not } from 'ramda'

const getAuth = prop('auth')

export const getToken = createSelector(getAuth, prop('token'))
export const getJoinToken = createSelector(getAuth, prop('joinToken'))
export const getChallengeID = createSelector(getAuth, prop('challengeID'))
export const getError = createSelector(getAuth, prop('error'))
export const getMessage = createSelector(getAuth, prop('message'))
export const getFetching = createSelector(getAuth, prop('isFetching'))
export const isLoggedInSelector = createSelector(
  getAuth,
  allPass([prop('token'), not(prop('error')), not(prop('isFetching'))])
)
