import { createAction } from 'redux-actions'
import {
  RESERVATION_MODIFY,
  RESERVATION_CREATE,
  GET_RESERVATION,
  START_FETCHING,
  MANAGE_URL_RESERVATION,
  GET_RESERVATION_PARTS,
  GET_RESERVATION_FAILED,
  EXTEND_RESERVATION,
  CLEAR_RESERVATION,
  SET_RESERVATION,
  SET_REFUND_RESERVATION,
  RESERVATION_CREATED,
  RESERVATION_MODIFIED,
  UPDATE_LOCAL_RESERVATION,
  FETCH_PAYMENT_METHODS,
  FETCH_PAYMENT_METHODS_SUCCESS,
  REFUND_RESERVATION,
  REFUND_RESERVATION_SUCCESS,
  REFUND_RESERVATION_FAILED,
  SET_EDITED_RESERVATION_DATA,
  START_EDIT_RESERVATION,
  CANCEL_EDIT_RESERVATION,
  FINISH_EDIT_RESERVATION,
  EDIT_DATA_IN_RESERVATION,
  WATCH_CANCELLED_RESERVATION,
  SET_CUSTOM_VEHICLE_WEIGHT,
  RESET_CUSTOM_VEHICLE_WEIGHT,
  START_PRICE_CALCULATION,
  CLEAR_RESERVATION_ERROR,
  CLEAR_EXPIRE_TIMERS,
  APPLY_PROMO_CODE,
  RESERVATION_FROM_RESERVE,
} from '../consts/reservation'

const manageURLFake = () => ({
  fake: {
    name: 'reservation.manageURL',
    success: UPDATE_LOCAL_RESERVATION,
  },
})

const createFake = () => ({
  fake: {
    name: 'reservation.create',
    success: RESERVATION_CREATED,
  },
})

const modifyFake = () => ({
  fake: {
    name: 'reservation.modify',
    success: RESERVATION_MODIFIED,
  },
})

const paymentMethodsFake = () => ({
  fake: {
    name: 'payment.methods',
    success: FETCH_PAYMENT_METHODS_SUCCESS,
  },
})

export const startFetchingReservation = createAction(START_FETCHING)
export const startPriceCalculation = createAction(START_PRICE_CALCULATION)
export const getReservation = createAction(GET_RESERVATION)
export const getReservationFailed = createAction(GET_RESERVATION_FAILED)
export const getReservationParts = createAction(GET_RESERVATION_PARTS)
export const createReservation = createAction(RESERVATION_CREATE, null, createFake)
export const modifyReservation = createAction(RESERVATION_MODIFY, null, modifyFake)
export const manageUrlReservation = createAction(MANAGE_URL_RESERVATION, null, manageURLFake)
export const extendReservation = createAction(EXTEND_RESERVATION)
export const clearReservation = createAction(CLEAR_RESERVATION)
export const setReservation = createAction(SET_RESERVATION)
export const setRefundReservation = createAction(SET_REFUND_RESERVATION)

export const refund = createAction(REFUND_RESERVATION)
export const refundFailed = createAction(REFUND_RESERVATION_FAILED)
export const refundSuccess = createAction(REFUND_RESERVATION_SUCCESS)

export const fetchPaymentMethods = createAction(FETCH_PAYMENT_METHODS, null, paymentMethodsFake)

export const setEditedReservationData = createAction(SET_EDITED_RESERVATION_DATA)

export const startEditReservation = createAction(START_EDIT_RESERVATION)
export const cancelEditReservation = createAction(CANCEL_EDIT_RESERVATION)
export const finishEditReservation = createAction(FINISH_EDIT_RESERVATION)
export const editDataInReservation = createAction(EDIT_DATA_IN_RESERVATION)

export const watchCancelledReservation = createAction(WATCH_CANCELLED_RESERVATION)

export const setCustomVehicleWeight = createAction(SET_CUSTOM_VEHICLE_WEIGHT)
export const resetCustomVehicleWeight = createAction(RESET_CUSTOM_VEHICLE_WEIGHT)
export const clearReservationError = createAction(CLEAR_RESERVATION_ERROR)
export const clearExpireTimers = createAction(CLEAR_EXPIRE_TIMERS)
export const applyPromoCodeToReservation = createAction(APPLY_PROMO_CODE)
export const setReservationFromReserve = createAction(RESERVATION_FROM_RESERVE)
