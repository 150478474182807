/* eslint-disable max-lines */
import { delay } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest, throttle } from 'redux-saga/effects'
import { compose, flatten, map, pathOr, prop, propEq, propOr, isEmpty, converge, filter, lt, T, length } from 'ramda'
import { change, clearFields, touch } from 'redux-form'

import { contactFields } from '../../modules/Booking/components/ContactFormFields'

import {
  confirmReservation as confirm,
  reservationModificationModifyService,
  reservationModificationCancelService,
  reservationModificationFinishService,
  reservationModificationStartService,
  extendReservation as extend,
  fetchPaymentMethods,
  getRefundInfo,
  getReservation as get,
  getReservationParts as getParts,
  newReservationCreator,
  refundReservation as refund,
  removeTripPackage as removeTrip,
  reservationUpdater,
  setCustomVehicleWeight,
  resetCustomVehicleWeight,
} from './api'
import { getPayedReservations } from './selectors'
import { changeLocale, removeTripPackage, selectCustomerRole } from '../user-selections/actions'
import { getCitizenshipList, getCompaniesSelector } from '../user/selectors'
import * as Actions from './consts/reservation'
import { backToPreviousPage, goToPage, showModal, userNeedToPayAction, clearModalData } from '../../actions'
import { setModalError, addError } from '../../modules/Ticket/EditTicket/editTicketSagas'
import { clearEditList, fetchAvailableReplacementLegs } from '../schedule/actions/schedule'
import { clearReservationError } from './actions/reservation'
import { fetchCitizenships } from '../user/actions'
import { isRingsuReservation } from '../../modules/Booking/containers/PaymentResults/ActionsForResults'
import { getModifiedReservationExpireAt } from '../../store/middlewares/expiration-check'
import { watchPromoCodeApply, watchPromoCodeRemove } from './promocode.sagas'
import * as Sentry from '@sentry/browser'
import { logBeacon } from '../../index'

export const getFirstSailRefId = pathOr(0, ['sailPackages', 0, 'sailRefs', 0, 'sailRefId'])
export const getFirstSailStatus = pathOr('', ['sailPackages', 0, 'sailRefs', 0, 'status'])

function* getPaymentMethods({ payload: reservationToken }) {
  try {
    const data = yield call(fetchPaymentMethods, reservationToken)
    yield put({
      type: Actions.FETCH_PAYMENT_METHODS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    yield put({
      type: Actions.FETCH_PAYMENT_METHODS_FAILED,
      payload: error,
    })
  }
}

export function* confirmReservation({ payload: { reservationId } }) {
  try {
    yield delay(200)
    const data = yield call(confirm, reservationId)

    yield put({
      type: Actions.RESERVATION_CONFIRM_SUCCESS,
      payload: data,
    })
  } catch (error) {
    yield put({
      type: Actions.RESERVATION_CONFIRM_FAILED,
      payload: error,
    })
  }
}

export function* getReservation({ payload: { reservationId } }) {
  try {
    const data = yield call(get, reservationId)

    yield put({
      type: Actions.GET_RESERVATION_SUCCESS,
      payload: data.reservation,
    })
  } catch (error) {
    yield put({
      type: Actions.GET_RESERVATION_FAILED,
      payload: error,
    })
  }
}

export function* getCurrentReservation() {
  const reservationToken = yield select(pathOr(0, ['reservation', 'current', 'token']))

  if (!reservationToken) return
  const data = yield call(get, reservationToken)

  yield put({
    type: Actions.GET_RESERVATION_ON_LOCALE,
    payload: data.reservation,
  })
}

export function* getReservationParts({ payload: { reservationToken } }) {
  try {
    const data = yield call(getParts, reservationToken)

    yield put({
      type: Actions.GET_RESERVATION_PARTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    yield put({
      type: Actions.GET_RESERVATION_PARTS_FAILED,
      payload: error,
    })
  }
}

export function* createReservation({ payload: { request, actualData, ...payload } }) {
  if (isEmpty(request.requests)) {
    // throw new Error('request cannot be empty')
    yield put(clearReservationError())
    return
  }

  const { response: newReservation, error } = yield call(newReservationCreator, request)

  if (newReservation) {
    yield put({
      type: Actions.RESERVATION_CREATED,
      payload: newReservation,
      newFormValues: actualData,
    })

    yield delay(500)
    yield put(touch('contact', ...map(prop('name'))(contactFields)))
    yield put(touch('textHotel', 'textHotel'))
  } else {
    yield put({
      type: Actions.RESERVATION_CREATE_FAILED,
      payload: { ...error, message: error.statusText, newFormValues: payload },
    })
  }
}

export function* deleteTripReservation({ payload: { trip, reservationId, ...payload } }) {
  try {
    const { seqN } = trip
    const data = yield call(removeTrip, { reservationId, seqN })
    yield put({
      type: Actions.RESERVATION_MODIFIED,
      payload: data,
      newFormValues: payload,
    })
  } catch (error) {
    yield put({
      type: Actions.RESERVATION_MODIFY_FAILED,
      payload: { ...error, message: error.statusText },
    })
  }
}

export function* modifyReservation({ payload }) {
  const { actualData, newReservation, request, ...newFormValues } = payload

  const confirming = yield select(propOr(false, 'confirming'))

  if (confirming) {
    console.warn('Looks like the reservation is currently in confirm process, cannot modify ATM')
    return
  }

  logBeacon(`Modify Request: ${JSON.stringify(request || '')}`)

  if (request && request.reservationId) {
    const item = request.item || request['purge-item'] || {}
    if (isEmpty(item) || isEmpty(propOr([], 'requests'))) {
      console.error('Empty request cannot be sent', payload)
      // put some recovery here ?
      // flush seats ?
      return
    }
  }

  const modal = yield select(pathOr('', ['modal']))
  if (modal === 'tickets') {
    if (newFormValues.count === 0) {
      yield put(clearFields('ticketsForm', false, false, newFormValues.code))
    } else {
      const keys = Object.keys(actualData)
      for (let i = 0; i < keys.length; i += 1) {
        yield put(change('ticketsForm', actualData[keys[i]].code, actualData[keys[i]]))
      }
    }
  } else {
    const reservation = newReservation ? newReservation : request
    const modifyingReservationOwner = reservation && reservation.reservationOwner

    if (!modifyingReservationOwner) {
      yield put({
        type: 'modifying',
        payload: true,
      })
    }

    const { response: updatedReservation, error } = yield call(reservationUpdater, reservation)

    if (!modifyingReservationOwner) {
      yield put({
        type: 'modifying',
        payload: false,
      })
    }

    if (updatedReservation) {
      yield put({
        type: Actions.RESERVATION_MODIFIED,
        payload: updatedReservation,
        newFormValues: actualData,
      })
    } else {
      const message = propOr('', 'statusText')(error)
      yield put({
        type: Actions.RESERVATION_MODIFY_FAILED,
        payload: { ...error, message, newFormValues },
      })
    }
  }
}

function* updateLocalReservation({ payload: { reservation, status } = {} }) {
  if (reservation) {
    const payedReservations = yield select(getPayedReservations)

    const payload = {
      confirmed: false,
      created: false,
      fetching: false,
      current: reservation,
    }

    if (status === 'COMPLETED' || reservation.status === 'OK') {
      payload.current = null
      payload.payedReservations = {
        ...payedReservations,
        [reservation.token]: reservation,
      }
    }

    yield put({
      type: Actions.UPDATE_RESERVATION_INFO,
      payload,
    })

    yield put({
      type: '[SW]/CLEAR_TIMEOUTS',
      meta: { WebWorker: true },
    })
  }
}

function* extendReservation({ payload: reservationId }) {
  try {
    const reservation = yield call(extend, reservationId)

    const { expireAt: expireAtFromRoot } = reservation
    const expireAt = getModifiedReservationExpireAt(expireAtFromRoot)(reservation)

    yield put({
      type: Actions.EXTEND_RESERVATION_EXPIRE,
      payload: expireAt,
    })
  } catch (error) {
    yield put({
      type: Actions.EXTEND_RESERVATION_FAILED,
      payload: error,
    })
  }
}

function* refundReservation({ payload: { reservationToken, sailRefIds }, ...payload }) {
  try {
    const data = yield call(refund, { reservationToken, sailRefIds })
    yield put({
      type: Actions.REFUND_RESERVATION_SUCCESS,
      payload: data,
      newFormValues: payload,
    })
  } catch (error) {
    // should be common warnings for this
    yield put({
      type: Actions.REFUND_RESERVATION_FAILED,
      payload: error,
    })
  }
}

const isRoundTrip = compose(lt(1), length)
const getSailRefs = compose(flatten, map(propOr([], 'sailRefs')))
const createSailRefFilter = (sailPackages) => (isRoundTrip(sailPackages) ? propEq('status', 'CANCELLED') : T)
const selectSailRefIds = compose(map(prop('sailRefId')), converge(filter, [createSailRefFilter, getSailRefs]))

function* extendRefundInfo({ payload: reservation }) {
  try {
    const { token, sailPackages = [], selectedSailRefIds = [] } = reservation

    const sailRefIds = isEmpty(selectedSailRefIds) ? selectSailRefIds(sailPackages) : selectedSailRefIds
    const refund = yield call(getRefundInfo, { reservationToken: token, sailRefIds })
    const extendSailPackagesWithStatuses = map(({ sailRefs, ...rest }) => ({
      ...rest,
      sailRefs: map((data) => ({ ...data, status: pathOr('', ['sails', data.sailRefId, 'status'])(refund) }))(sailRefs),
    }))

    yield put({
      type: Actions.SET_RESERVATION,
      payload: {
        type: 'refund',
        reservation: {
          ...reservation,
          selectedSailRefIds: sailRefIds,
          refund,
          sailPackages: extendSailPackagesWithStatuses(reservation.sailPackages),
        },
      },
    })
  } catch (error) {
    yield put({
      type: Actions.SET_REFUND_RESERVATION_FAILED,
      payload: error,
    })
  }
}

function* startEditReservation({ payload }) {
  const reservationToken = payload
  const citizenshipList = yield select(getCitizenshipList)

  try {
    yield put(clearEditList())
    const reservationUnderEdit = yield call(reservationModificationStartService, reservationToken)
    yield put({
      type: Actions.START_EDIT_RESERVATION_SUCCESS,
      payload: reservationUnderEdit,
    })

    if (isEmpty(citizenshipList) && isRingsuReservation(reservationUnderEdit)) {
      yield put(fetchCitizenships())
    }

    const firstSailRefId = getFirstSailRefId(reservationUnderEdit)
    if (getFirstSailStatus(reservationUnderEdit) === 'CANCELLED')
      yield put(fetchAvailableReplacementLegs(firstSailRefId))
  } catch (error) {
    console.error(error)
  }
}

function* cancelEditReservation({ payload }) {
  const reservationToken = payload
  // Sentry.captureEvent({ message: `cancelEdit ${reservationId}` })
  try {
    yield put(backToPreviousPage())
    const data = yield call(reservationModificationCancelService, reservationToken)
    yield put({
      type: Actions.CANCEL_EDIT_RESERVATION_SUCCESS,
      payload: data,
    })
  } catch (error) {
    console.error(error)
  }
}

function* finishEditReservation({ payload }) {
  const { reservationToken, differenceToPay } = payload
  // Sentry.captureEvent({ message: `finishEdit ${reservationId}`, extra: { differenceToPay } })
  try {
    const data = yield call(reservationModificationFinishService, reservationToken)
    yield put({
      type: Actions.FINISH_EDIT_RESERVATION_SUCCESS,
      payload: data,
    })

    if (differenceToPay === 0) yield put(backToPreviousPage())
    // here should be action with endpoint for refund from edit
    if (differenceToPay < 0) yield put(goToPage('/myticket-edit/refund'))
    if (differenceToPay > 0) {
      if (data.reservationOwner.companyId) {
        const customerCompanies = yield select(getCompaniesSelector)
        const customerPaymentCompany = customerCompanies.find(
          (company) => company.companyId === data.reservationOwner.companyId
        )
        yield put(selectCustomerRole(customerPaymentCompany))
      } else {
        yield put(selectCustomerRole({}))
      }
      yield put({
        type: Actions.FETCH_PAYMENT_METHODS,
        payload: reservationToken,
      })
      yield put(userNeedToPayAction(differenceToPay))
    }
    // yield put(goToPage('/refund'))
  } catch (error) {
    Sentry.captureException(error)
  }
}

function* editDataInReservationSaga({ payload: { reservationToken, dataToEdit } }) {
  try {
    const data = yield call(reservationModificationModifyService, reservationToken, dataToEdit)
    yield put({
      type: Actions.EDIT_DATA_IN_RESERVATION_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const { data: { code, id, message = '' } = {} } = error
    yield put(setModalError(code || message))
    if (id && message) {
      yield put(addError(error.data))
    }
  }
}

function* handleCustomVehicleWeight({ payload }) {
  const { seqNum, sailRefId } = payload
  const getFormerWeightFromModal = pathOr(0, ['modalData', 'formerWeight'])

  const currentResId = yield select(pathOr(0, ['reservation', 'current', 'reservationId']))
  // const editResId = yield select(pathOr(0, ['reservation', 'edit', 'reservationId']))
  // const reservationId = editResId || currentResId
  const reservationId = currentResId

  const { weight } = yield select(pathOr({}, ['form', 'custom-weight', 'values']))

  if (!reservationId || !seqNum || !sailRefId) {
    throw new Error('handleCustomVehicleWeight is missing parameters to run!')
  }

  try {
    const data = weight
      ? yield call(setCustomVehicleWeight, { reservationId, seqNum, sailRefId, weight })
      : yield call(resetCustomVehicleWeight, { reservationId, seqNum, sailRefId })
    yield put({
      type: Actions.RESERVATION_MODIFIED,
      payload: data,
      newFormValues: {},
    })
    yield put(clearFields('custom-weight', false, false, 'weight'))
    yield put(showModal(''))
    yield put(clearModalData())
  } catch (error) {
    yield put({
      type: Actions.RESERVATION_MODIFY_FAILED,
      payload: { ...error },
    })
    const formerWeight = yield select(getFormerWeightFromModal)
    yield put(change('custom-weight', 'weight', formerWeight))
  }
}

function* editDataInReservationSuccessSaga() {
  yield put(showModal(''))
}

export function* confirmation() {
  yield takeEvery(Actions.RESERVATION_CONFIRM, confirmReservation)
}

function* getting() {
  yield takeEvery(Actions.GET_RESERVATION, getReservation)
}

function* creation() {
  yield takeEvery(Actions.RESERVATION_CREATE, createReservation)
}

function* updateLocal() {
  yield takeEvery(Actions.UPDATE_LOCAL_RESERVATION, updateLocalReservation)
}

function* deletionTrip() {
  yield takeEvery(removeTripPackage, deleteTripReservation)
}

function* modification() {
  yield takeEvery(Actions.RESERVATION_MODIFY, modifyReservation)
}

function* gettingParts() {
  yield takeEvery(Actions.GET_RESERVATION_PARTS, getReservationParts)
}

function* extendHandler() {
  yield takeEvery(Actions.EXTEND_RESERVATION, extendReservation)
}

function* setRefundReservationWatcher() {
  yield throttle(1000, Actions.SET_REFUND_RESERVATION, extendRefundInfo)
}

function* watchFetchPaymentMethods() {
  yield takeEvery(Actions.FETCH_PAYMENT_METHODS, getPaymentMethods)
}

function* watchRefundReservation() {
  yield throttle(1000, Actions.REFUND_RESERVATION, refundReservation)
}

function* watchStartEditReservation() {
  yield takeEvery(Actions.START_EDIT_RESERVATION, startEditReservation)
}

function* watchCancelEditReservation() {
  yield takeEvery(Actions.CANCEL_EDIT_RESERVATION, cancelEditReservation)
}

function* watchFinishEditReservation() {
  yield takeEvery(Actions.FINISH_EDIT_RESERVATION, finishEditReservation)
}

function* watchEditDataInReservation() {
  yield takeEvery(Actions.EDIT_DATA_IN_RESERVATION, editDataInReservationSaga)
}

function* watchEditDataInReservationSuccessSaga() {
  yield takeEvery(Actions.EDIT_DATA_IN_RESERVATION_SUCCESS, editDataInReservationSuccessSaga)
}

function* watchLocaleChange() {
  yield takeLatest(changeLocale, getCurrentReservation)
}

function* watchCustomVehicleWeight() {
  yield takeEvery([Actions.RESET_CUSTOM_VEHICLE_WEIGHT, Actions.SET_CUSTOM_VEHICLE_WEIGHT], handleCustomVehicleWeight)
}

export function* reservationSaga() {
  yield [
    gettingParts(),
    updateLocal(),
    confirmation(),
    getting(),
    creation(),
    modification(),
    deletionTrip(),
    extendHandler(),
    watchFetchPaymentMethods(),
    setRefundReservationWatcher(),
    watchRefundReservation(),
    watchStartEditReservation(),
    watchCancelEditReservation(),
    watchFinishEditReservation(),
    watchEditDataInReservation(),
    watchEditDataInReservationSuccessSaga(),
    watchLocaleChange(),
    watchCustomVehicleWeight(),
    watchPromoCodeApply(),
    watchPromoCodeRemove(),
  ]
}
