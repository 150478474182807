import { createSelector } from 'reselect'
import {
  anyPass,
  compose,
  defaultTo,
  equals,
  filter,
  find,
  head,
  identity,
  isEmpty,
  isNil,
  map,
  mapObjIndexed,
  not,
  omit,
  pathEq,
  pathOr,
  prop,
  propEq,
  propOr,
  reduce,
  reject,
  sortBy,
  type,
  values,
} from 'ramda'
import { getFormValues } from 'redux-form'

import messages from '../../../consts/messages'
import { attributeCodePath, getAttributeStructureValue } from '../../routes/routes/reducers'

const isObject = compose(equals('Object'), type)
const valuesOfTypeObjectOnly = compose(filter(isObject), values)

export const getPrices = pathOr({}, ['prices'])

const getPricesByTitle = (title) => compose(defaultTo({}), prop(title), head, values)

const isOfCode = propEq('code')
const isOfSubType = propEq('subType')
const isOfType = propEq('type')
const isOfInventoryClass = propEq('inventoryClass')

const getSubTypesAnyPassPredicate = compose(anyPass, map(isOfSubType))
const getTypesAnyPassPredicate = compose(anyPass, map(isOfType))
const getInventoryClassesAnyPassPredicate = compose(anyPass, map(isOfInventoryClass))
const getCodeAnyPassPredicate = compose(anyPass, map(isOfCode))

const filterByInventoryClass = compose(filter, getInventoryClassesAnyPassPredicate)
const filterByCode = compose(filter, getCodeAnyPassPredicate)
const rejectByInventoryClass = compose(reject, getInventoryClassesAnyPassPredicate)
const filterBySubTypes = compose(filter, getSubTypesAnyPassPredicate)
const filterByTypes = compose(filter, getTypesAnyPassPredicate)
const rejectBySubTypes = compose(reject, getSubTypesAnyPassPredicate)

const getOnlyPassengersTypes = compose(filterByTypes(['PASSENGER']))
const getOnlyDecks = compose(filterBySubTypes(['DECK', 'CABIN']))
const getOnlyLocalTravellers = compose(filterByCode(['LOCAL_TRAVELLER']))
const getOnlyBicycles = compose(filterBySubTypes(['BICYCLE']), filterByInventoryClass(['BICYCLE']))
// const getOnlyBicycles = filterByInventoryClass(['BICYCLE'])

const rejectTrailersAndBicycles = compose(
  rejectBySubTypes(['BICYCLE', 'TRAILER']),
  rejectByInventoryClass(['BICYCLE', 'TRAILER'])
)

const priceCategorySort = sortBy(prop('title'))

export const localTravellerPriceCategory = {
  code: 'LOCAL_TRAVELLER',
  translationId: messages.localTravellerCategory.id,
}
const withoutLocalTraveller = reject(propEq('code', 'LOCAL_TRAVELLER'))
const sortedAndWithoutLocals = withoutLocalTraveller
export const getSelectedTickets = compose(defaultTo({}), getFormValues('ticketsForm'))

export const getLocalTravellers = compose(defaultTo({}), getFormValues('localTravellerIDForm'))

const onlyBicycles = filter(isOfSubType('BICYCLE'))
const onlyTrailers = filter(isOfSubType('TRAILER'))

const onlyBikesAvailability = filter(isOfInventoryClass('BICYCLE'))
const onlyPassengersAvailability = filter(isOfSubType('PASSENGER'))

const defaultTo0 = defaultTo(0)
const defaultToEuro = defaultTo('EUR')
const notEmptyOrNull = compose(not, anyPass([isNil, isEmpty]))

const getFirstSail = compose(defaultTo({}), head, values, getPrices)
export const getPassengerPrices = createSelector(getFirstSail, prop('prices'))
export const getResidentPrices = createSelector(getFirstSail, prop('residentPrices'))
export const getIsRequestingCalcPrices = createSelector(getPrices, prop('requesting'))

const getTitle = ({ priceCategory: { title } }) => title

export const getResidentPriceCategoryTitles = createSelector(
  getFirstSail,
  compose(mapObjIndexed(getTitle), prop('residentPrices'))
)

export const getDescriptions = (code, locale) => (prices) => {
  const descProps = locale === 'et' ? 'DESCRIPTION_ET' : 'DESCRIPTION_EN'
  return compose(
    getAttributeStructureValue,
    find(pathEq(attributeCodePath, descProps)),
    pathOr([], [code, 'priceCategory', 'attributes'])
  )(prices)
}

export const getDescriptionAttributeValueByCodeAndLocale = (code, locale) =>
  createSelector(getFirstSail, compose(getDescriptions(code, locale), prop('residentPrices')))

export const getVehiclePrices = createSelector(getFirstSail, prop('carDeckPrices'))
export const getAddonPrices = createSelector(getFirstSail, prop('addonPrices'))
export const getRouteAddons = createSelector(getFirstSail, prop('routeAddons'))

const onlyWeightEditAllowed = ({ priceCategory: { weightEditAllowed = false } }) => weightEditAllowed
const onlyTitle = ({ priceCategory: { title = '' } }) => title

export const vehiclesWeightEditAllowed = createSelector(
  getFirstSail,
  compose(mapObjIndexed(onlyWeightEditAllowed), ({ carDeckResidentPrices = {}, carDeckPrices = {} }) => ({
    ...carDeckResidentPrices,
    ...carDeckPrices,
  }))
)

export const vehicleTranslations = createSelector(
  getFirstSail,
  compose(mapObjIndexed(onlyTitle), ({ carDeckResidentPrices = {}, carDeckPrices = {} }) => ({
    ...carDeckResidentPrices,
    ...carDeckPrices,
  }))
)

const omitWaste = omit(['createdAt', 'isResident', 'type', 'vehicleRegistrationInfoRequired'])

const categoryTransform = ({ calculatedPrice, currency, priceCategory }) => ({
  ...omitWaste(priceCategory),
  price: defaultTo0(calculatedPrice),
  currency: defaultToEuro(currency),
})

const routeAddonsTransform = ({ mandatoryParameter, priceCategory, maxQuantityInReservation }) => ({
  mandatoryParameter,
  priceCategory,
  maxQuantityInReservation,
})

const priceCategorySelector = (firstSelector, modifier = identity) =>
  createSelector(firstSelector, compose(modifier, map(categoryTransform), values))
const routeAddonsSelector = (firstSelector, modifier = identity) =>
  createSelector(firstSelector, compose(modifier, map(routeAddonsTransform), values))

export const getCategoriesListWithPrices = priceCategorySelector(getPassengerPrices)
export const getResidentCategoriesListWithPrices = priceCategorySelector(getResidentPrices)
export const getVehicleCategoriesListWithPrices = priceCategorySelector(getVehiclePrices, rejectTrailersAndBicycles)
export const getSortedVehicleCategoriesListWithPrices = createSelector(
  getVehicleCategoriesListWithPrices,
  priceCategorySort
)
export const getBicycleCategoriesListWithPrices = priceCategorySelector(getVehiclePrices, onlyBicycles)
export const getAddonBicycleCategoriesListWithPrices = priceCategorySelector(getAddonPrices, onlyBicycles)
export const getAddonCategoriesListWithPrices = priceCategorySelector(getAddonPrices)
export const getRouteAddonsParameters = routeAddonsSelector(getRouteAddons)
export const getTrailerCategoriesListWithPrices = priceCategorySelector(getVehiclePrices, onlyTrailers)

export const areVehiclesActive = createSelector(getSelectedTickets, prop('vehicleIsActive'))
export const areTrailersActive = createSelector(getSelectedTickets, prop('trailerIsActive'))
export const getSelectedTicketsValues = createSelector(getSelectedTickets, identity)

export const areVehiclesAvailable = createSelector(getVehicleCategoriesListWithPrices, notEmptyOrNull)
export const areBicyclesAvailable = createSelector(getBicycleCategoriesListWithPrices, notEmptyOrNull)
export const areAddonBicyclesAvailable = createSelector(getAddonBicycleCategoriesListWithPrices, notEmptyOrNull)
export const areAddonsAvailable = createSelector(getAddonCategoriesListWithPrices, notEmptyOrNull)
export const areTrailersAvailable = createSelector(getTrailerCategoriesListWithPrices, notEmptyOrNull)

const passengersCategories = createSelector(getCategoriesListWithPrices, (categories) => {
  if (window.brandProps.theme === 'kihnu' || window.brandProps.theme === 'saaremaa') {
    return window.brandProps.backendConfig && window.brandProps.backendConfig.displayResidentTickets
      ? [...categories]
      : [localTravellerPriceCategory, ...sortedAndWithoutLocals(categories)]
  }
  return sortedAndWithoutLocals(categories)
})
const residentCategories = createSelector(getResidentCategoriesListWithPrices, (categories) => {
  return [...categories]
})

const bicycleCategories = createSelector(getBicycleCategoriesListWithPrices, sortedAndWithoutLocals)
const bicycleAddonCategories = createSelector(getAddonBicycleCategoriesListWithPrices, sortedAndWithoutLocals)

export const selectedTicketsList = createSelector(getSelectedTickets, valuesOfTypeObjectOnly)

const availableForSelectionFromCategories = (categories, selectedTickets) => {
  const selectedTicketsHas = (code) => find(propEq('code', code), selectedTickets)
  const rejectSelectedTickets = reject(compose(selectedTicketsHas, prop('code')))

  return rejectSelectedTickets(categories)
}

const iconTypeFromProps = (state, props) => prop('icon', props)
const isAddonFromProps = (state, props) => prop('isAddon', props)
const availableInvClassesFromProps = (state, props) => prop('availableInvClasses', props)
export const getNeededCategoriesByIconType = (tickets, icon, prices, availableInvClasses, isAddon) => {
  const addMaxValueToIC = (acc, { inventoryClass, amount = Infinity }) => {
    const invClass = availableInvClasses.find((invClass) => invClass.inventoryClass === inventoryClass)
    acc[inventoryClass] = amount
    acc[`${inventoryClass}_reserves`] = pathOr(0, ['reserves', 0, 'amount'])(invClass)
    return acc
  }

  const mapTicketsWithCategories = (categories, isIconBike = false) =>
    map((ticket) => {
      const category = find(propEq('code', ticket.code))(categories)
      const { title } = ticket
      if (!category && isIconBike) {
        return []
      }
      return {
        ...ticket,
        title: propOr(title, 'title')(category),
      }
    })

  const mapSelectedTicketsWithLocalTitles = (residentPrices) =>
    map((selectedTickets) => {
      const title = pathOr(selectedTickets.title, ['priceCategory', 'title'])(
        prop(selectedTickets.code, residentPrices)
      )
      return {
        ...selectedTickets,
        title,
      }
    })

  switch (icon) {
    case 'iconTicket': {
      return {
        maxValues: {},
        categoriesAvailableForSelection: [
          {
            subType: 'ticket',
            title: 'Rent sunchair/umbrella set',
          },
        ],
        selectedTicketsList: [],
      }
    }
    case 'iconLunch': {
      return {
        maxValues: {},
        categoriesAvailableForSelection: [
          {
            subType: 'lunch',
            title: 'Lunch',
          },
        ],
        selectedTicketsList: [],
      }
    }
    case 'iconShip': {
      return {
        maxValues: {},
        categoriesAvailableForSelection: [
          {
            subType: 'ship',
            title: 'Cruise to southern Point of Europe',
          },
        ],
        selectedTicketsList: [],
      }
    }
    case 'iconCar': {
      return {
        maxValues: {},
        categoriesAvailableForSelection: [
          {
            subType: 'car',
            title: 'Rent a car',
          },
        ],
        selectedTicketsList: [],
      }
    }
    case 'iconBus': {
      return {
        maxValues: {},
        categoriesAvailableForSelection: [
          {
            subType: 'bus',
            title: 'Bus Transfer to Sarakiniko Beach',
          },
        ],
        selectedTicketsList: [],
      }
    }
    case 'iconBike': {
      const categories = isAddon ? bicycleAddonCategories({ prices }) : bicycleCategories({ prices })
      const selectedTickets = mapTicketsWithCategories(categories, true)(getOnlyBicycles(tickets)).flat()
      const categoriesAvailableForSelection = availableForSelectionFromCategories(
        categories,
        selectedTickets,
        availableInvClasses
      )
      const availabilityInvClasses = onlyBikesAvailability(availableInvClasses)
      const maxValues = reduce(addMaxValueToIC, {}, availabilityInvClasses)

      return {
        maxValues,
        categoriesAvailableForSelection,
        selectedTicketsList: selectedTickets,
      }
    }
    case 'iconPassenger': {
      let categories = passengersCategories({ prices })
      if (pathOr(false, ['backendConfig', 'displayResidentTickets'], window.brandProps)) {
        categories = [...residentCategories({ prices }), ...passengersCategories({ prices })].sort((a, b) =>
          a.seqN > b.seqN ? 1 : b.seqN > a.seqN ? -1 : 0
        )
      }
      const residentPrices = getPricesByTitle('residentPrices')(prices)
      const categoriesEdited = categories.length === 1 && categories[0].code === 'LOCAL_TRAVELLER' ? [] : categories
      // categories always contain local travellers, even if it's not chooosen.
      // this action was made to prevent appearing of local travellers when nothing is choosen
      const deckAndLocalTravellerTickets = [
        ...getOnlyDecks(tickets),
        ...getOnlyLocalTravellers(tickets),
        ...getOnlyPassengersTypes(tickets),
      ]
      const selectedTickets = mapTicketsWithCategories(categories)(deckAndLocalTravellerTickets)
      const selectedTicketsWithLocalTitles = mapSelectedTicketsWithLocalTitles(residentPrices)(selectedTickets)
      const categoriesAvailableForSelection = availableForSelectionFromCategories(
        categoriesEdited,
        selectedTicketsWithLocalTitles
      )
      const availabilityInvClasses = onlyPassengersAvailability(availableInvClasses)
      const ticketsCount = selectedTicketsWithLocalTitles.reduce((acc, ticket) => acc + ticket.count, 0)
      const maxValues = reduce(addMaxValueToIC, {}, availabilityInvClasses)

      return {
        maxValues,
        categoriesAvailableForSelection,
        selectedTicketsList: selectedTicketsWithLocalTitles,
        ticketsCount,
      }
    }
    default: {
      return {
        maxValues: [],
        selectedTicketsList: [],
        categoriesAvailableForSelection: [],
      }
    }
  }
}

export const ticketsIncDecSelectorParameters = () =>
  createSelector(
    [selectedTicketsList, iconTypeFromProps, getPrices, availableInvClassesFromProps, isAddonFromProps],
    getNeededCategoriesByIconType
  )
