import { createAction } from 'redux-actions'
import {
  CHANGE_INVENTORY_DATE,
  CHANGE_INVENTORY_LEG,
  FETCH_INVENTORY_FOR_EDIT_RESERVATION,
  FETCH_ROUTE_LEG_INVENTORY,
} from '../consts'

export const changeDate = createAction(CHANGE_INVENTORY_DATE, null)
export const changeLeg = createAction(CHANGE_INVENTORY_LEG, null)
export const fetchInventoryAction = createAction(FETCH_ROUTE_LEG_INVENTORY, null)
export const fetchInventoryForEditReservationAction = createAction(FETCH_INVENTORY_FOR_EDIT_RESERVATION)
