import { pathOr } from 'ramda'
import * as AppActions from '../consts/actions'

const initialState = ''

const userNeedToPayAfterEdit = (state = initialState, action) => {
  switch (action.type) {
    case AppActions.USER_NEED_TO_PAY: {
      return pathOr(state, ['payload'], action)
    }
    default:
      return state
  }
}

export default userNeedToPayAfterEdit
