import { actionTypes, getFormValues, touch } from 'redux-form'
import { takeEvery, put, select } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { map, prop, keys, path } from 'ramda'
import { debounce } from '../../utils/saga-debounce'

import { setReservationCustomerInfo } from '../../actions'
import { contactFields } from '../../modules/Booking/components/ContactFormFields'
import { getTempPassengerData, saveTempPassengerData } from '../../services/user-selections'

const CONTACT_FORM_NAME = 'contact'
const BOOKING_FORM = 'bookingForm'

function* blur(action) {
  const { meta } = action

  if (meta.form) {
    if (meta.form === 'bookingForm') {
      // const index = Number(meta.field.match(/\d+/g)[0])
      // yield put(setReservationPassengerInfo({ index }))
      yield null
    }
  }
}

function* changeOrBlur({ meta }) {
  const modal = yield select(prop('modal'))
  if (modal === 'customerForm') return
  if (meta.form && meta.form === CONTACT_FORM_NAME) {
    yield put(setReservationCustomerInfo())
  }
}

function* changeOnly(action) {
  const { meta } = action
  if (meta.form && meta.form === BOOKING_FORM) {
    if (meta.field && meta.field.startsWith('passengers[0]')) {
      const tempPassengers = yield select(getTempPassengerData)
      const formValues = yield select(getFormValues(BOOKING_FORM))
      const passenger = path(['passengers', 0])(formValues)

      if (!keys(tempPassengers).includes(`${passenger.seqN}`)) {
        const { seqN, firstName = '', lastName = '' } = passenger
        if (firstName && lastName) yield put(saveTempPassengerData({ seqNum: seqN, passenger }))
      }
    }
  }
}

function* focus(action) {
  const { meta } = action

  if (meta.form && meta.form === CONTACT_FORM_NAME) {
    yield delay(500)
    yield put(touch(CONTACT_FORM_NAME, ...map(prop('name'))(contactFields)))
  }
}

export function* onBlur() {
  yield takeEvery(actionTypes.BLUR, blur)
}

export function* onFocus() {
  yield takeEvery(actionTypes.FOCUS, focus)
}

export function* onChangeOnBlur() {
  yield debounce(1000, [actionTypes.CHANGE, actionTypes.BLUR], changeOrBlur)
}

export function* onChange() {
  yield debounce(500, actionTypes.CHANGE, changeOnly)
}

export default function* saga() {
  yield [onChangeOnBlur(), onBlur(), onFocus(), onChange()]
}
