import { createAction } from 'redux-actions'
import {
  RESERVATION_CONFIRM,
  RESERVATION_CONFIRM_SUCCESS,
  RESERVATION_WITHOUT_PASSENGERS_WARNING,
} from '../consts/reservation'

const confirmFake = () => ({
  fake: {
    name: 'reservation.confirm',
    success: RESERVATION_CONFIRM_SUCCESS,
  },
})

export const warnNoPassengersInReservation = createAction(RESERVATION_WITHOUT_PASSENGERS_WARNING)
export const confirm = createAction(RESERVATION_CONFIRM, null, confirmFake)
