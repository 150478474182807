import axios from 'axios'
import { map, curry } from 'ramda'
import { projectCustomFields } from '../utils'

const getFields = curry(projectCustomFields)(null)

export function getTicketTermsForRoute(routeCode, locale) {
  return axios({
    method: 'GET',
    url: 'api/sales-static/ticket_terms',
    params: {
      slug: routeCode,
      locale,
    },
  }).then(map(getFields))
}
