import { handleActions } from 'redux-actions'
import * as Actions from '../consts'

import messages from '../../../consts/messages'
import { translate } from '../../../modules/Common/Translator'

const initialCredentials = {
  message: '',
  error: '',
  isFetching: false,
}

const resetCredentials = (state) => ({ ...state, ...initialCredentials })

const setFetching = (state) => ({ ...state, isFetching: true, error: '' })

const setSuccessMessage = (state) => ({
  ...state,
  isFetching: false,
  error: '',
  message: translate(messages.profileChangePasswordSuccessText),
})

const setErrorMessage = (state, { payload = {} }) => ({
  ...state,
  isFetching: false,
  message: '',
  error: payload.message,
})

const credentials = handleActions(
  {
    [Actions.CHANGE_USER_PASSWORD]: setFetching,
    [Actions.CHANGE_USER_PASSWORD_SUCCESS]: setSuccessMessage,
    [Actions.CHANGE_USER_PASSWORD_FAIL]: setErrorMessage,
    [Actions.RESET_CREDENTIALS_MESSAGES]: resetCredentials,
  },
  initialCredentials
)

export default credentials
