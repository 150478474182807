import { call, put, takeEvery, select } from 'redux-saga/effects'
import { reduce, assoc, omit, propOr, pathOr, update } from 'ramda'

import * as userActions from './consts'

import {
  resetCredentialsMessages,
  changeUserFilters,
  fetchCountries,
  fetchCitizenships,
  getLocalTravellerInfo,
  inviteUserToCompanySuccess,
  manageInvitationToCompanySuccess,
} from './actions'

import {
  fetchUserDetails,
  fetchAllReservationsByUserId,
  updateUserDetails,
  upsertUserCompany,
  changeUserPassword,
  deleteUserCompany,
} from './api/user'

import { fetchPriceCategoryForPersonalId, checkPersonalIdOnSailAndReservation } from '../reservation/api/reservation'

import {
  fetchCompanyMembers,
  inviteUserToCompany,
  resendInvitationUserToCompany,
  cancelInvitationUserToCompany,
  fetchCountriesForCompany,
  fetchCitizenshipsForCompany,
} from './api/company'

import {
  userDetailsSelector,
  getSelectedCompanyUsers,
  getManagersSelectedCompany,
  getUserIdSelector,
  getCustomerIdSelector,
} from './selectors'
import { change } from 'redux-form'
import { changeItemQtty } from '../user-selections'
import pricing from '../pricing'
import { getEditReservation, getIsEditProcessStarted } from '../reservation/selectors'
import { setModalError } from '../../modules/Ticket/EditTicket/editTicketSagas'
import { warningSign } from '../../consts/stuff'
import { getSailDatesForSailRefIds } from '../schedule/reducers'

const USER_MESSAGES_DISPLAY_TIME = 5000
const delay = (ms) => new Promise((res) => setTimeout(res, ms))

function* clearUserMessages() {
  yield call(delay, USER_MESSAGES_DISPLAY_TIME)
  yield put(resetCredentialsMessages())
}

const getErrorCode = pathOr('', ['data', 'code'])

function* fetchUserDetailsSaga() {
  try {
    const data = yield call(fetchUserDetails)
    yield put({
      type: userActions.FETCH_USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const { data = {} } = error
    yield put({
      type: userActions.FETCH_USER_DETAILS_FAIL,
      payload: { ...error, message: data.message || error.message },
    })
  }
}

function* updateUserDetailsSaga({ payload }) {
  try {
    const data = yield call(updateUserDetails, payload)
    yield put({
      type: userActions.UPDATE_USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const { data = {} } = error
    yield put({
      type: userActions.UPDATE_USER_DETAILS_FAIL,
      payload: { ...error, message: data.message || error.message },
    })
  }
}

function* fetchAllUserReservationsSaga({ payload = { filter: '' } }) {
  let fromDepartureAt = null
  let toDepartureAt = null

  if (payload.filter === 'dates') {
    try {
      const [from, to] = payload.value

      if (from) {
        fromDepartureAt = from.toISOString()
      }
      if (to && to.format) {
        toDepartureAt = to.toISOString()
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  try {
    const customerId = yield select(getCustomerIdSelector)
    const data = yield call(fetchAllReservationsByUserId, {
      customerId,
      from: fromDepartureAt,
      to: toDepartureAt,
    })
    yield put({
      type: userActions.FETCH_ALL_USER_RESERVATIONS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const { data = {} } = error
    yield put({
      type: userActions.FETCH_ALL_USER_RESERVATIONS_FAIL,
      payload: { ...error, message: data.message || error.message },
    })
  }
}

function* upsertUserCompanySaga({ payload }) {
  try {
    const data = yield call(upsertUserCompany, { ...payload, type: 'REGULAR' })
    yield put({
      type: userActions.UPSERT_USER_COMPANY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const { data = {} } = error
    yield put({
      type: userActions.UPSERT_USER_COMPANY_FAIL,
      payload: { ...error, message: data.message || error.message },
    })
  }
}

function* deleteUserCompanySaga({ payload }) {
  try {
    const myUserid = yield select(getUserIdSelector)
    yield call(deleteUserCompany, { ...payload, userId: myUserid })
    yield put({
      type: userActions.DELETE_USER_COMPANY_SUCCESS,
      payload: payload.companyId,
    })
  } catch (error) {
    const { data = {} } = error
    yield put({
      type: userActions.DELETE_USER_COMPANY_FAIL,
      payload: { ...error, message: data.message || error.message },
    })
  }
}

function* changeUserPasswordSaga({ payload }) {
  try {
    const data = yield call(changeUserPassword, payload)
    yield put({
      type: userActions.CHANGE_USER_PASSWORD_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const { data = {} } = error
    yield put({
      type: userActions.CHANGE_USER_PASSWORD_FAIL,
      payload: { ...error, message: data.message || error.message },
    })
  }
}

function* inviteUser({ payload: data }) {
  try {
    const selectedCompany = yield select(getManagersSelectedCompany)
    const { companyId } = selectedCompany

    yield call(inviteUserToCompany, { companyId, data })
    yield put(inviteUserToCompanySuccess())
  } catch (error) {
    console.error(error)
  }
}

function* manageInvitation({
  payload: {
    user: { token },
    type,
  },
}) {
  try {
    const selectedCompany = yield select(getManagersSelectedCompany)
    const { companyId } = selectedCompany

    if (type === 'resend') {
      yield call(resendInvitationUserToCompany, { companyId, token })
    } else if (type === 'cancel') {
      yield call(cancelInvitationUserToCompany, { companyId, token })

      const companyMembers = yield select(getSelectedCompanyUsers)

      yield put({
        type: userActions.UPDATE_COMPANY_MEMBERS,
        payload: {
          companyId,
          invitations: omit([token], companyMembers.invitations),
        },
      })
    }

    yield put(manageInvitationToCompanySuccess())
  } catch (error) {
    const { data = {} } = error
    yield put({
      type: userActions.MANAGE_INVITATION_TO_COMPANY_FAIL,
      payload: { ...error, message: data.message || error.message },
    })
  }
}

function* selectCompanyAndFetchMembers({ payload: companyId }) {
  try {
    yield put(changeUserFilters({ type: 'usersCompany', filter: 'companyId', value: companyId }))

    yield put({ type: userActions.FETCH_COMPANY_MEMBERS })

    const response = yield call(fetchCompanyMembers, companyId)
    let { members = [], invitations = [] } = response ? response.company : {}

    const userDetails = yield select(userDetailsSelector)
    const { customerId } = userDetails

    const invitationById = (acc, invitation) => assoc(invitation.token, { ...invitation, status: 'invited' }, acc)
    const membersById = (acc, member) =>
      assoc(member.customer.customerId, { ...member, isYou: member.customer.customerId === customerId }, acc)

    members = reduce(membersById, {}, members)
    invitations = reduce(invitationById, {}, invitations)

    yield put({
      type: userActions.FETCH_COMPANY_MEMBERS_SUCCESS,
      payload: { [companyId]: { members, invitations } },
    })
  } catch (error) {
    console.error(error)
  }
}

function* fetchCountriesSaga() {
  try {
    const data = yield call(fetchCountriesForCompany)
    yield put({
      type: userActions.FETCH_COUNTRIES_SUCCESS,
      payload: propOr([], 'items')(data),
    })
  } catch (error) {
    console.error(error)
  }
}

function* fetchCitizenshipsSaga() {
  try {
    const data = yield call(fetchCitizenshipsForCompany)
    yield put({
      type: userActions.FETCH_CITIZENSHIPS_SUCCESS,
      payload: data || [],
    })
  } catch (error) {
    console.error(error)
  }
}

function* localIDFieldErrorHandler(localTravellerFormValues, localID, errorCode) {
  const index = localTravellerFormValues.localIDs.findIndex(
    (item) => item.localID === localID && item.status === 'not-checked'
  )
  if (index !== 1) {
    const setFieldContentToNotFound = change.bind(
      null,
      'localTravellerIDForm',
      'localIDs',
      update(
        index,
        {
          localID: `${warningSign} ${localTravellerFormValues.localIDs[index].localID}`,
          status: 'not-exist',
        },
        localTravellerFormValues.localIDs
      )
    )
    yield put(setFieldContentToNotFound())
  }
  yield put(setModalError(errorCode))
}

const selectSailRef = (isTicketsModal) => (state) => {
  if (isTicketsModal) {
    const { selectedSailPackageIndexToEdit: sailIndex } = state
    const editReservation = getEditReservation(state)
    return pathOr({}, ['sailPackages', sailIndex, 'sailRefs', [0]], editReservation)
  }
  const sailRefFromEditableTrip = pathOr({}, ['userSelections', 'editableTrip', 'sailRefs', [0]], state)
  if (!sailRefFromEditableTrip.route) {
    const { code, route } = pathOr({}, ['userSelections', 'sailPackages', [0]], state)
    const sailRefId = pathOr(0, ['schedule', 'selectedSails', code], state)
    return { route, sailRefId }
  }
  return sailRefFromEditableTrip
}

function* getLocalTravellerInfoSaga({ payload }) {
  try {
    const editProcessStarted = yield select(getIsEditProcessStarted)
    const modal = yield select(pathOr('', ['modal']))
    const isTicketsModal = modal === 'tickets'

    const { route, sailRefId } = yield select(selectSailRef(isTicketsModal))
    const dates = yield select(getSailDatesForSailRefIds)
    const departureDate = dates[sailRefId] || ''

    if (!departureDate) console.error('The departureDate is empty, ouch')

    let priceCategoryForPersonalId
    let backupRegistry = false
    if (editProcessStarted) {
      const reservationUnderEdit = yield select(getEditReservation)
      const { reservationId } = reservationUnderEdit
      const { priceCategory, backupRegistryUsed = false } = yield call(checkPersonalIdOnSailAndReservation, {
        reservationId,
        sailRefId,
        personalId: payload,
      })

      backupRegistry = backupRegistryUsed
      priceCategoryForPersonalId = priceCategory
    } else {
      const { priceCategory, backupRegistryUsed = false } = yield call(
        fetchPriceCategoryForPersonalId,
        route,
        payload,
        departureDate
      )

      backupRegistry = backupRegistryUsed
      priceCategoryForPersonalId = priceCategory
    }
    if (!priceCategoryForPersonalId) {
      yield put(setModalError('NO_DISCOUNT_ON_ROUTE'))
      return
    }

    const prices = yield select(pricing.selectors.getPrices)
    const priceIndex = Object.values(prices[sailRefId].residentPrices).findIndex(
      (price) => price.priceCategory.code === priceCategoryForPersonalId
    )

    const localTravellerFormValues = yield select((state) => state.form.localTravellerIDForm.values)

    if (priceIndex === -1) {
      yield call(localIDFieldErrorHandler, localTravellerFormValues, payload, 'NO_DISCOUNT_ON_ROUTE')
      return
    }
    yield put({
      type: userActions.GET_LOCAL_TRAVELLER_INFO_SUCCESS,
      payload: {
        personalIdentificationNumber: payload,
        level: priceCategoryForPersonalId,
        backupRegistry,
      },
    })

    if (isTicketsModal) {
      if (localTravellerFormValues.localIDs) {
        const index = localTravellerFormValues.localIDs.findIndex((item) => item.localID === payload)
        if (index !== -1) {
          const newData = [...localTravellerFormValues.localIDs]
          if (localTravellerFormValues.localIDs[index].status === 'not-checked') {
            newData[index].status = 'exist'
          } else {
            yield call(localIDFieldErrorHandler, localTravellerFormValues, payload, 'DISCOUNT_ALREADY_USED_ON_SAIL')
            return
          }
          yield put(change('localTravellerIDForm', 'localIDs', newData))
        } else {
          yield put(setModalError('DISCOUNT_ALREADY_USED_ON_SAIL'))
        }
      }
      const ticketsFormValues = yield select((state) => state.form.ticketsForm.values)
      const ticketKey = Object.keys(ticketsFormValues).find(
        (key) => ticketsFormValues[key].priceCategory === priceCategoryForPersonalId
      )
      if (ticketKey) {
        const { localIDs = [], count = 0 } = ticketsFormValues[ticketKey]
        yield put(
          changeItemQtty({
            ...ticketsFormValues[ticketKey],
            localID: payload,
            localIDs: [...localIDs, payload],
            count: count + 1,
            type: 'confirm',
          })
        )
      } else {
        const categoryPriceData = prices[sailRefId].residentPrices[priceCategoryForPersonalId].priceCategory

        yield put(
          changeItemQtty({
            ...categoryPriceData,
            count: 1,
            localID: payload,
            localIDs: [payload],
            price: prices[sailRefId].residentPrices[priceCategoryForPersonalId].calculatedPrice,
            type: 'confirm',
            currency: prices[sailRefId].residentPrices[priceCategoryForPersonalId].currency,
            priceCategory: categoryPriceData.code,
            resident: categoryPriceData.isResident,
          })
        )
      }
    }
  } catch (error) {
    const errorCode = getErrorCode(error)
    if (errorCode.includes('DISCOUNT')) {
      yield put(setModalError(errorCode))
    } else {
      yield put(setModalError('bookingProcessError'))
    }
  }
}

function* watchFetchUserDetails() {
  yield takeEvery(userActions.FETCH_USER_DETAILS, fetchUserDetailsSaga)
}

function* watchUpdateUserDetails() {
  yield takeEvery(userActions.UPDATE_USER_DETAILS, updateUserDetailsSaga)
}

function* watchFetchAllUserReservations() {
  yield takeEvery(userActions.FETCH_ALL_USER_RESERVATIONS, fetchAllUserReservationsSaga)
}

function* watchUpsertUserCompany() {
  yield takeEvery(userActions.UPSERT_USER_COMPANY, upsertUserCompanySaga)
}

function* watchDeleteUserCompany() {
  yield takeEvery(userActions.DELETE_USER_COMPANY, deleteUserCompanySaga)
}

function* watchChangeUserPassword() {
  yield takeEvery(userActions.CHANGE_USER_PASSWORD, changeUserPasswordSaga)
}

function* watchSelectUsersCompany() {
  yield takeEvery(userActions.SELECT_USERS_COMPANY, selectCompanyAndFetchMembers)
}

function* watchInviteUserToCompany() {
  yield takeEvery(userActions.INVITE_USER_TO_COMPANY, inviteUser)
}

function* watchInvitationManagment() {
  yield takeEvery(userActions.MANAGE_INVITATION_TO_COMPANY, manageInvitation)
}

function* watchCredentialsMessages() {
  yield takeEvery([userActions.CHANGE_USER_PASSWORD_SUCCESS, userActions.CHANGE_USER_PASSWORD_FAIL], clearUserMessages)
}

function* watchFetchCountries() {
  yield takeEvery(fetchCountries, fetchCountriesSaga)
}

function* watchFetchCitizenships() {
  yield takeEvery(fetchCitizenships, fetchCitizenshipsSaga)
}

function* watchGetLocalTravellerInfo() {
  yield takeEvery(getLocalTravellerInfo, getLocalTravellerInfoSaga)
}

function* watchHistoryFilterByDate() {
  yield takeEvery(userActions.CHANGE_USER_FILTERS, fetchAllUserReservationsSaga)
}

export default function* userSaga() {
  yield [
    watchFetchUserDetails(),
    watchUpdateUserDetails(),
    watchFetchAllUserReservations(),
    watchUpsertUserCompany(),
    watchDeleteUserCompany(),
    watchChangeUserPassword(),
    watchCredentialsMessages(),
    watchSelectUsersCompany(),
    watchInviteUserToCompany(),
    watchInvitationManagment(),
    watchFetchCountries(),
    watchFetchCitizenships(),
    watchGetLocalTravellerInfo(),
    watchHistoryFilterByDate(),
  ]
}
