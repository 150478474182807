import * as reducers from './reducers/reservation'
import * as actions from './actions'
import * as constants from './consts/reservation'
import * as api from './api'
import * as selectors from './selectors'
import { reservationSaga } from './sagas'

export default {
  api,
  reducers,
  actions,
  constants,
  selectors,
  sagas: reservationSaga,
}
