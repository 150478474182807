import { handleActions } from 'redux-actions'
import {
  RESERVATION_CONFIRM,
  RESERVATION_CONFIRM_FAILED,
  RESERVATION_CONFIRM_SUCCESS,
} from '../services/reservation/consts/reservation'

const confirming = handleActions(
  {
    [RESERVATION_CONFIRM]: () => true,
    [RESERVATION_CONFIRM_SUCCESS]: () => false,
    [RESERVATION_CONFIRM_FAILED]: () => false,
  },
  false
)

export default confirming
