import { call, put, takeEvery } from 'redux-saga/effects'
import * as customerActions from './consts'
import * as userActions from '../user/consts'
import { fetchUserCustomerInfo } from './api'

function* fetchUserAsCustomerSaga() {
  try {
    const data = yield call(fetchUserCustomerInfo)
    yield put({
      type: customerActions.FETCH_USER_CUSTOMER_INFO_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const { data = {} } = error
    yield put({
      type: customerActions.FETCH_USER_CUSTOMER_INFO_FAIL,
      payload: { ...error, message: data.message || error.message },
    })
  }
}

export function* watchFetchUserCustomer() {
  yield takeEvery(customerActions.FETCH_USER_CUSTOMER_INFO, fetchUserAsCustomerSaga)
}

export function* watchUpdateUserDetailsSuccess() {
  yield takeEvery(userActions.UPDATE_USER_DETAILS_SUCCESS, fetchUserAsCustomerSaga)
}

export default function* customerSaga() {
  yield [watchFetchUserCustomer(), watchUpdateUserDetailsSuccess()]
}
