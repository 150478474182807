import { handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import { always, head, init, isNil, omit, tail } from 'ramda'
import {
  ADD_SAIL_PACKAGE_TO_SELECTED,
  ADD_SEAT,
  DROP_REVERSE_SAIL_PACKAGE,
  END_EDIT_TRIP,
  RESET_EDIT_TRIP,
  FLUSH_SEAT,
  SELECT_CUSTOMER_ROLE,
  RESET_CUSTOMER_ROLE,
  SELECT_SAIL_PACKAGE,
  SET_COMMON_USER,
  SET_FIRST_SAIL_DATE,
  SET_SAIL_DATES,
  SET_SECOND_SAIL_DATE,
  SET_SELECTED_PACKAGES,
  START_EDIT_TRIP,
  TOGGLE_MOBILE_MENU,
  VEHICLE_INPUT_TYPE,
  TRAILER_INPUT_TYPE,
  RESET_ALL,
  SELECT_PASSENGER_TO_EDIT_PERSONAL_DATA,
  CLEAR_PASSENGER_SEQ_NUM,
  SAVE_PASSENGER_FORM_DATA,
  CLEAR_ALL_PASSENGER_FORM_DATA,
  REMOVE_ONE_PASSENGER_FORM_DATA,
} from './consts'

import { UPDATE_EDITABLE_FIELDS } from '../../consts/actions'

import { beforeThanToday, extendSailPackage } from '../utils'

const defaultSailDatesState = ['']

const sailDates = handleActions(
  {
    'persist/REHYDRATE': (state, { payload = {} }) => {
      const { userSelections } = payload
      const { sailDates: selectedDates } = userSelections || {}

      if (selectedDates && selectedDates.some(beforeThanToday)) {
        return ['']
      }

      return state
    },
    [SET_SAIL_DATES]: (state, { payload }) => payload,
    [SET_FIRST_SAIL_DATE]: (state, { payload }) => [payload, ...tail(state)],
    [SET_SECOND_SAIL_DATE]: (state, { payload }) => [head(state), payload],
    [RESET_ALL]: () => defaultSailDatesState,
  },
  defaultSailDatesState
)

const sailPackages = handleActions(
  {
    [SELECT_SAIL_PACKAGE]: (state, { payload }) => [extendSailPackage(payload.sailPackage)],
    [ADD_SAIL_PACKAGE_TO_SELECTED]: (state, { payload }) => [...state, payload],
    [SET_SELECTED_PACKAGES]: (state, { payload }) => payload,
    [DROP_REVERSE_SAIL_PACKAGE]: init,
    [RESET_ALL]: () => [],
  },
  []
)

const defaultEditableTrip = {}

const editableTrip = handleActions(
  {
    [START_EDIT_TRIP]: (state, { payload }) => payload,
    [END_EDIT_TRIP]: () => defaultEditableTrip,
    [RESET_EDIT_TRIP]: () => defaultEditableTrip,
    [RESET_ALL]: () => defaultEditableTrip,
  },
  defaultEditableTrip
)

const initialMobileMenuOpened = false
const mobileMenuOpened = handleActions(
  {
    [TOGGLE_MOBILE_MENU]: (state, { payload }) => (isNil(payload) ? !state : payload),
    [RESET_ALL]: () => false,
  },
  initialMobileMenuOpened
)

const initialVehicleInputType = 'simple'
const vehicleInputType = handleActions(
  {
    [VEHICLE_INPUT_TYPE]: (_, { payload: type }) => type,
    [RESET_ALL]: () => initialVehicleInputType,
  },
  initialVehicleInputType
)

const trailerInputType = handleActions(
  {
    [TRAILER_INPUT_TYPE]: (_, { payload: type }) => type,
    [RESET_ALL]: () => initialVehicleInputType,
  },
  initialVehicleInputType
)

const initialCustomerRole = {}
const customerRole = handleActions(
  {
    [RESET_CUSTOMER_ROLE]: () => initialCustomerRole,
    [SELECT_CUSTOMER_ROLE]: (state, { payload }) => payload,
  },
  initialCustomerRole
)

const initialSeatsState = {}
const seats = handleActions(
  {
    [ADD_SEAT]: (state, { payload }) => {
      const { code, localID } = payload
      const key = (localID && `ISIK_${localID}`) || code
      return {
        ...state,
        [key]: payload,
      }
    },
    [FLUSH_SEAT]: () => initialSeatsState,
    [RESET_ALL]: () => initialSeatsState,
  },
  initialSeatsState
)

const initialCommonUser = {}
const commonUser = handleActions(
  {
    [SET_COMMON_USER]: (state, { payload }) => payload,
    [RESET_ALL]: () => initialCommonUser,
  },
  initialCommonUser
)

const editableFields = handleActions(
  {
    [UPDATE_EDITABLE_FIELDS]: (state, { payload }) => payload,
    [RESET_ALL]: () => ({}),
  },
  {}
)

const selectedPassengerSeqNum = handleActions(
  {
    [SELECT_PASSENGER_TO_EDIT_PERSONAL_DATA]: (state, { payload }) => payload,
    [CLEAR_PASSENGER_SEQ_NUM]: always(0),
  },
  0
)

const defaultTempPassengerFormData = {}
const tempPassengerFormData = handleActions(
  {
    [SAVE_PASSENGER_FORM_DATA]: (state, { payload }) => ({ ...state, [payload.seqNum]: payload.passenger }),
    [REMOVE_ONE_PASSENGER_FORM_DATA]: (state, { payload }) => omit([payload.seqNum])(state),
    [CLEAR_ALL_PASSENGER_FORM_DATA]: always(defaultTempPassengerFormData),
  },
  defaultTempPassengerFormData
)

export const reducer = combineReducers({
  sailDates,
  sailPackages,
  customerRole,
  editableTrip,
  mobileMenuOpened,
  commonUser,
  editableFields,
  seats,
  vehicleInputType,
  trailerInputType,
  selectedPassengerSeqNum,
  tempPassengerFormData,
})
