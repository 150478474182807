import { pathOr } from 'ramda'
import { WATCH_CANCELLED_RESERVATION } from '../services/reservation/consts/reservation'

const initialState = {}

const cancelled = (state = initialState, action) => {
  switch (action.type) {
    case WATCH_CANCELLED_RESERVATION: {
      return pathOr(state, ['payload'], action)
    }
    default:
      return state
  }
}

export default cancelled
