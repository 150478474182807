import axios from 'axios'

const preparePayment = ({ reservationToken, paymentMethod, creditCard = false }) =>
  axios({
    method: 'post',
    url: `/api/payment/${reservationToken}/prepare`,
    data: {
      paymentMethod,
      origin: window.location.origin,
      creditCard,
    },
  })

const paymentCreditCardOnSuccess = ({ reservationId, locale, ...data }) =>
  axios({
    method: 'post',
    url: `/after-pay/cc-success/${reservationId}?locale=${locale}&redirect=false`,
    data,
  })

const paymentTransaction = ({ transactionId, ...data }) =>
  axios({
    method: 'post',
    url: `/api/payment/${transactionId}/success`,
    data,
  })

const cancelTransaction = ({ transactionId, ...data }) =>
  axios({
    method: 'post',
    url: `/api/payment/${transactionId}/cancel`,
    data,
  })

const requestMaker = (apiMethod) => (data) =>
  apiMethod(data)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))

export const preparePaymentRequester = requestMaker(preparePayment)
export const paymentTransactionRequester = requestMaker(paymentTransaction)
export const cancelTransactionRequester = requestMaker(cancelTransaction)
export const sendCreditCardSuccess = requestMaker(paymentCreditCardOnSuccess)
