import { handleActions } from 'redux-actions'
import { pathOr } from 'ramda'

import { FETCH_ROUTES_SUCCESS, SET_ROUTES_STATE } from '../consts'

const routes = handleActions(
  {
    [FETCH_ROUTES_SUCCESS]: (state, { payload }) => pathOr({}, ['entities', 'routes'], payload),
    [SET_ROUTES_STATE]: (state, { payload }) => payload,
  },
  {}
)

export default routes

export const getRoute = (state, id) => state[id]
