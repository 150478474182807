import ServiceWorker from 'worker-loader!../../workers/service-worker.js'

const SWMiddleware = (worker) => {
  if (!worker) {
    throw new Error(`\`createWorkerMiddleware\` expects a worker instance as the argument. Instead received: ${worker}`)
  } else if (!worker.postMessage) {
    throw new Error('The worker instance is expected to have a `postMessage` method.')
  }

  return ({ dispatch }) => {
    /*
      when the worker posts a message back, dispatch the action with its payload
      so that it will go through the entire middleware chain
    */
    worker.onmessage = ({ data: resultAction }) => {
      // eslint-disable-line no-param-reassign
      const { meta } = resultAction

      if (meta.route) {
        const url = window.location.href
        if (url.includes(meta.route)) dispatch(resultAction)
      } else {
        dispatch(resultAction)
      }
    }

    return (next) => {
      if (!next) {
        throw new Error('Worker middleware received no `next` action. Check your chain of middlewares.')
      }

      return (action) => {
        if (action.meta && action.meta.WebWorker) {
          worker.postMessage(action)
        }
        // always pass the action along to the next middleware
        return next(action)
      }
    }
  }
}

const worker = new ServiceWorker()
const serviceWorkerMiddleware = SWMiddleware(worker)

export { serviceWorkerMiddleware }
