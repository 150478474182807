export const FETCH_USER_DETAILS = 'USER:FETCH_USER_DETAILS'
export const FETCH_USER_DETAILS_SUCCESS = 'USER:FETCH_USER_DETAILS_SUCCESS'
export const FETCH_USER_DETAILS_FAIL = 'USER:FETCH_USER_DETAILS_FAIL'

export const SET_USER_DETAILS = 'USER:SET_USER_DETAILS'

export const FETCH_ALL_USER_RESERVATIONS = 'USER:FETCH_ALL_USER_RESERVATIONS'
export const FETCH_ALL_USER_RESERVATIONS_SUCCESS = 'USER:FETCH_ALL_USER_RESERVATIONS_SUCCESS'
export const FETCH_ALL_USER_RESERVATIONS_FAIL = 'USER:FETCH_ALL_USER_RESERVATIONS_FAIL'

export const UPDATE_USER_DETAILS = 'USER:UPDATE_USER_DETAILS'
export const UPDATE_USER_DETAILS_SUCCESS = 'USER:UPDATE_USER_DETAILS_SUCCESS'
export const UPDATE_USER_DETAILS_FAIL = 'USER:UPDATE_USER_DETAILS_FAIL'

export const UPSERT_USER_COMPANY = 'USER:UPDATE_USER_COMPANY'
export const UPSERT_USER_COMPANY_SUCCESS = 'USER:UPSERT_USER_COMPANY_SUCCESS'
export const UPSERT_USER_COMPANY_FAIL = 'USER:UPSERT_USER_COMPANY_FAIL'

export const DELETE_USER_COMPANY = 'USER:DELETE_USER_COMPANY'
export const DELETE_USER_COMPANY_SUCCESS = 'USER:DELETE_USER_COMPANY_SUCCESS'
export const DELETE_USER_COMPANY_FAIL = 'USER:DELETE_USER_COMPANY_FAIL'

export const CHANGE_USER_PASSWORD = 'USER:CHANGE_USER_PASSWORD'
export const CHANGE_USER_PASSWORD_SUCCESS = 'USER:CHANGE_USER_PASSWORD_SUCCESS'
export const CHANGE_USER_PASSWORD_FAIL = 'USER:CHANGE_USER_PASSWORD_FAIL'
export const RESET_CREDENTIALS_MESSAGES = 'USER:RESET_CREDENTIALS_MESSAGES'

export const CHANGE_USER_FILTERS = 'USER:CHANGE_USER_FILTERS'
export const SELECT_USERS_COMPANY = 'USER:SELECT_USERS_COMPANY'

export const FETCH_COMPANY_MEMBERS = 'USER:COMPANY:FETCH_COMPANY_MEMBERS'
export const FETCH_COMPANY_MEMBERS_SUCCESS = 'USER:COMPANY:FETCH_COMPANY_MEMBERS_SUCCESS'

export const RESET_USER = 'USER:RESET_USER'

export const INVITE_USER_TO_COMPANY = 'USER:INVITE_USER_TO_COMPANY'
export const INVITE_USER_TO_COMPANY_SUCCESS = 'USER:INVITE_USER_TO_COMPANY_SUCCESS'

export const MANAGE_INVITATION_TO_COMPANY = 'USER:MANAGE_INVITATION_TO_COMPANY'
export const MANAGE_INVITATION_TO_COMPANY_SUCCESS = 'USER:MANAGE_INVITATION_TO_COMPANY_SUCCESS'
export const MANAGE_INVITATION_TO_COMPANY_FAIL = 'USER:MANAGE_INVITATION_TO_COMPANY_FAIL'

export const UPDATE_COMPANY_MEMBERS = 'USER:UPDATE_COMPANY_MEMBERS'
export const REMOVE_USER_FROM_COMPANY_SUCCESS = 'USER:REMOVE_USER_FROM_COMPANY_SUCCESS'

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES'
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS'

export const FETCH_CITIZENSHIPS = 'FETCH_CITIZENSHIPS'
export const FETCH_CITIZENSHIPS_SUCCESS = 'FETCH_CITIZENSHIPS_SUCCESS'

export const GET_LOCAL_TRAVELLER_INFO = 'USER:GET_LOCAL_TRAVELLER_INFO'
export const GET_LOCAL_TRAVELLER_INFO_SUCCESS = 'USER:GET_LOCAL_TRAVELLER_INFO_SUCCESS'
export const PROMOTE_RESIDENT_DISCOUNT = 'USER:PROMOTE_RESIDENT_DISCOUNT'
