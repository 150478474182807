import { evolve, reduce, assoc, __, omit, find, propEq, pathOr } from 'ramda'

import * as Actions from '../consts/schedule'
import * as inventoryActions from '../../inventory/consts'

import { beforeThanToday } from '../../utils'

const initialState = {
  sails: {},
  inventories: {},
  availableDates: {},
  selectedSails: {},
  selectedSailsWhileEdit: {},
  ticketsIsFetching: false,
  requestingDates: false,
}

const schedule = (state = initialState, action) => {
  const updateState = (sailPackageCode, newData) => (oldData) => {
    const updatedData = { ...oldData }
    updatedData[sailPackageCode] = newData
    return updatedData
  }

  switch (action.type) {
    case 'persist/REHYDRATE': {
      if (action.payload && action.payload.schedule) {
        const oldSelectedSails = []

        const {
          schedule: { selectedSails = {}, sails, inventories },
        } = action.payload

        Object.keys(selectedSails).forEach((sailPackageCode) => {
          const sailRefId = selectedSails[sailPackageCode]

          const sail = find(propEq('sailRefId', sailRefId), sails[sailPackageCode] || [])
          const inventory = find(
            propEq('time', pathOr('', ['departure', 'time'], sail)),
            inventories[sailPackageCode] || []
          )

          if (sail && inventory) {
            if (!inventory.inventories.enabled || beforeThanToday(sail.departureDate)) {
              oldSelectedSails.push(sailPackageCode)
            }
          }
        })

        return {
          ...action.payload.schedule,
          selectedSails: omit(oldSelectedSails, selectedSails),
        }
      }

      return state
    }
    case Actions.SW_SAILS_FETCH_SUCCESS:
    case Actions.FETCH_SCHEDULE_SUCCESS: {
      const newState = evolve(
        __,
        state
      )({
        sails: updateState(action.payload.sailPackageCode, action.payload.sails),
      })

      return {
        ...newState,
        requestingDates: false,
      }
    }
    case Actions.FETCH_SAILS_FOR_LEG_AND_DATE: {
      return {
        ...state,
        requestingDates: true,
      }
    }
    case inventoryActions.SET_ALL_INVENTORY_FETCH_SUCCESS:
    case inventoryActions.ALL_INVENTORY_FETCH_SUCCESS: {
      return evolve(
        __,
        state
      )({
        inventories: updateState(action.payload.sailPackageCode, action.payload.inventories),
      })
    }
    case Actions.CHANGE_SCHEDULE_DATE: {
      return { ...state, date: action.payload, ticketsIsFetching: true }
    }
    case Actions.SELECT_SCHEDULE_SAIL: {
      return evolve(
        __,
        state
      )({
        selectedSails: updateState(action.payload.sailPackageCode, action.payload.selectedSailId),
      })
    }
    case Actions.SELECT_EDIT_SCHEDULE_SAIL: {
      return evolve(
        __,
        state
      )({
        selectedSailsWhileEdit: updateState(action.payload.sailPackageCode, action.payload.selectedSailId),
      })
    }
    case Actions.CLEAR_EDIT_SCHEDULE_SAIL_LIST: {
      return {
        ...state,
        selectedSailsWhileEdit: initialState.selectedSailsWhileEdit,
      }
    }
    case Actions.CLEAR_SCHEDULE_SELECTED_SAILS: {
      return {
        ...state,
        selectedSails: {},
      }
    }
    case Actions.FETCH_AVAILABLE_REPLACEMENT_LEGS_SUCCESS: {
      return {
        ...state,
        availableReplacementLegs: action.payload.availableReplacementLegs,
      }
    }
    case Actions.FETCH_AVAILABLE_REPLACEMENT_LEGS_FAIL: {
      return {
        ...state,
        availableReplacementLegs: [],
      }
    }
    case Actions.FETCH_SCHEDULE_AVAILABLE_DATES_SUCCESS: {
      return evolve(
        __,
        state
      )({
        availableDates: updateState(
          action.payload.sailPackageCode,
          reduce((acc, date) => assoc(date, date, acc), {}, action.payload.availableDates)
        ),
      })
    }
    case Actions.FETCH_SCHEDULE_AVAILABLE_DATES_FAIL: {
      return evolve(
        __,
        state
      )({
        availableDates: updateState(action.payload.sailPackageCode, {}),
      })
    }

    case 'routes/Routes/SELECT_ROUTE': {
      return evolve(
        __,
        state
      )({
        ticketsIsFetching: true,
      })
    }

    case Actions.OMIT_PACKAGE_FROM_SCHEDULE_STATE: {
      const { sailPackageCode } = action.payload

      return {
        ...state,
        sails: omit([sailPackageCode], state.sails),
        inventories: omit([sailPackageCode], state.inventories),
        selectedSails: omit([sailPackageCode], state.selectedSails),
      }
    }

    case Actions.CLEAR_SCHEDULE: {
      return { ...initialState }
    }

    case Actions.SET_SCHEDULE_STATE: {
      return { ...state, requestingDates: false, ...action.payload }
    }

    case Actions.REMOVE_SAIL_REF_BY_CODE: {
      const sailPackageCode = action.payload
      return {
        ...state,
        selectedSails: omit([sailPackageCode], state.selectedSails),
      }
    }

    default:
      return state
  }
}

export default schedule
export * from './schedule'
