import * as AppActions from '../consts/actions'

const initialState = null

const selectedSailPackageIndexToEdit = (state = initialState, action) => {
  switch (action.type) {
    case AppActions.SET_SELECTED_SAIL_PACKAGE_INDEX: {
      return action.payload
    }
    case AppActions.RESET_SELECTED_SAIL_PACKAGE_INDEX: {
      return initialState
    }
    default:
      return state
  }
}

export default selectedSailPackageIndexToEdit
