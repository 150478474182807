import { equals, anyPass, pathOr } from 'ramda'
import moment from 'moment'
import {
  RESERVATION_CREATED,
  // RESERVATION_MODIFIED,
  RESERVATION_CONFIRM_SUCCESS,
  REMOVE_CURRENT_RESERVATION,
  RESERVATION_ABOUT_TO_EXPIRE,
  RESERVATION_EXPIRED,
  CANCEL_EDIT_RESERVATION,
  CLEAR_EXPIRE_TIMERS,
  EXTEND_RESERVATION_EXPIRE,
} from '../../services/reservation/consts/reservation'

const MINUTES_TO_CONFIRM = 2

const updateType = anyPass([
  equals(RESERVATION_CREATED),
  // equals(RESERVATION_MODIFIED),
  equals(RESERVATION_CONFIRM_SUCCESS),
  equals(EXTEND_RESERVATION_EXPIRE),
  equals('EDIT_TICKET:UPDATE_RESERVATION'),
])

const clearType = anyPass([
  equals(REMOVE_CURRENT_RESERVATION),
  equals(CANCEL_EDIT_RESERVATION),
  equals(CLEAR_EXPIRE_TIMERS),
])
export const getReservationExpireAt = (defaultDate) => pathOr(defaultDate, ['expireAt'])
export const getModifiedReservationExpireAt = (defaultDate) => pathOr(defaultDate, ['modifiedReservation', 'expireAt'])

const getMinutesDiff = (tempExpireAt) => moment(tempExpireAt).diff(moment(), 'minutes') + moment().utcOffset()

const tryToCloseMKModal = () => {
  try {
    if (window.Maksekeskus.Checkout && window.Maksekeskus.Checkout.closeModal) {
      window.Maksekeskus.Checkout.closeModal()
    }
  } catch (e) {
    console.warn('Cannot close the cc payment modal', e)
  }
}

export function checkReservationExpiration(store) {
  const { dispatch } = store

  let warningTimer
  let expiredTimer

  return (next) => (action) => {
    const { type, payload = {} } = action

    if (updateType(type)) {
      const reservation = (type === RESERVATION_CONFIRM_SUCCESS ? payload.reservation : payload) || {}

      const { expireAt: expireAtFromRoot } = reservation
      let expireAt = getReservationExpireAt(expireAtFromRoot)(reservation)

      if (type === EXTEND_RESERVATION_EXPIRE) {
        expireAt = payload
      }

      clearTimeout(warningTimer)
      clearTimeout(expiredTimer)

      const minutesUntilExpire = getMinutesDiff(expireAt)
      const minutesUntilWarning = minutesUntilExpire - MINUTES_TO_CONFIRM

      if (expireAt) {
        warningTimer = setTimeout(() => {
          dispatch({
            type: RESERVATION_ABOUT_TO_EXPIRE,
            payload: {
              minutes: getMinutesDiff(expireAt),
            },
          })
          tryToCloseMKModal()
        }, minutesUntilWarning * 60000)

        expiredTimer = setTimeout(() => {
          dispatch({
            type: RESERVATION_EXPIRED,
            payload: {},
          })
          tryToCloseMKModal()
        }, minutesUntilExpire * 60000)
      }
    } else if (clearType(type)) {
      clearTimeout(warningTimer)
      clearTimeout(expiredTimer)
    }

    return next(action)
  }
}
