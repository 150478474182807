import { anyPass, compose, contains, filter, length, lt, map, prop } from 'ramda'

export { isLaaksaareSail } from '../../utils/laaksaare'

const departuresRingsu = contains('RIN-')
const arrivalRingsu = contains('-RIN')
const departuresLaakasaare = contains('LAA-')
const arrivalLaakasaare = contains('-LAA')

export const isRingsuOrLaaksaareSail = compose(
  lt(0),
  length,
  filter(anyPass([departuresRingsu, arrivalRingsu, departuresLaakasaare, arrivalLaakasaare])),
  map(prop('code'))
)
