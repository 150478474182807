import axios from 'axios'

export const fetchCompanyMembers = (companyId) =>
  axios({
    method: 'get',
    url: `/api/company/${companyId}/members`,
  })

export const fetchMembersByAllManagersCompanies = () =>
  axios({
    method: 'get',
    url: '/api/company/all/members',
  })

// ACTIONS WITH COMMON USERS

/*
"emailAddress": "new_member@company.me",
"personalIdentificationNumber": "22222222222"
*/
export const inviteUserToCompany = ({ companyId, data }) =>
  axios({
    method: 'put',
    url: `/api/company/${companyId}/member/invite`,
    data,
  })

export const resendInvitationUserToCompany = ({ companyId, token }) =>
  axios({
    method: 'put',
    url: `/api/company/${companyId}/invitation/${token}`,
  })

export const cancelInvitationUserToCompany = ({ companyId, token }) =>
  axios({
    method: 'delete',
    url: `/api/company/${companyId}/invitation/${token}`,
  })

export const validateInvite = (joinToken) =>
  axios({
    method: 'get',
    url: `/api/company/invitation/check/${joinToken}`,
  })

export const activateCompanyMember = (companyId, customerId) =>
  axios({
    method: 'put',
    url: `/api/company/${companyId}/customer/${customerId}/status`,
  })

export const deactivateCompanyMember = (companyId, customerId) =>
  axios({
    method: 'delete',
    url: `/api/company/${companyId}/customer/${customerId}/status`,
  })

export const removeUserFromCompany = ({ companyId, customerId }) =>
  axios({
    method: 'delete',
    url: `/api/company/${companyId}/customer/${customerId}`,
  })

export const fetchCountriesForCompany = () =>
  axios({
    method: 'get',
    url: '/api/catalog/COUNTRY',
  })

export const fetchCitizenshipsForCompany = () =>
  axios({
    method: 'get',
    url: '/api/catalog/CITIZENSHIPS',
  })

export function createCorporateAccountService(data) {
  return axios({
    method: 'post',
    url: '/api/customer/add/company/member',
    data,
  })
}

let prevCancelSource
export function fetchCompanyMemberReservations({ companyId, customerId }) {
  if (prevCancelSource) prevCancelSource.cancel('Operation canceled to make next request.')

  const cancelSource = axios.CancelToken.source()
  prevCancelSource = cancelSource

  return axios({
    method: 'get',
    url: `/api/reservations/by/company/${companyId}/customer/${customerId}`,
    cancelToken: cancelSource.token,
  })
}

export const fetchReservationsByAllManagersCompanies = () =>
  axios({
    method: 'post',
    url: '/api/reservations/by/managed-companies',
    data: {
      options: {
        isManager: true,
      },
    },
  })

export const fetchReservationsByCustomerInAllManagersCompanies = (customerId) =>
  axios({
    method: 'post',
    url: '/api/reservations/by/customer',
    data: {
      criteria: {
        reservationOwner: {
          customerId,
        },
      },
      options: {
        isManager: true,
      },
    },
  })

export const fetchReservationsByCompany = (companyId) =>
  axios({
    method: 'post',
    url: '/api/reservations/by/company',
    data: {
      criteria: {
        reservationOwner: {
          companyId,
        },
      },
    },
  })
