/* eslint-disable max-lines-per-function */
export default {
  fetchRoutes:
    (
      fake = {
        status: true,
        data: {
          content: [
            {
              code: 'SVI-ROH-SVI',
              createdAt: '2018-01-22T15:16:50.056',
              legs: [
                {
                  code: 'SVI-ROH',
                  createdAt: '2018-01-22T15:17:32.391',
                  locationFrom: 'Sviby',
                  locationTo: 'Mandriosa',
                  portFrom: 'SVI',
                  portTo: 'ROH',
                  seqNumber: 1,
                  title: 'Sviby - Rohuküla',
                },
                {
                  code: 'ROH-SVI',
                  createdAt: '2018-01-22T15:17:32.982',
                  locationFrom: 'Mandriosa',
                  locationTo: 'Vormsi',
                  portFrom: 'ROH',
                  portTo: 'SVI',
                  seqNumber: 2,
                  title: 'Rohuküla - Sviby',
                },
              ],
              title: 'Sviby - Rohuküla - Sviby',
            },
            {
              code: 'SOR-TRI-SOR',
              createdAt: '2018-01-22T15:16:50.672',
              legs: [
                {
                  code: 'SOR-TRI',
                  createdAt: '2018-01-22T15:17:33.274',
                  locationFrom: 'Hiiumaa',
                  locationTo: 'Saaremaa',
                  portFrom: 'SOR',
                  portTo: 'TRI',
                  seqNumber: 1,
                  title: 'Sõru - Triigi',
                },
                {
                  code: 'TRI-SOR',
                  createdAt: '2018-01-22T15:17:33.554',
                  locationFrom: 'Saaremaa',
                  locationTo: 'Hiiumaa',
                  portFrom: 'TRI',
                  portTo: 'SOR',
                  seqNumber: 2,
                  title: 'Triigi - Sõru',
                },
              ],
              title: 'Sõru - Triigi - Sõru',
            },
            {
              code: 'KIH-PAR-KIH',
              createdAt: '2018-01-22T15:16:50.86',
              legs: [
                {
                  code: 'KIH-PAR',
                  createdAt: '2018-01-22T15:17:33.784',
                  locationFrom: 'Kihnu',
                  locationTo: 'Mandriosa',
                  portFrom: 'KIH',
                  portTo: 'PAR',
                  seqNumber: 1,
                  title: 'Kihnu - Pärnu',
                },
                {
                  code: 'PAR-KIH',
                  createdAt: '2018-01-22T15:17:33.985',
                  locationFrom: 'Mandriosa',
                  locationTo: 'Kihnu',
                  portFrom: 'PAR',
                  portTo: 'KIH',
                  seqNumber: 2,
                  title: 'Pärnu - Kihnu',
                },
              ],
              title: 'Kihnu - Pärnu - Kihnu',
            },
            {
              code: 'KIH-MUN-KIH',
              createdAt: '2018-01-22T15:16:51.044',
              legs: [
                {
                  code: 'KIH-MUN',
                  createdAt: '2018-01-22T15:17:34.31',
                  locationFrom: 'Kihnu',
                  locationTo: 'Mandriosa',
                  portFrom: 'KIH',
                  portTo: 'MUN',
                  seqNumber: 1,
                  title: 'Kihnu - Munalaid',
                },
                {
                  code: 'MUN-KIH',
                  createdAt: '2018-01-22T15:17:34.583',
                  locationFrom: 'Mandriosa',
                  locationTo: 'Kihnu',
                  portFrom: 'MUN',
                  portTo: 'KIH',
                  seqNumber: 2,
                  title: 'Munalaid - Kihnu',
                },
              ],
              title: 'Kihnu - Munalaid - Kihnu',
            },
            {
              code: 'MAN-MUN-MAN',
              createdAt: '2018-01-22T15:16:51.24',
              legs: [
                {
                  code: 'MAN-MUN',
                  createdAt: '2018-01-22T15:17:34.799',
                  locationFrom: 'Manilaid',
                  locationTo: 'Mandriosa',
                  portFrom: 'MAN',
                  portTo: 'MUN',
                  seqNumber: 1,
                  title: 'Manilaid - Munalaid',
                },
                {
                  code: 'MUN-MAN',
                  createdAt: '2018-01-22T15:17:35.014',
                  locationFrom: 'Mandriosa',
                  locationTo: 'Manilaid',
                  portFrom: 'MUN',
                  portTo: 'MAN',
                  seqNumber: 2,
                  title: 'Munalaid - Manilaid',
                },
              ],
              title: 'Manilaid - Munalaid - Manilaid',
            },
            {
              code: 'RIN-MUN-RIN',
              createdAt: '2018-01-22T15:16:51.436',
              legs: [
                {
                  code: 'RIN-MUN',
                  createdAt: '2018-01-22T15:17:35.214',
                  locationFrom: 'Ruhnu',
                  locationTo: 'Mandriosa',
                  portFrom: 'RIN',
                  portTo: 'MUN',
                  seqNumber: 1,
                  title: 'Ringsu - Munalaid',
                },
                {
                  code: 'MUN-RIN',
                  createdAt: '2018-01-22T15:17:35.421',
                  locationFrom: 'Mandriosa',
                  locationTo: 'Ruhnu',
                  portFrom: 'MUN',
                  portTo: 'RIN',
                  seqNumber: 2,
                  title: 'Munalaid - Ringsu',
                },
              ],
              title: 'Ringsu - Munalaid - Ringsu',
            },
            {
              code: 'RIN-PAR-RIN',
              createdAt: '2018-01-22T15:16:51.632',
              legs: [
                {
                  code: 'RIN-PAR',
                  createdAt: '2018-01-22T15:17:35.65',
                  locationFrom: 'Ruhnu',
                  locationTo: 'Mandriosa',
                  portFrom: 'RIN',
                  portTo: 'PAR',
                  seqNumber: 1,
                  title: 'Ringsu - Pärnu',
                },
                {
                  code: 'PAR-RIN',
                  createdAt: '2018-01-22T15:17:35.84',
                  locationFrom: 'Mandriosa',
                  locationTo: 'Ruhnu',
                  portFrom: 'PAR',
                  portTo: 'RIN',
                  seqNumber: 2,
                  title: 'Pärnu - Ringsu',
                },
              ],
              title: 'Ringsu - Pärnu - Ringsu',
            },
            {
              code: 'RIN-ROO-RIN',
              createdAt: '2018-01-22T15:16:51.837',
              legs: [
                {
                  code: 'RIN-ROO',
                  createdAt: '2018-01-22T15:17:36.09',
                  locationFrom: 'Ruhnu',
                  locationTo: 'Saaremaa',
                  portFrom: 'RIN',
                  portTo: 'ROO',
                  seqNumber: 1,
                  title: 'Ringsu - Roomassaare',
                },
                {
                  code: 'ROO-RIN',
                  createdAt: '2018-01-22T15:17:36.285',
                  locationFrom: 'Saaremaa',
                  locationTo: 'Ruhnu',
                  portFrom: 'ROO',
                  portTo: 'RIN',
                  seqNumber: 2,
                  title: 'Roomassaare - Ringsu',
                },
              ],
              title: 'Ringsu - Roomassaare - Ringsu',
            },
            {
              code: 'LAA-PII-LAA',
              createdAt: '2018-01-22T15:16:52.034',
              legs: [
                {
                  code: 'LAA-PII',
                  createdAt: '2018-01-22T15:17:36.49',
                  locationFrom: 'Mandriosa',
                  locationTo: 'Piirissaar',
                  portFrom: 'LAA',
                  portTo: 'PII',
                  seqNumber: 1,
                  title: 'Laaksaare - Piirissaar',
                },
                {
                  code: 'PII-LAA',
                  createdAt: '2018-01-22T15:17:36.716',
                  locationFrom: 'Piirissaar',
                  locationTo: 'Mandriosa',
                  portFrom: 'PII',
                  portTo: 'LAA',
                  seqNumber: 2,
                  title: 'Piirissaar - Laaksaare',
                },
              ],
              title: 'Laaksaare - Piirissaar - Laaksaare',
            },
            {
              code: 'KEL-LEP-KEL',
              createdAt: '2018-01-22T15:16:52.236',
              legs: [
                {
                  code: 'KEL-LEP',
                  createdAt: '2018-01-22T15:17:36.916',
                  locationFrom: 'Prangli',
                  locationTo: 'Mandriosa',
                  portFrom: 'KEL',
                  portTo: 'LEP',
                  seqNumber: 1,
                  title: 'Kelnase - Leppneeme',
                },
                {
                  code: 'LEP-KEL',
                  createdAt: '2018-01-22T15:17:37.126',
                  locationFrom: 'Mandriosa',
                  locationTo: 'Prangli',
                  portFrom: 'LEP',
                  portTo: 'KEL',
                  seqNumber: 2,
                  title: 'Leppneeme - Kelnase',
                },
              ],
              title: 'Kelnase - Leppneeme - Kelnase',
            },
            {
              code: 'TAL-AEG-TAL',
              createdAt: '2018-01-22T15:16:52.446',
              legs: [
                {
                  code: 'TAL-AEG',
                  createdAt: '2018-01-22T15:17:37.37',
                  locationFrom: 'Mandriosa',
                  locationTo: 'Aegna',
                  portFrom: 'TAL',
                  portTo: 'AEG',
                  seqNumber: 1,
                  title: 'Tallinn - Aegna',
                },
                {
                  code: 'AEG-TAL',
                  createdAt: '2018-01-22T15:17:37.598',
                  locationFrom: 'Aegna',
                  locationTo: 'Mandriosa',
                  portFrom: 'AEG',
                  portTo: 'TAL',
                  seqNumber: 2,
                  title: 'Aegna - Tallinn',
                },
              ],
              title: 'Tallinn - Aegna - Tallinn',
            },
          ],
          page: {
            hasNext: false,
            hasPrevious: false,
            number: 0,
            size: 2147483647,
            totalElements: 11,
            totalPages: 1,
          },
        },
      }
    ) =>
    () =>
      Promise[fake.status ? 'resolve' : 'reject'](
        fake.status
          ? fake.data
          : {
              error: 'fake_error',
            }
      ),
}
