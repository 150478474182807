import * as Actions from '../consts'

const initialCountries = []

const countries = (state = initialCountries, action) => {
  switch (action.type) {
    case Actions.FETCH_COUNTRIES_SUCCESS: {
      const countries = action.payload.sort((a, b) => (a.title > b.title ? 1 : -1))
      const codesOfPreferredCountries = ['EE', 'FI', 'LT', 'LV', 'SE']
      const preferredCountries = countries.filter((country) => codesOfPreferredCountries.includes(country.code))
      const otherCountries = countries.filter((country) => !codesOfPreferredCountries.includes(country.code))
      return [...preferredCountries, ...otherCountries]
    }

    default:
      return state
  }
}

export default countries
