import { createSelector } from 'reselect'
import {
  anyPass,
  ascend,
  compose,
  cond,
  defaultTo,
  descend,
  equals,
  filter,
  length,
  not,
  pathOr,
  prop,
  propOr,
  sort,
  T,
  values,
  whereEq,
} from 'ramda'

import { reservationFilters } from './reducers/filters'

import { notEmpty } from '../../utils/ramda-extend'

const handleEmptyCollection = compose(defaultTo([]), values)

const getUserData = prop('user')
export const getUserFiltersByType = (type) => createSelector(getUserData, pathOr({}, ['filters', type]))
export const getCitizenshipList = createSelector(getUserData, propOr([], ['citizenships']))
export const getCountries = createSelector(getUserData, propOr([], ['countries']))

const byPaymentTypes = cond([
  [equals('CREDIT'), () => anyPass([equals('CREDIT'), equals('INVOICE')])],
  [equals('CASH'), () => equals('CASH')],
  [equals('CARD'), () => compose(not, anyPass([equals('CASH'), equals('INVOICE'), equals('CREDIT')]))],
  [T, () => T],
])

const getFirstTransactionPaymentType = pathOr('', ['paymentInfo', 'paymentTransactions', [0], 'paymentMethod'])

export const filterReservations = (userFilters, userReservations = []) => {
  const payerPredicate = compose(
    compose(
      whereEq,
      ({ userId, companyId }) => ({
        ...(userId && { userId }),
        ...(userId && { companyId: undefined }),
        ...(companyId && { companyId }),
      }),
      prop('payer')
    )(userFilters),
    prop('reservationOwner')
  )

  const paymentMethodPredicate = compose(
    compose(byPaymentTypes, prop('paymentMethod'))(userFilters),
    getFirstTransactionPaymentType
  )

  let reservations = compose(filter(payerPredicate), filter(paymentMethodPredicate))(userReservations)

  if (userFilters.direction) {
    reservations = reservations.filter(({ sailPackages }) =>
      sailPackages.some(({ code }) => code === userFilters.direction)
    )
  }

  // if (userFilters.dates[0] || userFilters.dates[1]) {
  //   const startDate = moment(userFilters.dates[0]).startOf('day').valueOf()
  //   const endDate = moment(userFilters.dates[1]).startOf('day').valueOf()
  //
  //   reservations = reservations.filter(({ departureAt }) => {
  //     const departure = moment(departureAt).startOf('day').valueOf()
  //
  //     if (startDate && endDate) {
  //       return startDate <= departure && departure <= endDate
  //     }
  //
  //     if (startDate) {
  //       return departure >= startDate
  //     }
  //
  //     if (endDate) {
  //       return departure <= endDate
  //     }
  //
  //     return true
  //   })
  // }

  const cancelledFilter = propOr(false, 'cancelled', userFilters)

  const filteredReservations = !cancelledFilter
    ? reservations.filter((res) => res.status === 'OK')
    : reservations.filter((res) => res.status === 'CN' || res.status === 'RF' || res.status === 'OK')

  // const chooseDate = pathOr('', ['sailPackages', [0], 'sailRefs', [0], 'departureAt'])
  // const byDate = descend(chooseDate)

  let sortedReservations = [...filteredReservations]

  if (userFilters.number) {
    sortedReservations = filteredReservations.filter(({ reservationId }) =>
      String(reservationId).includes(userFilters.number)
    )
  }
  return sortedReservations
}

const reservationFilterKeys = Object.keys(reservationFilters)
export const getTicketsFiltersEnabled = (filters) =>
  Object.keys(filters).some((key) => {
    if (key === 'dates') return filters[key].filter((date) => date !== null).length > 0
    return reservationFilterKeys.includes(key) && notEmpty(filters[key])
  })

export const userDetailsSelector = createSelector(getUserData, prop('details'))
export const getUserIdSelector = createSelector(userDetailsSelector, prop('userId'))
export const getCustomerIdSelector = createSelector(userDetailsSelector, prop('customerId'))
export const allUserReservationsSelector = createSelector(getUserData, compose(handleEmptyCollection, prop('history')))
export const allUserReservationsLengthSelector = createSelector(allUserReservationsSelector, length)
export const filteredUserReservationsSelector = createSelector(
  [getUserFiltersByType('tickets'), allUserReservationsSelector],
  filterReservations
)

const sortedByOrderSelector = (filteredreservations, userFilters) => {
  let finalReservations = [...filteredreservations]
  if (userFilters.ticketsSort === 'numberUp') {
    const chooseTicketNumber = propOr('', 'reservationId')
    const byTicketNr = descend(chooseTicketNumber)
    finalReservations = sort(byTicketNr, filteredreservations)
  }

  if (userFilters.ticketsSort === 'numberDown') {
    const chooseTicketNumber = propOr('', 'reservationId')
    const byTicketNr = ascend(chooseTicketNumber)
    finalReservations = sort(byTicketNr, filteredreservations)
  }

  if (userFilters.ticketsSort === 'dateUp') {
    const chooseDate = pathOr('', ['sailPackages', [0], 'sailRefs', [0], 'departureAt'])
    const byDate = descend(chooseDate)
    finalReservations = sort(byDate, filteredreservations)
  }

  if (userFilters.ticketsSort === 'dateDown') {
    const chooseDate = pathOr('', ['sailPackages', [0], 'sailRefs', [0], 'departureAt'])
    const byDate = ascend(chooseDate)
    finalReservations = sort(byDate, filteredreservations)
  }

  if (userFilters.ticketsSort === 'directionUp') {
    const chooseDirection = pathOr('', ['sailPackages', [0], 'title'])
    const byDirection = descend(chooseDirection)
    finalReservations = sort(byDirection, filteredreservations)
  }

  if (userFilters.ticketsSort === 'directionDown') {
    const chooseDirection = pathOr('', ['sailPackages', [0], 'title'])
    const byDirection = ascend(chooseDirection)
    finalReservations = sort(byDirection, filteredreservations)
  }
  return finalReservations
}

export const sordedReservations = createSelector(
  [filteredUserReservationsSelector, getUserFiltersByType('tickets')],
  sortedByOrderSelector
)

export const getCompanies = createSelector(getUserData, prop('companies'))
export const getCompanyMembers = createSelector(getUserData, prop('companyMembers'))
export const getCompaniesSelector = createSelector(getUserData, compose(handleEmptyCollection, prop('companies')))
export const getManagersCompaniesSelector = createSelector(
  getUserData,
  compose(filter(pathOr(false, ['member', 'manager'])), handleEmptyCollection, prop('companies'))
)
export const getManagersSelectedCompany = createSelector(
  [getUserFiltersByType('usersCompany'), getCompanies],
  ({ companyId }, companies) => prop(companyId, companies)
)

export const getSelectedCompanyUsers = createSelector(
  [getUserFiltersByType('usersCompany'), getCompanyMembers],
  ({ companyId }, { byCompanyId }) => defaultTo({ members: {}, invitations: {} })(prop(companyId, byCompanyId))
)

export const getPayers = createSelector([userDetailsSelector, getCompanies], (user, companies) => {
  const payers = [{ name: `${user.firstName} ${user.lastName}`, userId: user.userId }]

  Object.keys(companies).forEach((companyId) => {
    payers.push({ name: companies[companyId].legalName, companyId })
  })

  return payers
})

const getCredentials = createSelector(getUserData, prop('credentials'))
export const getCredentialsMessage = createSelector(getCredentials, prop('message'))
export const getCredentialsError = createSelector(getCredentials, prop('error'))

export const userEmailSelector = createSelector(userDetailsSelector, prop('email'))
