import * as reducers from './reducers'
import * as actions from './actions'
import * as constants from './consts'
import * as api from './api'
import * as selectors from './reducers/selectors'
import { authSaga } from './sagas'

export default {
  api,
  reducers,
  actions,
  constants,
  selectors,
  sagas: {
    authSaga,
  },
}
