export default {
  fetchPorts:
    (
      fake = {
        status: true,
        data: {
          content: [
            {
              code: 'TLL',
              title: 'Tallinn',
            },
            {
              code: 'HEL',
              title: 'Helsinki',
            },
            {
              code: 'ROH',
              title: 'Rohukula',
            },
            {
              code: 'HLT',
              title: 'Heltermaa',
            },
          ],
        },
      }
    ) =>
    () =>
      Promise[fake.status ? 'resolve' : 'reject'](
        fake.status
          ? fake.data
          : {
              error: 'fake_error',
            }
      ),
}
