import { createAction } from 'redux-actions'

import {
  FETCH_SAIL_PACKAGES,
  FETCH_SAIL_PACKAGES_ERROR,
  FETCH_SAIL_PACKAGES_SUCCESS,
  MANAGE_SAIL_PACKAGES_FETCH_RESPONSE,
  SET_SAIL_PACKAGES_STATE,
} from './consts'

const fake = () => ({
  fake: {
    name: 'packages',
    success: MANAGE_SAIL_PACKAGES_FETCH_RESPONSE,
  },
})

export const fetchAllSailPackages = createAction(FETCH_SAIL_PACKAGES, null, fake)
export const fetchSailPackagesSuccess = createAction(FETCH_SAIL_PACKAGES_SUCCESS)
export const fetchSailPackagesError = createAction(FETCH_SAIL_PACKAGES_ERROR)

export const manageSailPackagesFetchResponse = createAction(MANAGE_SAIL_PACKAGES_FETCH_RESPONSE)
export const setSailPackagesState = createAction(SET_SAIL_PACKAGES_STATE)
