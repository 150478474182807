import { anyPass, compose, contains, filter, find, length, lt, map, prop } from 'ramda'

const ROUTE_CODE = 'LAA'
const ROUTE_CODE_RINGSU = 'RIN'

const departuresLaakasaare = contains('LAA-')
const arrivalLaakasaare = contains('-LAA')

const departuresRingsu = contains('RIN-')
const arrivalRingsu = contains('-RIN')

export const isLaakTrips = compose(
  find((code) => code.includes(ROUTE_CODE)),
  map(prop('code'))
)

export const isTalAegTrips = compose(
  find((code) => code.includes('AEG')),
  map(prop('code'))
)

export const isRingsuTrips = compose(
  find((code) => code.includes(ROUTE_CODE_RINGSU)),
  map(prop('code'))
)

export const isLaaksaareSail = compose(
  lt(0),
  length,
  filter(anyPass([departuresLaakasaare, arrivalLaakasaare])),
  map(prop('code'))
)

export const isRingsuSail = compose(
  lt(0),
  length,
  filter(anyPass([departuresRingsu, arrivalRingsu])),
  map(prop('code'))
)
