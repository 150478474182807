import { handleActions } from 'redux-actions'
import * as AppActions from '../consts/actions'

const fetching = handleActions(
  {
    [AppActions.FETCHING_START]: () => true,
    [AppActions.FETCHING_COMPLETE]: () => false,
  },
  false
)

export default fetching
