import { trim } from 'ramda'

import { translate } from '../modules/Common/Translator'

const mainLandMarker = 'Mandriosa'

const LOCATION_SEPARATOR = ' - '
const LOCATION_SEPARATOR_WITHOUT_SPACE = '-'

export const splitTitle = (title) => {
  if (title.includes(LOCATION_SEPARATOR)) return title.split(LOCATION_SEPARATOR).map(trim)
  return title.split(LOCATION_SEPARATOR_WITHOUT_SPACE).map(trim)
}

const isMainLand = (point) => point === mainLandMarker

export const getLabelsFromLeg = ({ title = '-', locationTo = '', locationFrom = '' }) => {
  const mainLandTranslation = translate('promotions-view.mainland-text')

  let [departureTitle, destinationTitle] = splitTitle(title)

  const departureMainLand = isMainLand(locationFrom)
  const destinationMainLand = isMainLand(locationTo)

  const departure = departureMainLand ? departureTitle : locationFrom
  const destination = destinationMainLand ? destinationTitle : locationTo

  destinationTitle = destinationMainLand ? mainLandTranslation : destinationTitle
  departureTitle = departureMainLand ? mainLandTranslation : departureTitle

  return {
    departureTitle,
    destinationTitle,
    departureMainLand,
    destinationMainLand,
    to: destination,
    from: departure,
  }
}

export const getParameterByName = (parameterName, url = window.location.href) => {
  const name = parameterName.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function formatPhone({ intlCode, phoneNumber } = {}) {
  const formattedIntlCode = intlCode ? `+${intlCode}` : ''
  const formattedPhoneNumber = phoneNumber ? String(phoneNumber) : ''
  const spacing = intlCode && phoneNumber ? ' ' : ''

  return formattedIntlCode + spacing + formattedPhoneNumber
}

export const variableToString = (varObj) => Object.keys(varObj)[0]

export const formatRouteTitle = (routeTitle = '') => {
  return routeTitle.replace('-', '>')
}
