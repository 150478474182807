import { change } from 'redux-form'
import { createAction } from 'redux-actions'
import { put, takeEvery } from 'redux-saga/effects'

const updateVehicle = createAction('UPDATE_VEHICLE_PRICE_ON_RESERVATION_UPSERT')

function findVehiclePrice(type) {
  return (payloadItems) => {
    const carItem = payloadItems.find((item) => item.priceCategorySubType === type)

    if (!carItem) {
      return undefined
    }

    return carItem
  }
}

const findCarPrice = findVehiclePrice('VEHICLE')

const findTrailerPrice = findVehiclePrice('TRAILER')

function* updateVehicleHandler({ payload: reservationItems = [] }) {
  const car = findCarPrice(reservationItems)
  if (typeof car !== 'undefined') {
    yield put(change('vehiclesForm', 'plateNumberVehicle.price', car.price))
    yield put(change('vehiclesForm', 'plateNumberVehicle.coefficients', car.coefficients || []))
  }

  const trailer = findTrailerPrice(reservationItems)
  if (typeof trailer !== 'undefined') {
    yield put(change('trailersForm', 'plateNumberVehicle.price', trailer.price))
    yield put(change('trailersForm', 'plateNumberVehicle.coefficients', trailer.coefficients || []))
  }
}

function* watchUpdateVehicle() {
  yield takeEvery(updateVehicle, updateVehicleHandler)
}

export { updateVehicle, watchUpdateVehicle }
