export const mergeVehicleData = (arrayOfDefinedSubtypeItems = [], vehiclesFromReservation = []) =>
  arrayOfDefinedSubtypeItems.map((item) => {
    const [seqN] = item.ownerSeqNs
    const { licencePlate } =
      vehiclesFromReservation.length > 0 ? vehiclesFromReservation.find((vehicle) => vehicle.seqN === seqN) : ''
    return {
      ...item,
      licencePlate,
    }
  })

export const areLicensePlatesForRoundTripDifferent = (arrayOfVehicleSubtype = [], arrayOfTrailerSubtype = []) => {
  if (arrayOfVehicleSubtype && arrayOfVehicleSubtype.length > 1) {
    const [vehicleForward, vehicleBack] = arrayOfVehicleSubtype
    if (vehicleForward.licencePlate !== vehicleBack.licencePlate) {
      return true
    }
  }
  if (arrayOfTrailerSubtype && arrayOfTrailerSubtype.length > 1) {
    const [trailerForward, trailerBack] = arrayOfTrailerSubtype
    if (trailerForward.licencePlate !== trailerBack.licencePlate) {
      return true
    }
  }
  return false
}
