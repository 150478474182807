import moment from 'moment'
import { prop } from 'ramda'
import {
  INVENTORY_FETCH_SUCCESS,
  CHANGE_INVENTORY_LEG,
  CHANGE_INVENTORY_DATE,
  SW_INVENTORY_FETCH_SUCCESS,
} from '../consts'

const initialState = {}

const inventory = (state = initialState, action) => {
  switch (action.type) {
    case SW_INVENTORY_FETCH_SUCCESS:
    case INVENTORY_FETCH_SUCCESS:
      return {
        ...state,
        inventories: action.payload,
        vessle: action.vessle,
      }
    case CHANGE_INVENTORY_LEG:
      return {
        ...state,
        leg: action.payload,
      }
    case CHANGE_INVENTORY_DATE:
      return {
        ...state,
        date: moment(action.payload).format('YYYY-MM-DD'),
      }
    default:
      return state
  }
}

export const getInventoryState = prop('inventory')

export default inventory
