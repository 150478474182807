import { call, put, select, takeEvery } from 'redux-saga/effects'
import * as Actions from './consts/reservation'
import { pathOr } from 'ramda'
import { applyPromotion, removePromotion } from './api'
import { reset } from 'redux-form'
import { FORM } from '../../modules/Booking/forms/PromoCodeForm'

export function* applyPromoCode({ payload: code }) {
  const reservationId = yield select(pathOr(0, ['reservation', 'current', 'reservationId']))
  if (!(reservationId && code)) {
    yield put({
      type: Actions.APPLY_PROMO_CODE_FAILED,
      payload: 'Code or ReservationId is missing',
    })
    return
  }

  try {
    const reservation = yield call(applyPromotion, {
      reservationId,
      code,
    })
    yield put({
      type: Actions.APPLY_PROMO_CODE_SUCCESS,
      payload: reservation,
    })
  } catch ({ data }) {
    yield put({
      type: Actions.APPLY_PROMO_CODE_FAILED,
      payload: { data },
    })
  }
}

export function* removePromoCode() {
  const reservationId = yield select(pathOr(0, ['reservation', 'current', 'reservationId']))

  try {
    yield put(reset(FORM))
    yield put({
      type: Actions.REMOVE_PROMO_CODE_SUCCESS,
      payload: {},
    })
    yield call(removePromotion, {
      reservationId,
    })
  } catch ({ data }) {
    yield put({
      type: Actions.REMOVE_PROMO_CODE_FAILED,
      payload: { data },
    })
  }
}

export function* watchPromoCodeApply() {
  yield takeEvery([Actions.APPLY_PROMO_CODE], applyPromoCode)
}

export function* watchPromoCodeRemove() {
  yield takeEvery([Actions.REMOVE_PROMO_CODE], removePromoCode)
}
