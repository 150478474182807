import moment from 'moment'
import { map, reduce, pathOr, assocPath } from 'ramda'

import { selectedSailBySailPackageCode } from '../../../services/schedule/reducers'
import { getSelectedSailPackages } from '../../../services/user-selections/selectors'
import { getCurrentReservation } from '../../../services/reservation/selectors'

const response = (name) => require(`./${name}.json`)

export default (name, payload, { getState }) => {
  switch (name) {
    case 'dates': {
      const { legCode } = payload

      return {
        payload: {
          sailPackageCode: legCode,
          availableDates: response('dates'),
        },
      }
    }
    case 'sails': {
      const { code, date } = payload

      const changeDataInFake = (fakeSail) => {
        const sail = { ...fakeSail }

        sail.arrival.date = date
        sail.departure.date = date
        sail.departureDate = date
        sail.routeLeg = code
        sail.sailPackage = code

        return sail
      }

      return {
        payload: {
          sailPackageCode: code,
          sails: map(changeDataInFake, response('sails')),
        },
      }
    }
    case 'inventory': {
      const inventory = response('inventory')

      inventory.date = payload.date
      inventory.routeLeg = payload.code

      return {
        payload: inventory,
      }
    }

    case 'prices': {
      const fakeSails = response('sails')
      const fakePrices = response('prices')

      const pricesBySailRefId = (acc, { sailRefId }) => {
        acc[sailRefId] = fakePrices
        return acc
      }

      return {
        payload: reduce(pricesBySailRefId, {}, fakeSails),
      }
    }

    case 'packages': {
      return {
        payload: response(name),
      }
    }

    case 'payment.methods': {
      return {
        payload: response('paymentMethods'),
      }
    }

    case 'payment.prepare': {
      return {
        payload: payload.reservationId,
      }
    }

    case 'reservation.manageURL': {
      if (payload.reservationId) {
        const state = getState()
        const reservation = getCurrentReservation(state)

        if (reservation) {
          reservation.status = 'OK'

          return {
            payload: {
              reservation,
              status: 'COMPLETED',
            },
          }
        }
      }

      break
    }

    case 'reservation.confirm': {
      const state = getState()
      const reservation = getCurrentReservation(state)

      reservation.status = 'OFFER'
      reservation.reservationId = Math.abs(reservation.reservationId)

      return {
        payload: { reservation },
      }
    }

    case 'reservation.create':
    case 'reservation.modify': {
      const state = getState()
      const { request, newReservation, ...newFormValues } = payload

      if (newReservation) {
        const currentReservation = getCurrentReservation(state)
        const { reservationOwner } = newReservation

        if (reservationOwner) {
          reservationOwner.phones = [reservationOwner.phone]
        }

        return {
          payload: {
            ...currentReservation,
            ...newReservation,
          },
        }
      }

      const reservation = response('reservation')
      const requests =
        request.requests ||
        pathOr(undefined, ['item', 'requests'], request) ||
        pathOr(undefined, ['purge-item', 'requests'], request) ||
        []

      const guestSeqN = pathOr(undefined, [0, 'seqN'], requests)
      const [fakeSailPackage] = reservation.sailPackages

      const selectedSailPackages = getSelectedSailPackages(state)
      const [selectedSailPackage] = selectedSailPackages
      const selectedSail = selectedSailBySailPackageCode(state)(selectedSailPackage.code)

      const reservationItem = {
        ownerSeqNs: guestSeqN ? [guestSeqN] : [],
        sailPackageSeqN: 1,
        price: newFormValues.price,
        inventoryClass: newFormValues.inventoryClass,
        priceCategory: newFormValues.inventoryClass,
        pricePerUnit: newFormValues.price,
        quantity: newFormValues.count,
        type:
          newFormValues.inventoryClass === 'BICYCLE' || newFormValues.inventoryClass === 'CAR'
            ? newFormValues.subType
            : 'PASSENGER',
      }

      if (reservation.items.length) {
        const existItem = reservation.items.find((item) => item.priceCategory === reservationItem.priceCategory)

        if (existItem) {
          const addOrRemoveTicket = (item) => {
            if (item.priceCategory === reservationItem.priceCategory) return reservationItem
            return item
          }

          reservation.items = map(addOrRemoveTicket, reservation.items)
        } else {
          reservation.items = [...reservation.items, reservationItem]
        }
      } else {
        reservation.items = [reservationItem]
      }

      reservation.items = reservation.items.filter((item) => item.quantity > 0)

      fakeSailPackage.code = selectedSailPackage.code

      const sailRef = {
        arrivalAt: selectedSail.arrival.timestamp,
        arrivalTo: selectedSail.arrival.port,
        departureAt: selectedSail.departure.timestamp,
        departureFrom: selectedSail.departure.port,
        departureDate: selectedSail.departureDate,
        departureTime: selectedSail.departure.time,
        route: selectedSail.route,
        routeLeg: selectedSail.routeLeg,
        sailRefId: selectedSail.sailRefId,
        vessel: 'STAR',
      }

      fakeSailPackage.sailRefs = [sailRef]
      reservation.guests = [{ seqN: guestSeqN }]
      reservation.sailPackages = [fakeSailPackage]
      reservation.tempExpireAt = moment().add(20, 'minutes').format()
      const amount = reduce((acc, item) => acc + item.price * item.quantity, 0, reservation.items)

      return {
        newFormValues,
        payload: assocPath(['paymentInfo', 'totalPrice', 'amount'], amount, reservation),
      }
    }

    default: {
      return {}
    }
  }
  return undefined
}
