import { handleActions } from 'redux-actions'
import { pathOr } from 'ramda'
import { FETCH_SAIL_PACKAGES_SUCCESS, SET_SAIL_PACKAGES_STATE } from '../consts'

const sailPackages = handleActions(
  {
    [FETCH_SAIL_PACKAGES_SUCCESS]: (state, { payload }) => pathOr({}, ['entities', 'sailPackages'], payload),
    [SET_SAIL_PACKAGES_STATE]: (state, { payload }) => payload,
  },
  {}
)

export default sailPackages

export const getSailPackage = (state, id) => state[id]
