import {
  getPrices,
  getCategoriesListWithPrices,
  getVehiclePrices,
  getSortedVehicleCategoriesListWithPrices,
  areVehiclesAvailable,
  areBicyclesAvailable,
  getTrailerCategoriesListWithPrices,
  areTrailersAvailable,
  areVehiclesActive,
  areTrailersActive,
  ticketsIncDecSelectorParameters,
  getLocalTravellers,
  getResidentPriceCategoryTitles,
  areAddonBicyclesAvailable,
  areAddonsAvailable,
  getAddonCategoriesListWithPrices,
  getRouteAddonsParameters,
} from './selectors'

export { prices } from './prices'

export const selectors = {
  getPrices,
  getCategoriesListWithPrices,
  getVehiclePrices,
  getSortedVehicleCategoriesListWithPrices,
  areVehiclesAvailable,
  areBicyclesAvailable,
  areAddonBicyclesAvailable,
  areAddonsAvailable,
  getAddonCategoriesListWithPrices,
  getRouteAddonsParameters,
  getTrailerCategoriesListWithPrices,
  areTrailersAvailable,
  areVehiclesActive,
  areTrailersActive,
  ticketsIncDecSelectorParameters,
  getLocalTravellers,
  getResidentPriceCategoryTitles,
}
