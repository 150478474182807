import { curry, equals, complement, isNil, pathOr } from 'ramda'

const validator = (invalid, errorKey) => (invalid ? errorKey : undefined)

export const required = (value, allValues, formProps, name) => {
  const { registeredFields } = formProps
  const fieldProps = registeredFields && registeredFields[name]

  if (fieldProps && fieldProps.count === 0) return undefined

  return validator(isNil(value) || value === '', 'common-fields.required-error')
}

export const requiredNumber = (value) => validator(isNaN(value), 'common-fields.number-error')
export const requiredWithoutPoints = (value) => validator(/\./.test(value), 'common-fields.integer-number-error')
export const email = (value) =>
  validator(!/^([a-z0-9_.-]+)@([a-z0-9_.-]+)\.([a-z]+)$/i.test(value), 'common-fields.email-correct-error')
export const localIDValidator = (value) =>
  validator(!/^[0-9]{11}$/i.test(value), 'common-fields.local-id-correct-error')

export const phoneCode = (value) => validator(!/^([0-9]{1,4})$/.test(value), 'common-fields.number-error')

const fieldCheckGenerator = curry(
  (checkFn, errorMessageId, fieldToCompare) => (value, allValues) =>
    validator(checkFn(value, allValues[fieldToCompare]), errorMessageId)
)

export const matchField = fieldCheckGenerator(complement(equals), 'common-fields.passwords-should-match-error')
export const dontMatchField = fieldCheckGenerator(equals, 'common-fields.passwords-should-not-match-error')

export const validateMaxSize = curry((maxSize, file) =>
  validator(pathOr(0, ['meta', 'size'], file) > maxSize, 'common-fields.max-file-size-error')
)

export const plateNumberRegexp = /[\d|\w]{2,8}/i

export const onlyAlphaNumericDash = (value) => (value ? value.replace(/[^A-Za-z0-9-]/, '') : value)
