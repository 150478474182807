import { assoc, omit } from 'ramda'

import {
  SET_PRICES,
  FETCH_PRICES_SUCCESS,
  FETCH_PRICES_FAIL,
  CLEAR_SAIL_REF_ID_PRICES,
  CLEAR_PRICES,
  CALCULATE_VEHICLE_REQUESTING,
} from '../consts'

const initialState = {
  requesting: false,
}

export const prices = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRICES_SUCCESS: {
      return assoc(action.payload.sailRefId, action.payload.prices, state)
    }
    case FETCH_PRICES_FAIL: {
      return assoc(action.payload.sailRefId, {}, state)
    }
    case CLEAR_SAIL_REF_ID_PRICES: {
      return omit(action.payload, state)
    }
    case SET_PRICES: {
      return action.payload
    }
    case CLEAR_PRICES: {
      return initialState
    }
    case CALCULATE_VEHICLE_REQUESTING: {
      return {
        ...state,
        requesting: action.payload,
      }
    }
    default:
      return state
  }
}
