import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import * as AppActions from '../consts/actions'
import { prop } from 'ramda'

const defaultState = {
  lastRequest: '',
  errorResult: null,
}

const getNetwork = prop('network')

export const getLastRequest = createSelector(getNetwork, prop('lastRequest'))
export const getNetworkErrorResult = createSelector(getNetwork, prop('errorResult'))
export const isWeightRequest = createSelector(getLastRequest, (url) => url.startsWith('api/weight'))

const network = handleActions(
  {
    [AppActions.FETCHING_COMPLETE]: (state, { payload: lastRequest }) => ({
      ...state,
      lastRequest,
    }),
    [AppActions.SET_NETWORK_ERROR]: (state, { payload: errorResult }) => ({
      ...state,
      errorResult,
    }),
    [AppActions.CLEAR_NETWORK_ERROR]: (state) => ({
      ...state,
      errorResult: defaultState.errorResult,
    }),
  },
  defaultState
)

export default network
