import { delay } from 'redux-saga'
import { fork, take, race } from 'redux-saga/effects'

export const debounce = (ms, pattern, task, ...args) =>
  fork(function* debounceGenerator() {
    while (true) {
      let action = yield take(pattern)

      while (true) {
        const { debounced, latestAction } = yield race({
          debounced: delay(ms),
          latestAction: take(pattern),
        })

        if (debounced) {
          yield fork(task, ...args, action)
          break
        }

        action = latestAction
      }
    }
  })
