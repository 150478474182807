import axios from 'axios'
import moment from 'moment'

const tomorrow = () => moment(new Date()).add(1, 'days').format('YYYY-MM-DD')

export const fetchAvailableReplacementLegs = (sailRefId) =>
  axios({
    method: 'get',
    url: `/api/sails/available-replacement-legs/${sailRefId}`,
  })

export const fetchSailsDates = ({ legCode, startDate }) =>
  axios({
    method: 'get',
    url: `/api/sails/${legCode}/dates?fromDate=${startDate}`,
  })

export const fetchSails = ({ leg, date = tomorrow() }) =>
  axios({
    method: 'get',
    url: `/api/sails/${leg}/${date}`,
  })
